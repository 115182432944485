import { NodeSelection, type Selection } from "prosemirror-state";

import { schema } from "@moment/api-collab/prosemirror-schema";

export const singleComputeCellSelected = (selection: Selection) => {
	const nodeSelectionComputeCell =
		selection instanceof NodeSelection && selection.node.type === schema.nodes.compute_cell;

	// But sometimes, it's a `TextSelection`. For example, if you press `Backspace` next to a
	// `compute_cell`, it's often a `TextSelection` that includes the `compute_cell`.
	const textSelectionComputeCell =
		selection.$from.node() === selection.$to.node() &&
		selection.$from.node().type === schema.nodes.compute_cell;

	return nodeSelectionComputeCell || textSelectionComputeCell;
};
