import googleAnalytics from "@analytics/google-analytics";
import {
	ApolloClient,
	ApolloProvider,
	InMemoryCache,
	type NormalizedCacheObject,
} from "@apollo/client";
import Analytics from "analytics";
import { isEmpty } from "lodash";
import NextApp, { type AppContext, type AppProps } from "next/app";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useEffectOnce } from "react-use";
import { AnalyticsProvider } from "use-analytics";

import "@fontsource/ibm-plex-mono/latin.css";
import "@fontsource/ibm-plex-sans/latin.css";
import "@fontsource/ibm-plex-serif/latin.css";
import "@moment/design-system/main.css";
import "@xterm/xterm/css/xterm.css";

import { ToastNotifications, makeToast } from "@moment/design-system/Toast";
import { type UserInfo, setUserProperty } from "@moment/logging";

import { type ClientActionsProps } from "~/ClientActions";
import { Providers } from "~/Providers";
import { useLogger } from "~/components/canvas/hooks/useLogger";
import { FatalAppError } from "~/components/errors/FatalAppError";
import { FindInPage } from "~/components/find-in-page/FindInPage";
import { useOutdatedAppModal } from "~/components/outdated-app/OutdatedAppModalContext";
import { Head } from "~/components/seo/Head";
import { useFetchConfigQuery } from "~/store/desktop-app/api";
import { selectIsOutdatedAppVersion } from "~/store/desktop-app/selectors";
import { setIsOutdatedAppVersion, setIsUpdateReady } from "~/store/desktop-app/slice";
import { useAppDispatch, useAppSelector } from "~/store/hooks";
import { initLogger, initializeRum } from "~/utils/datadog/datadog";

import { isDesktopAppMode } from "../auth/interop/common";
import { useAuth } from "../auth/useAuth";
import { printEnvironment } from "../utils/printEnvironment";

export type MomentAppProps = AppProps & {
	cookies?: string;
};

const ClientActions = dynamic<ClientActionsProps>(
	() => import("~/ClientActions").then((m) => m.ClientActions),
	// turn off server side rendering for this code
	{ ssr: false }
);

const App = (props: MomentAppProps) => {
	const { pageProps } = props;

	return (
		<>
			<Head
				// pageProps comes in as "any" so we need to check for existence
				title={pageProps?.title ?? null}
				description={pageProps?.description ?? null}
				url={pageProps?.url ?? null}
			/>
			<ClientActions />
			<ErrorBoundary FallbackComponent={FatalAppError}>
				<Providers pageProps={pageProps}>
					<Main {...props} />
					{/* <SpeedInsights /> */}
				</Providers>
			</ErrorBoundary>
		</>
	);
};

const getMeasurementIds = () => {
	if (process.env.APP_ENV === "production") {
		return "G-PBWVPLX26V";
	} else {
		return "G-4JR0PBCCBT";
	}
};

const analytics = Analytics({
	app: "Moment App",
	plugins: [
		googleAnalytics({
			measurementIds: getMeasurementIds(),
		}),
	],
});

// Use a variable to prevent running
// init routine for desktop app twice.
// https://react.dev/learn/you-might-not-need-an-effect#initializing-the-application
let didRunDesktopAppInit = false;

export const Main = ({ Component, pageProps }: AppProps) => {
	const dispatch = useAppDispatch();
	const { confirm } = useOutdatedAppModal();
	const isAppOutdatedVersion = useAppSelector(selectIsOutdatedAppVersion);
	const [showFindInPage, setShowFindInPage] = useState(false);
	const { user } = useAuth();

	// initialize datadog rum
	useEffectOnce(() => {
		initializeRum();
		initLogger();
	});

	// disable canvas caching for local-first for now
	/*
	useEffect(() => {
		if (!wb) {
			return;
		}

		// Pre-caching core canvases requires the user's auth info,
		// so don't trigger pre-caching if the user is not logged-in
		// yet.
		if (authState !== AuthStates.LoggedIn) {
			return;
		}

		// The docs for `messageSW()` state that the service worker
		// should set a response using `event.ports[0].postMessage(...)`
		// or the promise here won't resolve.
		wb.messageSW({
			type: "PRE_CACHE",
			urls: coreCanvases.map((c) => `/api/${c}?env=${env}`),
		}).catch((err) =>
			console.error("Error sending pre-caching message to the service worker", err)
		);
	}, [authState, env]);
	 */

	useEffect(() => {
		if (didRunDesktopAppInit) {
			return;
		}

		didRunDesktopAppInit = true;
		window.desktopIpcApi?.onAppIsTooOld(() =>
			dispatch(setIsOutdatedAppVersion({ isOutdatedAppVersion: true }))
		);
		window.desktopIpcApi?.onAppUpdateIsReady(() =>
			dispatch(setIsUpdateReady({ isAppUpdateReady: true }))
		);

		window.desktopIpcApi?.onShowFindInPage(() => setShowFindInPage(true));

		window.desktopIpcApi?.onShowError((message: string) => {
			makeToast({
				message,
				variant: "error",
			});
		});

		// Always set window ready at the end after all other
		// callbacks have been registered with the IPC API
		// exposed by preload.
		window.desktopIpcApi?.setWindowIsReady(true);
	}, [dispatch]);

	const { data, error } = useFetchConfigQuery(undefined);

	useEffect(() => {
		const anonUserID = data?.config?.userID;

		if (!isEmpty(anonUserID)) {
			setUserProperty("anonID", anonUserID);
		}
		if (error) {
			console.error("Failed to fetch desktop config", error);
		}
	}, [data, error]);

	useEffect(() => {
		const datadogUser: UserInfo = {
			id: "",
			email: "",
			isMoment: false,
			loggedIn: false,
		};

		if (!isEmpty(user)) {
			datadogUser.id = user.id;
			datadogUser.loggedIn = true;
			datadogUser.isMoment = user.isMomentEmployee;
			datadogUser.email = user.email;
			datadogUser.name = user.name;
			void analytics.identify(user.id, {
				isDesktop: isDesktopAppMode(),
			});
		}

		if (datadogUser.id !== "") {
			setUserProperty("id", datadogUser.id);
			setUserProperty("email", datadogUser.email);
			setUserProperty("isMoment", datadogUser.isMoment);
			setUserProperty("loggedIn", datadogUser.loggedIn);
			setUserProperty("name", datadogUser.name);
		}
	}, [user]);

	const [client, _setClient] = useState<ApolloClient<NormalizedCacheObject> | undefined>(
		undefined
	);

	const logger = useLogger("Main");

	useEffectOnce(() => {
		printEnvironment();
	});

	/*
	const makeApolloClient = useCallback(async () => {
		// only make an apollo client if we are logged in
		if (authState === AuthStates.LoggedIn) {
			try {
				const client = await dispatch(createClient()).unwrap();
				setClient(client);
			} catch (e) {
				logger.error("Error creating client", { error: `${e}` });
			}
		}
	}, [dispatch, authState, logger]);
	 */

	useEffect(() => {
		if (!isAppOutdatedVersion) {
			return;
		}

		confirm()
			.then((confirmed) => {
				if (!confirmed) {
					return;
				}

				window.desktopIpcApi?.restartToUpdate();
			})
			.catch((err) =>
				logger.error("Failed to show dialog for min version enforcement", {
					error: `${err}`,
				})
			);
	}, [confirm, logger, isAppOutdatedVersion]);

	/*
	useEffect(() => {
		void makeApolloClient();
	}, [makeApolloClient]);

	// if we are logged in, wait for apollo client to be created
	// before rendering app
	if (client === undefined && authState === AuthStates.LoggedIn) {
		return (
			<div className="absolute inset-0 size-full">
				<LoadingScreen />
			</div>
		);
	}
	 */

	return (
		<AnalyticsProvider instance={analytics}>
			<ApolloProvider client={client || new ApolloClient({ cache: new InMemoryCache() })}>
				{showFindInPage && <FindInPage onClose={() => setShowFindInPage(false)} />}
				<Component {...pageProps} />
				<ToastNotifications />
			</ApolloProvider>
		</AnalyticsProvider>
	);
};

App.getInitialProps = async (context: AppContext) => {
	const appProps = NextApp.getInitialProps(context);

	return { ...appProps, cookies: context.ctx.req?.headers.cookie };
};

export default App;
