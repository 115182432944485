import { useMutation } from "@tanstack/react-query";

import type { PageMetadata } from "@moment/api-collab/api-types";
import { generateShortUuid } from "@moment/api-collab/id";

import { createEmptyPage } from "~/components/canvas/easel/editor-state/createEmptyPage";

import { useUpdateDocumentMutation } from "../documents";

export const useCreatePageMutation = () => {
	const { updateAsync: updateDocument } = useUpdateDocumentMutation();

	const mutation = useMutation({
		mutationFn: async (args: {
			workspaceId: string;
			documentId: string;
			pageMetadata: Partial<PageMetadata>;
		}) => {
			const { workspaceId, documentId, pageMetadata } = args;

			const newPageId = pageMetadata.id ?? generateShortUuid();

			const now = new Date().toISOString();
			const newPage: PageMetadata = {
				archived: false,
				authorID: "",
				canvasID: documentId,
				created: now,
				id: newPageId,
				parentPageID: null,
				slug: newPageId,
				title: "",
				updated: now,
				...pageMetadata,
			};

			await window.desktopIpcApi?.writeLocalPageContents(workspaceId, documentId, newPageId, {
				doc: createEmptyPage().toJSON(),
				metadata: newPage,
			});

			await updateDocument(
				{
					workspaceId,
					documentId,
				},
				(document) => {
					if (!document) return document;

					document.pagesMetadata.push(newPage);
					return document;
				}
			);

			return newPage;
		},
	});
	return mutation;
};
