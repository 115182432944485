import NextHead from "next/head";
import { type FC } from "react";

import { getWebBaseURL } from "~/auth/interop/common";

import { Favicons } from "./Favicons";
import { Opengraph } from "./Opengraph";
import { Robots } from "./Robots";
import { Twitter } from "./Twitter";
import { type SEOConfig } from "./constants";

export const BaseSEO: FC<{ config: SEOConfig }> = ({ config }) => {
	// Always use production web base URL for SEO since we don't always have redux state wrapped around the component
	const webBaseUrl = getWebBaseURL(process.env.APP_ENV);
	const imageUrl = `${webBaseUrl}/default_opengraph.png`;

	return (
		<>
			<NextHead>
				<title>{config.baseTitle}</title>
				{/* 
                Short description of the document (limit to 150 characters)
                This content *may* be used as a part of search engine results.
            	*/}
				<meta name="description" content={config.description} key="description" />
				<meta name="theme-color" content={config.themeColor} key="theme-color" />

				{/* Identify the software used to build the document */}
				<meta name="generator" content="next.js" key="generator" />

				{/* Webmanifest */}
				<link rel="author" href="/humans.txt" key="author" />
			</NextHead>
			<Robots />

			<Favicons />

			<Opengraph
				url={webBaseUrl}
				type="website"
				title={config.baseTitle}
				description={config.description}
				site_name={config.baseTitle}
				locale="en_US"
				image_url={imageUrl}
			/>

			<Twitter
				title={config.baseTitle}
				description={config.description}
				card_type="summary_large_image"
				creator_url="https://www.moment.dev"
				creator_username="@moment_dev"
				image_url={imageUrl}
				image_alt="Moment"
			/>
		</>
	);
};
