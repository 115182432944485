import { z } from "zod";

import { CanvasListingItemSchema } from "../../models/CanvasListingItem";

export const WorkspaceSchema = z.object({
	id: z.string(),
	name: z.string().default(""),
	documents: z.record(CanvasListingItemSchema).default({}),
	recentlyViewed: z.array(z.string()).default([]),
});

export type Workspace = z.infer<typeof WorkspaceSchema>;
