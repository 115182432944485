// defaults to localStorage for web
import { type ApolloClient, type NormalizedCacheObject } from "@apollo/client";
import { type AnyAction, type ThunkDispatch, configureStore } from "@reduxjs/toolkit";
import { useStore } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { apolloClient } from "~/api/client";

import { defaultAppEnv } from "./client/slice";
import { desktopAppAPIs } from "./desktop-app/api";
import { exceptionHandler } from "./middlewares";
import { pageStorageSlice } from "./pageStorage/pageStorageSlice";
import { reducer } from "./reducer";

type StoreConfig = {
	logErrors: boolean;
	client?: ApolloClient<NormalizedCacheObject>;
};

const persistConfig = {
	key: "moment_v1",
	storage,
	// change to "allowlist" when redux-persist enables it
	// https://github.com/rt2zz/redux-persist/issues?q=is%3Aissue+is%3Aopen+allowlist
	whitelist: ["client", "auth", "recentlyViewed"],
};

const IS_PRODUCTION = process.env.APP_ENV === "production";

export const makeStore = ({ logErrors, client: clientInput }: StoreConfig) => {
	const client = clientInput === undefined ? apolloClient(defaultAppEnv) : clientInput;
	const persistedReducer = persistReducer(persistConfig, reducer);

	return configureStore({
		reducer: persistedReducer,
		devTools: !IS_PRODUCTION,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: false,
				thunk: {
					extraArgument: { client },
				},
			})
				.concat(pageStorageSlice.middleware)
				.concat(desktopAppAPIs.middleware)
				.concat(exceptionHandler(logErrors)),
	});
};

export type AppThunkConfig = { state: RootState };

export const store = makeStore({ logErrors: true });
export type Store = typeof store;

export const useAppStore = (): Store => {
	return useStore();
};

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type ExtraThunkArgs = {
	client?: ApolloClient<NormalizedCacheObject>;
};
export type ThunkArgs = {
	state: RootState;
	extra: ExtraThunkArgs;
};

export type AppDispatch = typeof store.dispatch;

export type AppThunkDispatch = ThunkDispatch<RootState, unknown, AnyAction>;
