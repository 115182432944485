import { Fragment, Slice } from "prosemirror-model";
import { type Command, TextSelection } from "prosemirror-state";

import { schema } from "@moment/api-collab/prosemirror-schema";

export const replaceComputeCellWithEmptyParagraphIfSinglySelected: Command = (state, dispatch) => {
	// Can't do anything if there's no dispatch function.
	if (!dispatch) {
		return false;
	}

	const sel = state.selection;
	const anchorNode = sel.$anchor.node();

	// Proceed only if the lenght of seleciton is 0 and the selection is in a `compute_cell`.
	if (sel.anchor !== sel.head || anchorNode.type !== schema.nodes.compute_cell) {
		return false;
	}

	// Else, replace the compute cell with an empty paragraph.
	const emptyParagraph = new Slice(Fragment.from(schema.nodes.paragraph.create()), 0, 0);
	let tr = state.tr.replace(sel.$anchor.start() - 1, sel.$anchor.end() + 1, emptyParagraph);
	tr = tr.setSelection(TextSelection.near(tr.doc.resolve(sel.$anchor.start()), 1));

	dispatch(tr.scrollIntoView());
	return true;
};
