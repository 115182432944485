import { type InputRule } from "prosemirror-inputrules";
import { type MarkType } from "prosemirror-model";

import { wrappingMarkRule } from "./wrappingMarkRule";

// Given an em markType returns an input rule that turns `"_text_"` in a text block
// into an <em> tag.

// match the beginning of the line or match when the the _ is preceded by a space

export const emUnderscoreRuleRegexp = /(?<spaceOrEmptyString>(?:^|\s))_([^_]+)_/;
export const emUnderscoreRule = (markType: MarkType): InputRule => {
	return wrappingMarkRule(emUnderscoreRuleRegexp, markType, {}, "spaceOrEmptyString");
};
