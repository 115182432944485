import { createSelector } from "@reduxjs/toolkit";
import { type Node } from "prosemirror-model";

import { schema } from "@moment/api-collab/prosemirror-schema";

import type { RootState } from "../store";
import { selectById } from "./slice";

export const selectEditorState = (pageId: string) => (state: RootState) => {
	return selectById(state.editors, pageId)?.editorState;
};

export const selectCompiledCells = (pageId: string) => (state: RootState) => {
	return selectById(state.editors, pageId)?.compiledCells;
};

export const selectSelectedComputeCellNodes = createSelector(
	[(state: RootState, pageId: string) => selectEditorState(pageId)(state)],
	(editorState) => {
		const nodes: Node[] = [];

		const selection = editorState?.selection;
		if (!selection) {
			return nodes;
		}

		editorState.doc.nodesBetween(selection.from, selection.to, (node) => {
			if (!node.attrs["id"] || node.type !== schema.nodes.compute_cell) {
				return true;
			}
			nodes.push(node);
			return true;
		});

		return nodes;
	}
);
