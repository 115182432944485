import { type WidgetViewComponentProps, widget } from "@nytimes/react-prosemirror";
import { nanoid } from "@reduxjs/toolkit";
import { type CreateTRPCClient } from "@trpc/client";
import { randomUUID } from "crypto";
import { extension } from "mime-types";
import { Plugin, PluginKey, type Transaction } from "prosemirror-state";
import { type MapResult, dropPoint } from "prosemirror-transform";
import { DecorationSet, type EditorView } from "prosemirror-view";
import { forwardRef } from "react";

import { schema } from "@moment/api-collab/prosemirror-schema";

import { isDesktopAppMode } from "~/auth/interop/common";
import { type AppRouter } from "~/utils/trpc";

interface ImageDropPluginDropMeta {
	type: "drop";
	payload: { pos: number };
}

interface ImageDropPluginSavedMeta {
	type: "saved";
}

type ImageDropPluginMeta = ImageDropPluginDropMeta | ImageDropPluginSavedMeta;

interface ImageDropPluginState {
	decorations: DecorationSet;
	transactions: Transaction[];
}

export const imageDropPluginKey = new PluginKey<ImageDropPluginState>("moment/image-drop");

function eventCoords(event: MouseEvent) {
	return { left: event.clientX, top: event.clientY };
}

function splitext(filename: string) {
	const parts = filename.split(".");
	const basename = parts.slice(0, parts.length - 1).join(".");
	const ext = parts.length > 1 ? "." + parts[parts.length - 1] : "";
	return [basename, ext] as const;
}

async function toDataArrayBuffer(htmlData: string | undefined, file: File | undefined) {
	if (htmlData) {
		const parsedDoc = new DOMParser().parseFromString(htmlData, "text/html").body;
		// TODO: handle HTML pastes with multiple images?
		const firstChild = parsedDoc.firstElementChild;
		if (!(firstChild instanceof HTMLImageElement)) return;
		const dataUri = firstChild.src;
		const response = await fetch(dataUri);
		const blob = await response.blob();
		const arrayBuffer = await blob.arrayBuffer();
		const basename = firstChild.alt
			? `${encodeURIComponent(firstChild.alt)}-${nanoid()}`
			: randomUUID();
		const mimeType = response.headers.get("content-type");
		const ext = mimeType ? extension(mimeType) : false;
		return {
			name: `${basename}${ext ? `.${ext}` : ""}`,
			contents: arrayBuffer,
		};
	}
	if (file) {
		const [basename, ext] = splitext(file.name);
		return {
			name: `${encodeURIComponent(basename)}-${nanoid()}${ext}`,
			contents: await file.arrayBuffer(),
		};
	}
}

export async function readAsDataURL(contents: ArrayBuffer) {
	return new Promise<string>((resolve, reject) => {
		const blob = new Blob([contents]);

		const reader = new FileReader();
		function onLoad(event: ProgressEvent<FileReader>) {
			if (!event.target) return reject(new Error("Failed to convert image data to data URL"));

			reader.removeEventListener("load", onLoad);
			resolve(event.target.result as string);
		}
		reader.addEventListener("load", onLoad);

		reader.readAsDataURL(blob);
	});
}

async function calculateImageDimensions(dataUri: string) {
	return new Promise<{ height: number; width: number }>((resolve) => {
		const image = new Image();
		image.addEventListener("load", () => {
			resolve({
				width: image.naturalWidth,
				height: image.naturalHeight,
			});
		});
		image.src = dataUri;
	});
}

async function persistImage(
	trpcClient: CreateTRPCClient<AppRouter>,
	view: EditorView,
	workspaceId: string,
	documentId: string,
	pageId: string,
	insertPos: number,
	name: string | undefined,
	contents: ArrayBuffer | undefined
) {
	if (!name || !contents) return;

	const dataUri = await readAsDataURL(contents);
	await trpcClient.pages.writeRelatedFileContents.mutate({
		workspaceId,
		documentId,
		pageId,
		filename: name,
		contents: dataUri.slice(dataUri.indexOf(",") + 1),
	});

	const { width, height } = await calculateImageDimensions(dataUri);

	const pos = insertPos;
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const { transactions } = imageDropPluginKey.getState(view.state)!;
	const mapped = transactions.reduce<null | MapResult>(
		(acc, tr) => (acc?.deleted ? acc : tr.mapping.mapResult(acc?.pos ?? pos)),
		null
	);

	const tr = view.state.tr;
	const meta: ImageDropPluginMeta = {
		type: "saved",
	};
	tr.setMeta(imageDropPluginKey, meta);

	if (mapped?.deleted) {
		view.dispatch(tr);
		return;
	}

	const aspectRatio = width / height;

	tr.insert(
		mapped?.pos ?? pos,
		schema.nodes.image.create({
			file: name,
			width: Math.min(width, 600),
			aspectRatio,
		})
	);
	view.dispatch(tr);
}

const ImagePlaceHolder = forwardRef<HTMLDivElement, WidgetViewComponentProps>(
	function ImagePlaceHolder({ widget: _widget, getPos: _getPos, ...props }, ref) {
		return (
			<div ref={ref} {...props}>
				Loading...
			</div>
		);
	}
);

export function imageDropPlugin(
	workspaceId: string,
	documentId: string,
	pageId: string,
	trpcClient: CreateTRPCClient<AppRouter>
) {
	return new Plugin({
		key: imageDropPluginKey,
		state: {
			init() {
				return { decorations: DecorationSet.empty, transactions: [] as Transaction[] };
			},
			apply(tr, value, _oldState, newState) {
				const meta = tr.getMeta(imageDropPluginKey) as ImageDropPluginMeta | undefined;
				if (!meta) return { ...value, transactions: [...value.transactions, tr] };

				if (meta.type === "drop") {
					return {
						decorations: DecorationSet.create(newState.doc, [
							widget(meta.payload.pos, ImagePlaceHolder, {
								key: "image-placeholder",
							}),
						]),
						transactions: [] as Transaction[],
					};
				}

				if (meta.type === "saved") {
					return { decorations: DecorationSet.empty, transactions: [] as Transaction[] };
				}

				return { decorations: DecorationSet.empty, transactions: [] as Transaction[] };
			},
		},
		props: {
			handlePaste(view, event) {
				if (!isDesktopAppMode()) return false;

				if (event.clipboardData?.types.includes("text/rtf")) {
					// Do not convert pasted rtf to image
					return false;
				}

				const clipboardItems = event.clipboardData?.items;
				if (!clipboardItems) return false;

				const items = Array.from(clipboardItems).filter((item) => {
					// Filter the image items only
					return item.type.indexOf("image") !== -1;
				});

				if (items.length === 0) {
					return false;
				}

				const item = items[0];
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				const file = item!.getAsFile();
				if (!file) {
					return false;
				}

				const tr = view.state.tr;

				let insertPos = view.state.selection.from;

				if (view.state.selection.empty) {
					const { $anchor } = view.state.selection;
					const depth = $anchor.depth;
					const node = $anchor.parent;
					if (depth === 1 && node.isTextblock && node.childCount === 0) {
						insertPos = $anchor.before();
						tr.delete($anchor.before(), $anchor.after());
					}
				} else {
					tr.deleteSelection();
				}

				const meta: ImageDropPluginMeta = {
					type: "drop",
					payload: { pos: insertPos },
				};
				tr.setMeta(imageDropPluginKey, meta);

				view.dispatch(tr);

				event.preventDefault();

				void toDataArrayBuffer(undefined, file).then((data) =>
					persistImage(
						trpcClient,
						view,
						workspaceId,
						documentId,
						pageId,
						insertPos,
						data?.name,
						data?.contents
					)
				);

				return true;
			},
			handleDrop(view, event, slice, moved) {
				if (!isDesktopAppMode()) return false;

				const htmlData = event.dataTransfer?.getData("text/html");
				const file = event.dataTransfer?.files?.[0];

				if ((!htmlData && !file) || moved) return false;

				const eventPos = view.posAtCoords(eventCoords(event));
				if (!eventPos) return false;

				const $mouse = view.state.doc.resolve(eventPos.pos);

				const dropPos = slice
					? (dropPoint(view.state.doc, $mouse.pos, slice) ?? $mouse.pos)
					: $mouse.pos;

				const $dropPos = view.state.doc.resolve(dropPos);
				const insertPos = $dropPos.after(1);

				const tr = view.state.tr;

				const meta: ImageDropPluginMeta = {
					type: "drop",
					payload: { pos: insertPos },
				};
				tr.setMeta(imageDropPluginKey, meta);
				view.dispatch(tr);

				void toDataArrayBuffer(htmlData, file).then((data) =>
					persistImage(
						trpcClient,
						view,
						workspaceId,
						documentId,
						pageId,
						insertPos,
						data?.name,
						data?.contents
					)
				);

				return true;
			},
		},
	});
}
