import { schema } from "@moment/api-collab/prosemirror-schema";

type MakeComputeCellArgs = {
	cellID: string;
	code: string;
	cellName: string;
	nodeAttrs?: Record<string, unknown>;
};

export const withoutID = (input: Record<string, unknown> | undefined) => {
	const output: Record<string, string> = {};
	if (input === undefined) {
		return output;
	}
	for (const k in input) {
		if (k !== "id") {
			output[k] = String(input[k]);
		}
	}
	return output;
};

export const makeComputeCell = ({ cellID, code, cellName, nodeAttrs }: MakeComputeCellArgs) => {
	const nodeAttrsWithoutID = withoutID(nodeAttrs);
	return schema.nodes.compute_cell.createChecked(
		{
			id: cellID,
			code,
			...nodeAttrsWithoutID,
			// VERY IMPORTANT FIXME: the `nodeAttrs.cellName` overrides `cellName` sometimes, so
			// pressing <enter> in some canvases overwrites ~all the cell names, completely breaking
			// it. I do not have a permanent fix, this is just to stop the bleeidng.
			cellName,
		},
		code ? schema.text(code) : undefined
	);
};
