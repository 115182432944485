import type { ActiveUser } from "@moment/api-collab/api-types";

import { type CellInfo } from "~/models/cell";
import { type JsxParameterInfo } from "~/runtime/cell-compiler/analysis/JsxParameterInfo";
import { type AutoImportActions } from "~/store/cells/types";
import { type CodeEditorState } from "~/store/code-editor/types";

export type SavingState = {
	status: "waiting" | "dirty" | "saving" | "saved" | "error";
	message: string;
};

export type DraftSavingState = {
	status: "waiting" | "saving";
};

export class NoActiveCanvasError extends Error {
	constructor(message?: string) {
		super(message);
		this.message = message === undefined ? "No Active Canvas" : message;
		this.name = "NoActiveCanvasError";
	}
}

export interface InspectingCell {
	cellInfo: CellInfo;
	rootJsxCellInfo: CellInfo;
	params: [string, JsxParameterInfo][];
}

export const PropertiesPaneStateValues = ["components", "cellDependencies"] as const;

export type Pane = (typeof PropertiesPaneStateValues)[number] | undefined;
export const PANE_LABELS: Record<Exclude<Pane, undefined>, string> = {
	components: "Components Tab",
	cellDependencies: "Cell Dependencies Tab",
} as const;

export const isPane = (value: unknown): value is Pane => {
	if (value === undefined) {
		return true;
	}

	if (typeof value !== "string") {
		return false;
	}

	return PropertiesPaneStateValues.includes(value as (typeof PropertiesPaneStateValues)[number]);
};

export type PropertiesPaneState = {
	pane: Pane;
	metadata?: {
		isInitiatedByUser?: boolean;
	};
};

export type Modes = "document" | "grid";

export interface CanvasState {
	activeDocumentID?: string;
	activePageID?: string;
	editor: CodeEditorState;
	inspectingCell?: string;
	propertiesPaneLoading: boolean;
	mode: Modes;
	activeUsers: ActiveUser[];
}

export type InsertActions = {
	autoImports?: AutoImportActions[];
};
