import cx from "classnames";
import { type Attrs, type Node } from "prosemirror-model";

import { DEFAULT_CELL_ID } from "./constants";

/**
 *
 * @param node prosemirror node
 * @param additionalClassNames additional class names to add to the node
 * @returns DOM attributes for the text cell node
 */
export const makeTextCellDefaultToDOMAttrs = (node: Node, additionalClassNames?: string) => {
	const classNames = cx(
		additionalClassNames,
		// This avoids the strange "mouse hovering over cell while you press enter animates it
		// slightly" problem.
		"transition-none"
	);
	const attrs: Attrs = {
		"data-text-cell": node.attrs["id"] ?? "",
		"id": node.attrs["id"] !== DEFAULT_CELL_ID ? node.attrs["id"] : undefined,
		"class": classNames,
	};

	return attrs;
};
