import { type Node } from "@babel/types";

/**
 * An immer-friendly port of Babel's not-exported core type, `BaseNode`, with extraneous fields
 * stripped out.
 */
export interface NodeLocation {
	start: number | null | undefined;
	end: number | null | undefined;
	// IMPORTANT: `loc` is not serializable to Redux, so we have to manually expand it.
	loc?: {
		start: {
			line: number;
			index?: number;
			column: number;
		};
		end: {
			line: number;
			index?: number;
			column: number;
		};
	} | null;
	range?: [number, number];
}

/**
 * Converts various Babel AST types to the Redux-serializable `NodeLocation`.
 */
export const toNodeLocation = (e: Node): NodeLocation => ({
	start: e.start,
	end: e.end,
	loc: e.loc && {
		start: {
			line: e.loc.start.line,
			column: e.loc.start.column,
		},
		end: { line: e.loc.end.line, column: e.loc.end.column },
	},
	range: e.range,
});
