import Mousetrap, { type MousetrapInstance } from "mousetrap";
import { useEffect } from "react";

type Callback = Parameters<MousetrapInstance["bind"]>[1];

export const useScopedKeyBinding = (keys: string | string[], callback: Callback) => {
	//
	// Define key binding on component mount, unbind on component unmount.
	//
	useEffect(() => {
		const mousetrap = new Mousetrap();
		mousetrap.stopCallback = function () {
			return false;
		};
		mousetrap.bind(keys, callback);
		return () => {
			mousetrap.unbind(keys);
		};
		// unbind/bind if callback changes
	}, [callback]);
};
