import { CheckmarkOutline } from "@carbon/icons-react";
import React, {
	type FC,
	type PropsWithChildren,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from "react";

import { BasicDialog } from "@moment/design-system/Dialog";

import { notImplementedFn } from "~/utils/context";

export interface ConfirmModalProps {
	headingTitle: string;
	headingIcon: React.ReactElement;
	content: string | React.ReactElement;
	variant: "normal" | "negative";
	confirmLabel: string;
	cancelLabel: string;
}

const DEFAULT_PROPS: ConfirmModalProps = {
	headingTitle: "Please confirm",
	headingIcon: <CheckmarkOutline />,
	content: "Are you sure you would like to continue?",
	variant: "normal",
	confirmLabel: "Confirm",
	cancelLabel: "Cancel",
};

type ConfirmFn = (props?: Partial<ConfirmModalProps>) => Promise<boolean>;
export type ConfirmModalContextState = {
	confirm: ConfirmFn;
};

export const initialState: ConfirmModalContextState = {
	confirm: notImplementedFn("confirm", "ConfirmModalContext", Promise.resolve(true)),
};

const ConfirmModalContext = createContext<ConfirmModalContextState>(initialState);

export const useConfirmModal = () => useContext(ConfirmModalContext);

export const ConfirmModalWrapper: FC<PropsWithChildren> = ({ children }) => {
	// State
	const [visible, setVisible] = useState(false);
	const [props, setProps] = useState(DEFAULT_PROPS);
	// We can't set the resolve function directly in state because anonymous function do not trigger state updates
	const [resolve, setResolve] = useState<{
		fn: undefined | ((resolve: boolean | PromiseLike<boolean>) => void);
	}>({ fn: undefined });

	// Methods
	const showDialog = useCallback(() => setVisible(true), [setVisible]);

	const closeDialog = useCallback(() => {
		setProps(DEFAULT_PROPS);
		setVisible(false);
	}, [setProps, setVisible]);

	const confirm: ConfirmFn = useCallback(
		(props) => {
			setProps({ ...DEFAULT_PROPS, ...props });
			showDialog();

			return new Promise<boolean>((resolve) => {
				setResolve({ fn: resolve });
			});
		},
		[setProps, showDialog, setResolve]
	);

	const onConfirm = useCallback(() => {
		resolve.fn?.(true);
		closeDialog();
	}, [resolve, closeDialog]);

	const onCancel = useCallback(() => {
		resolve.fn?.(false);
		closeDialog();
	}, [resolve, closeDialog]);

	const value = useMemo(() => ({ confirm }), [confirm]);

	return (
		<ConfirmModalContext.Provider value={value}>
			{children}
			<BasicDialog
				isOpen={visible}
				closeDialog={onCancel}
				title={props.headingTitle}
				titleIcon={props.headingIcon}
				description={props.content}
				variant={props.variant}
				confirmText={props.confirmLabel}
				onConfirm={onConfirm}
				cancelText={props.cancelLabel}
			/>
		</ConfirmModalContext.Provider>
	);
};
