import { useMutation, useQueryClient } from "@tanstack/react-query";

import { documentQueryKeys } from ".";
import { useArchiveRepoMutation } from "../gitea";
import { useUpdateWorkspaceMutation, workspaceQueryKeys } from "../workspaces";
import { LOCAL_WORKSPACE_ID } from "../workspaces/constants";

export const useDeleteDocumentMutation = () => {
	const queryClient = useQueryClient();

	const { updateAsync: updateWorkspace } = useUpdateWorkspaceMutation();
	const { mutateAsync: archiveRepo } = useArchiveRepoMutation();

	const mutation = useMutation({
		mutationKey: documentQueryKeys.deleteDocument,
		mutationFn: async (args: { workspaceId: string; documentId: string }) => {
			if (args.workspaceId !== "local") {
				// Archive the repo on Gitea
				await archiveRepo(args);
			}

			// Delete the document from drive
			await window.desktopIpcApi?.deleteLocalDocument(args.workspaceId, args.documentId);

			return updateWorkspace({ workspaceId: args.workspaceId }, (workspace) => {
				if (!workspace) throw new Error("Workspace not found");

				delete workspace.documents[args.documentId];

				return workspace;
			});
		},
		async onSuccess(_data, variables) {
			const location = variables.workspaceId === LOCAL_WORKSPACE_ID ? "local" : "remote";
			await queryClient.invalidateQueries({
				queryKey: workspaceQueryKeys.workspaces(location, false),
			});
			await queryClient.invalidateQueries({
				queryKey: workspaceQueryKeys.workspaces(location, true),
			});
			await queryClient.invalidateQueries({
				queryKey: workspaceQueryKeys.workspace(location, variables.workspaceId),
			});
		},
	});

	return mutation;
};
