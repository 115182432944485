import cx from "classnames";
import { Plugin, PluginKey, type Transaction } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";

export const highlightPluginKey = new PluginKey<DecorationSet>("highlight");

export const addHighlight = ({
	from,
	to,
	tr,
	resolvedTheme,
}: {
	from: number;
	to: number;
	tr: Transaction;
	resolvedTheme: string;
}) => {
	tr.setMeta(highlightPluginKey, { from, to, resolvedTheme });
};

export const removeHighlight = (tr: Transaction) => {
	tr.setMeta(highlightPluginKey, {});
};

export const highlightPlugin = () =>
	new Plugin({
		key: highlightPluginKey,
		state: {
			init() {
				return DecorationSet.empty;
			},
			apply(tr, value, oldState) {
				const meta = tr.getMeta(highlightPluginKey);
				if (meta) {
					const { from, to } = meta;
					return DecorationSet.create(oldState.doc, [
						Decoration.inline(from, to, {
							class: cx(
								"highlight",
								"bg-brand",
								meta.resolvedTheme === "light" && "text-inverse-primary",
								meta.resolvedTheme === "dark" && "text-primary",
								"py-0.5"
							),
						}),
					]);
				}
				return value.map(tr.mapping, tr.doc);
			},
		},

		props: {
			decorations(state) {
				return this.getState(state);
			},
		},
	});
