import { type Attrs, Fragment, type Node } from "prosemirror-model";

import { schema } from "@moment/api-collab/prosemirror-schema";
import { createCellID } from "@moment/api-collab/prosemirror-utils";

type MakeTextCellArgs = {
	cellID?: string;
	content?: Fragment;
	nodeAttrs?: Record<string, unknown>;
};

export const makeTextCell = (args: MakeTextCellArgs) => {
	const cellID = args.cellID || createCellID();

	const content = args.content || Fragment.fromArray([schema.nodes.paragraph.createChecked()]);

	const attrs: Attrs = {
		id: cellID,
		...args.nodeAttrs,
	};

	const nodes: Node[] = [];

	for (let i = 0; i < content.childCount; i++) {
		const child = content.child(i);
		if (child !== null) {
			nodes.push(child.type.createChecked({ ...child.attrs, ...attrs }, child.content));
		}
	}

	return nodes;
};
