import { type InputRule, wrappingInputRule } from "prosemirror-inputrules";
import { type NodeType } from "prosemirror-model";

import { createCellID } from "@moment/api-collab/prosemirror-utils";

// Given a blockquote node type, returns an input rule that turns `"> "`
// at the start of a textblock into a blockquote.
export const blockQuoteRule = (nodeType: NodeType): InputRule => {
	return wrappingInputRule(/^\s*>\s$/, nodeType, () => ({
		id: createCellID(),
	}));
};
