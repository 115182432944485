import { isEmpty } from "lodash";

export const getDocsApiUrl = (): string => {
	// set NEXT_PUBLIC_GITEA_API_URL env to  "localhost:8088" if you want to use local backend.
	const giteaApiUrl = process.env["NEXT_PUBLIC_GITEA_API_URL"];

	if (typeof giteaApiUrl === "string" && !isEmpty(giteaApiUrl)) {
		if (giteaApiUrl.startsWith("http")) {
			return giteaApiUrl;
		}
		return `https://${giteaApiUrl}`;
	}

	// TODO: we should NOT assume the production endpoint
	if (process.env.APP_ENV === "production") {
		return "https://git.moment.dev";
	}

	return "https://git-staging.moment.dev";
};
//
// this is used to fetch from docsapi endpoints, with prefix /moment/v1
export const createDocsApiFetch = (token: string, autothrow: boolean = true) => {
	return (path: string, init: RequestInit) => {
		const url = `${getDocsApiUrl()}/moment/v1${path}`;
		const fetch = docsApiFetch(token, autothrow);
		return fetch(url, init).then((response) => {
			return response.json();
		});
	};
};

export function docsApiFetch(token: string, autothrow: boolean = true) {
	return (input: string | URL | globalThis.Request, init?: RequestInit): Promise<Response> => {
		const headers = {
			...init?.headers,
		};
		if (!isEmpty(token)) {
			headers["Authorization"] = `Bearer ${token}`;
		}
		return fetch(input, {
			...init,
			headers,
		}).then((response) => {
			if (!response.ok && autothrow) {
				throw new Error(response.statusText, { cause: response });
			}
			return response;
		});
	};
}
