import { useMemo } from "react";

import type { CanvasPageMetadata } from "@moment/api-collab/api-types";

import { useEasel } from "../easel";
import { useCurrentDocument } from "./useCurrentDocument";

export const useEaselCurrentPage = (): CanvasPageMetadata | null => {
	const easel = useEasel();

	const activePageID = useMemo(() => easel.pageId, [easel]);
	const currentDocument = useCurrentDocument();

	if (!currentDocument) return null;

	const page = currentDocument.pagesMetadata.find((page) => page.id === activePageID);

	return page ?? null;
};
