import { Fragment, Slice } from "prosemirror-model";
import { AllSelection, type Command, TextSelection } from "prosemirror-state";

import { schema } from "@moment/api-collab/prosemirror-schema";

/**
 * If `state.selection` is an `AllSelection`, replace the document with an empty paragraph.
 */
export const replaceDocWithEmptyParagraphIfAllSelection: Command = (state, dispatch) => {
	if (!(state.selection instanceof AllSelection)) {
		return false;
	}

	const contentSize = state.tr.doc.content.size;
	const emptyParagraph = new Slice(Fragment.from(schema.nodes.paragraph.create()), 0, 0);

	// NOTE: Remove stored marks because otherwise they will remain in the document.
	let tr = state.tr.setStoredMarks([]).replace(0, contentSize, emptyParagraph);

	// NOTE: Set selection manually, otherwise it's still `AllSelection`.
	tr = tr.setSelection(new TextSelection(tr.doc.resolve(1)));

	// Dispatch and scroll into view.
	dispatch?.(tr.scrollIntoView());
	return true;
};
