import { type Attrs, type DOMOutputSpec, type Node, type NodeSpec } from "prosemirror-model";

// :: NodeSpec An image (`<image>`)

export const image: NodeSpec = {
	attrs: {
		src: { default: null },
		file: { default: null },
		width: { default: 600 },
		aspectRatio: { default: null },
		alignment: { default: "center" },
	},
	group: "block",
	parseDOM: [
		{
			// Images that were created by Moment will always have a
			// "file" data attr, so we check for those first, since they'll
			// have more detailed information
			tag: "img[data-file]",
			getAttrs(node: string | HTMLElement) {
				if (!(node instanceof HTMLElement)) {
					return false;
				}

				const attrs: Attrs = {
					src: null,
					file: node.dataset["file"],
					width: node.getAttribute("width"),
					aspectRatio: node.dataset["aspectRatio"] ?? node.style["aspect-ratio"],
					alignment: node.dataset["alignment"],
				};

				return attrs;
			},
		},
		{
			// We also want to support images that already exist in imported
			// docs, so we support normal image sources as well
			tag: "img[src]",
			getAttrs(node: string | HTMLElement) {
				if (!(node instanceof HTMLImageElement)) {
					return false;
				}

				const attrs: Attrs = {
					src: node.src,
					file: null,
					width: node.getAttribute("width"),
					aspectRatio: node.dataset["aspectRatio"] ?? node.style["aspect-ratio"],
					alignment: node.dataset["alignment"],
				};

				return attrs;
			},
		},
	],
	toDOM(node: Node): DOMOutputSpec {
		const attrs = node.attrs;
		return [
			"img",
			{
				"src": `related/${attrs["file"]}`,
				"data-file": attrs["file"],
				"width": attrs["width"],
				"style": `aspect-ratio: ${attrs["aspectRatio"]};`,
				"data-aspect-ratio": attrs["aspectRatio"],
				"data-alignment": attrs["alignment"],
			},
		];
	},
};
