export { useCreateDocumentMutation } from "./useCreateDocumentMutation";
export { useDeleteDocumentMutation } from "./useDeleteDocumentMutation";
export { useDocumentQuery } from "./useDocumentQuery";
export { useRenameDocumentMutation } from "./useRenameDocumentMutation";
export { useUpdateDocumentMutation } from "./useUpdateDocumentMutation";
export { useToggleDocumentPinnedMutation } from "./useToggleDocumentPinnedMutation";

export const documentQueryKeys = {
	base: ["documents"],
	document: (workspaceId: string, documentId: string) => ["documents", workspaceId, documentId],
	createDocument: ["documents", "createDocument"],
	deleteDocument: ["documents", "deleteDocument"],
	renameDocument: ["documents", "renameDocument"],
	updateDocument: ["documents", "updateDocument"],
};
