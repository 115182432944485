import { type Cell, CellType } from "~/models/cell";
import { type PageMap } from "~/models/page";
import { type Env } from "~/utils/common";
import {
	type FunctionBodyCell,
	FunctionBodyCellDispatcher,
	transform,
} from "~/utils/function-body-cell";

import type { ImportFromMomentNamespace } from "../../import";
import { type SpecifierInfo, importSpecifiers, tryAnalyzeRenderCallExpression } from "../analysis";

/**
 * Code and metadata emitted by the cell compiler, to be executed by the runtime. This type attempts
 * to bridge the gap between high-level cell types and the low-level cell runtime APIs, e.g., a
 * function body cell and most types of Observable cells will be run using the cell runtime's
 * `variable` API, and are therefore emitted as `VariableDefinitionEmitInfo`.
 *
 * The emitted code can be run immediately with no additional processing, and no unnecessary
 * knowledge of the "type" of the cell the code was compiled from. So, e.g., Observable cells,
 * function body cells, etc., will all have been compiled to something the runtime understands; all
 * async processing operations (e.g., fetching of source code for imports) has already occurred and
 * compiled down to a single string; etc.
 *
 * Code emitted is of the "standard" form expected by the runtime's variable definition API, i.e.:
 *
 *     (runtime, observer) => { <compiled cell code> }
 *
 * This is usually provided to the runtime for execution roughly as so:
 *
 *     const f = Function(`return ${emitInfo.emittedDefineFunction}`)()
 *     mainModule.variable.define(cellName, refs, f);
 *
 * The runtime registers the compiled cell code such that any cell with a `refs` containing
 * `cellName` will subscribe to the output of this cell.
 */
export type EmitInfo = ValidCellEmitInfo | InvalidEmitInfo;

type RichTextEmitInfo = {
	kind: "RichText";
	parsedMarkdown: string;
};

type EmptyEmitInfo = {
	kind: "Empty";
};

/**
 * Code and metadata emitted by the compiler for a *syntactically valid* cell, to be executed by the
 * runtime. The emitted code can be run immediately with no additional processing.
 *
 * @see {@link EmitInfo} for more context about code emission types and how they are used.
 */
export type ValidCellEmitInfo =
	| VariableDefinitionEmitInfo
	| ViewVariableDefinitionEmitInfo
	| CanvasImportEmitInfo
	| PageImportEmitInfo
	| RichTextEmitInfo
	| EmptyEmitInfo;

/**
 * Code and metadata emitted by the compiler, to be run by the cell runtime's `variable` API. This
 * code is of the "standard" form expected by the runtime's variable definition API, i.e.:
 *
 *     (runtime, observer) => { <compiled cell code> }
 *
 * This is usually provided to the runtime for execution roughly as so:
 *
 *     const f = Function(`return ${emitInfo.emittedDefineFunction}`)()
 *     mainModule.variable.define(cellName, refs, f);
 *
 * @see {@link EmitInfo} for more context about code emission types and how they are used.
 */
export interface VariableDefinitionEmitInfo {
	kind: "VariableDefinition";
	id?: string;
	refs?: string[];
	emittedDefineFunction: string;
}

/**
 * Code and metadata emitted by the compiler for cells that are "views" of (e.g.) React components
 * and HTML elements, to be run using the cell runtime's `variable` APIs.
 *
 * For example, an Observable cell may have code like:
 *
 *     viewof myText = html`<input />`;
 *
 * When an end user updates the text in the `input` element, all changes will be published
 * automatically to any cell referencing a global variable `myText`.
 *
 * This type is very similar to `VariableDefinitionEmitInfo`, with the main difference being that
 * the runtime needs to set up some scaffolding around the emitted code. The emitted code itself is
 * similar in that it is of the "standard" form expected by the runtime's variable definition API,
 * i.e.:
 *
 *     (runtime, observer) => { <compiled cell code> }
 *
 * This is usually provided to the runtime for execution roughly as follows. Note the runtime sets
 * up additional "scaffolding" `variable`s so that it can enumerate the view.
 *
 *     const f = Function(`return ${emitInfo.emittedDefineFunction}`)()
 *     mainModule.variable.define(cellName, refs, f);
 *
 * @see {@link EmitInfo} for more context about code emission types and how they are used.
 */
export interface ViewVariableDefinitionEmitInfo {
	kind: "ViewVariableDefinition";
	id?: string;
	refs?: string[];
	emittedDefineFunction: string;
}

/**
 * Code and metadata emittedby the compiler for cells that import cells from other places.
 *
 * For example, a cell may have code like:
 *
 *     import { myCell } from "@example/myCanvas"
 *
 * The emission routines will have fetched canvas metadata and compiled it to a single function of
 * "standard" form expected by the runtime's variable definition API, i.e.:
 *
 *     (runtime, observer) => { <compiled cell code> }
 *
 * This can then be provided as a module to the cell runtime roughly as follows:
 *
 *     const f = Function(`return ${emitInfo.emittedDefineFunction}`)();
 *     const newModule = runtime.module(f);
 *     mainModule.variable().import("myCell", "myCell", newModule);
 *
 * @see {@link EmitInfo} for more context about code emission types and how they are used.
 */
export interface CanvasImportEmitInfo {
	kind: "CanvasImport";
	specifiers: { [localIdentifier: string]: SpecifierInfo };
	emittedDefineFunction: string;
	arg: ImportFromMomentNamespace;
	env?: NonNullable<MomentAppEnv>;
}

export interface PageImportEmitInfo {
	kind: "PageImport";
	specifiers: { [localIdentifier: string]: SpecifierInfo };
	emittedDefineFunction: string;
}

/**
 * Represents a cell that could not be emitted because of a static error, e.g., a lexical analysis
 * error.
 *
 * @see {@link EmitInfo} for more context about code emission types and how they are used.
 */
export type InvalidEmitInfo = { kind: "StaticError"; message: string };

/**
 * Compile and emit a cell as primitives that can be understood by the cell runtime's core APIs,
 * e.g., the `variable` API.
 *
 * @see {@link EmitInfo} for more context about code emission types and how they are used.
 */
export const emitCell = async (cell: Cell, pages: PageMap, env?: Env): Promise<EmitInfo> => {
	switch (cell.type) {
		case CellType.JavaScriptFunctionBodyCell: {
			return await emitFunctionBodyCell(cell, pages, env);
		}
		case CellType.ProseMirrorJSONCell: {
			return {
				kind: "RichText",
				parsedMarkdown: cell.code,
			};
		}
	}
};

export const _emitDefinedFunction = async (parsed: FunctionBodyCell, cellSpec: Cell) => {
	const renderCallExpression = await tryAnalyzeRenderCallExpression(
		cellSpec,
		parsed,
		{},
		false,
		[]
	);
	const refsInRenderCall = renderCallExpression?.refsInRenderCall || null;

	// if function is async, prepend with async keyword
	const async = parsed.async ? "async" : "";

	// if function is a generator, use `function*` declaration,
	// otherwise fallback to `function` declaration
	const declaration = parsed.generator ? "function*" : "function";

	// references are arguments passed to the function
	const references = (parsed.references || []).map((id) => id.name);

	// remove the render() body's cell references
	// e.g.
	// ```
	// 		cell0;
	//		render(() => <div>{cell1}</div>);
	// ```
	// will yield
	// 		references = ["cell0", "cell1"];
	//		refsInRenderCall = ["cell1"]
	//		referencesOutsidesOfRenderCall = ["cell0"];
	//
	const referencesOutsidesOfRenderCall = refsInRenderCall
		? [
				...references.filter((name) => !refsInRenderCall.has(name)),
				"useCellValue",
				"ReactErrorBoundary",
				"designSystem",
				"DesignSystemThemeProvider",
			]
		: references;

	// the babel transformed body of the function
	// which is stored in the `code` field of the cell
	const body = (await transform(parsed))?.code;

	/* put it all together, creates something like this:
		`async function* (cellA, cellB) {
			// ... cell code
		}`
	*/
	const emittedFunction = `${async} ${declaration} (${referencesOutsidesOfRenderCall.join(
		", "
	)}) {
${body}
}`;
	return { emittedFunction, renderCallExpression, referencesOutsidesOfRenderCall };
};

const emitFunctionBodyCell = async (
	cellSpec: Cell | null,
	pages: PageMap,
	env?: Env
): Promise<EmitInfo> => {
	if (cellSpec === null) {
		return { kind: "Empty" };
	}

	// Parse `code` field of the cell and define any variables in the runtime as necessary.
	const dispatcher = new FunctionBodyCellDispatcher<EmitInfo>({
		onPageImport: async (_, declaration, arg) => {
			const page = pages[arg.slug];

			if (page === undefined) {
				return {
					kind: "StaticError",
					message: `cannot import from non-existent page "${arg.slug}"`,
				};
			}

			return {
				kind: "PageImport",
				emittedDefineFunction: await emitCanvasPageDefineFunction(arg.slug, pages, env),
				specifiers: importSpecifiers(declaration),
			};
		},
		onMomentNamespaceImport: (_, declaration, arg) => {
			return {
				kind: "CanvasImport",
				emittedDefineFunction: emitMomentNamespaceImport(arg, env),
				specifiers: importSpecifiers(declaration),
				arg,
				env,
			};
		},
		onMomentIdImport: (_parsed, _declaration, _arg) => {
			throw new Error("imports from Moment service not yet supported");
		},
		onLocalImport: (_parsed, _declaration, _arg) => {
			throw new Error("local imports not supported");
		},
		onEmptyCell: (_parsed) => {
			return { kind: "Empty" };
		},
		onFunctionBodyCell: async (parsed) => {
			const { emittedFunction, renderCallExpression, referencesOutsidesOfRenderCall } =
				await _emitDefinedFunction(parsed, cellSpec);
			if (renderCallExpression !== undefined && parsed.cellName !== "") {
				return {
					kind: "ViewVariableDefinition",
					id: parsed.cellName,
					refs: referencesOutsidesOfRenderCall,
					emittedDefineFunction: emittedFunction,
				};
			}
			return {
				kind: "VariableDefinition",
				id: parsed.cellName,
				refs: referencesOutsidesOfRenderCall,
				emittedDefineFunction: emittedFunction,
			};
		},
		onStaticError: (staticError: Error) => {
			return {
				kind: "StaticError",
				message: staticError.message,
			};
		},
	});

	if (!cellSpec.cellName) {
		return { kind: "StaticError", message: "cell name is required" };
	}

	return await dispatcher.dispatch(cellSpec.cellName, cellSpec.code);
};

const emitMomentNamespaceImport = (arg: ImportFromMomentNamespace, env?: Env): string => {
	const { namespace, canvasName, pageSlug } = arg;
	return `import("/api/@${namespace}/${canvasName}.js${env ? `?env=${env}` : ""}").then((canvas) => {
		try {
			const pmm = canvas.default;
			return pmm["${pageSlug || "index"}"](pmm);
		} catch (e) {
			throw Error("failed to import module @${namespace}/${canvasName}/${pageSlug || "index"}");
		}
	})`;
};

/**
 * emitCanvasPageDefineFunction takes a page slug and page map, takes the matching page within the page map
 * and and compiles it to a function that "imports" every cell as a variable into a module, resolving any
 * additional page imports along the way. For example:
 *
 *     const define = emitCanvasPageDefineFunction("page-1", myPageMap);
 *     myRuntime.module(define);
 */
export const emitCanvasPageDefineFunction = async (slug: string, pages: PageMap, env?: Env) => {
	let err: InvalidEmitInfo | undefined;
	const page = pages[slug];
	if (!page) {
		throw new Error("Page not found");
	}
	const variableDeclCode = (
		await Promise.all(page.cells.map((cell) => emitCell(cell, pages, env)))
	).flatMap<string>((parsed) => {
		if (parsed.kind === "StaticError") {
			err = parsed;
			return [];
		}

		if (parsed.kind === "VariableDefinition") {
			const refs = (parsed.refs || []).map((r) => `"${r}"`);
			const def = parsed.emittedDefineFunction;
			return [
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				parsed.id === undefined || parsed.id === null || parsed.id === ""
					? `main.variable(observer()).define([${refs.join(", ")}], ${def});`
					: `main.variable(observer("${parsed.id}")).define("${parsed.id}", [${refs.join(
							", "
						)}], ${def});`,
			];
		} else if (parsed.kind === "ViewVariableDefinition") {
			const refs = (parsed.refs || []).map((r) => `"${r}"`);
			const def = parsed.emittedDefineFunction;
			return [
				`main.variable(observer("viewof ${parsed.id}")).define("viewof ${
					parsed.id
				}", [${refs.join(", ")}], ${def});
    main.variable(observer("${parsed.id}")).define("${parsed.id}", ["Generators", "viewof ${
		parsed.id
	}"], (G, _) => G.input(_));`,
			];
		} else if (parsed.kind === "CanvasImport") {
			return [
				"{",
				...Object.values(parsed.specifiers).map(
					(spec) =>
						`    const ${spec.localIdentifier} = main.variable().define("${spec.localIdentifier}", () => new Promise(() => {}));`
				),
				`    ${parsed.emittedDefineFunction}.then(define => {`,
				`        const mod = runtime.module(define);`,
				...Object.values(parsed.specifiers).map(
					(spec) =>
						`        ${spec.localIdentifier}.import("${spec.imported}", "${spec.localIdentifier}", mod);`
				),
				`    })`,
				"}",
			];
		} else if (parsed.kind === "PageImport") {
			return [
				"{",
				`    const mod = runtime.module(${parsed.emittedDefineFunction});`,
				...Object.values(parsed.specifiers).map(
					(spec) => `    main.import("${spec.imported}", "${spec.localIdentifier}", mod);`
				),
				"}",
			];
		}

		return [];
	});

	if (err) {
		throw err;
	}

	return `function (runtime, observer) {
    const main = runtime.module();
    ${variableDeclCode.join("\n    ")}
    return main;
}`;
};
