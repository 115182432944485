import cookie from "cookie";
import Cookies from "js-cookie";
import { useState } from "react";

import { compressJSON } from "~/utils/compression/compressJSON";
import { decompressJSON } from "~/utils/compression/decompressJSON";

import { type FeatureFlag, Flags } from "./FeatureFlag";
import { Overrides } from "./Overrides";

export const MOMENT_FEATURE_FLAGS = "moment_feature_flags";
export const MOMENT_FEATURES_HEADER = "x-moment-features";

export const encodedFeatures = (): string => {
	return compressJSON(Features);
};

export const setFeatureFlagsCookie = (flags: FeatureFlag) => {
	if (typeof window === "undefined") {
		return;
	}

	Cookies.set(MOMENT_FEATURE_FLAGS, compressJSON(flags));
};

export const getFeatureFlagsFromCookie = (): Partial<FeatureFlag> => {
	if (typeof window === "undefined") {
		return {};
	}
	try {
		return decompressJSON(Cookies.get(MOMENT_FEATURE_FLAGS) || "");
	} catch (e) {
		return {};
	}
};

export const getFlagsFromServerCookie = (cookies: string) => {
	const parsed = cookie.parse(cookies);

	const featureFlags = parsed[MOMENT_FEATURE_FLAGS];

	try {
		return decompressJSON(featureFlags || "");
	} catch (e) {
		return {};
	}
};

export let Features: FeatureFlag = {
	[Flags.CanvasLayoutVisualDebug]: false,
	[Flags.CanvasPreviewAs]: false,
	[Flags.CollabServiceConnectionCalloutBanner]: false,
	[Flags.ForceDisableCollabEditing]: false,
	[Flags.OnboardingDevTools]: false,
	[Flags.OpenSearch]: false,
	[Flags.SettingsAtlasForceSetup]: false,
	[Flags.TestFlagFalse]: false, // always set to false
	[Flags.TestFlagTrue]: true, // always set to true

	...(process.env.APP_ENV ? Overrides[process.env.APP_ENV] : {}),
	...getFeatureFlagsFromCookie(),
};

export const isEnabled = (flag: Flags, cookies?: string): boolean => {
	if (cookies) {
		const flags = getFlagsFromServerCookie(cookies) as Partial<FeatureFlag>;
		withOverrides(flags);
	}
	return Features[flag];
};

export const withOverrides = (features: Partial<FeatureFlag>) => {
	Features = { ...Features, ...features };
};

export const useFeatureFlags = () => {
	const [features, setFeatures] = useState<FeatureFlag>(Features);
	const setFlag = (flag: Flags, value: boolean) => {
		Features[flag] = value;
		setFeatures({ ...Features });
		setFeatureFlagsCookie({ ...Features });
		window.location.reload();
	};

	const isEnabled = (flag: Flags) => features[flag];

	return { features, setFlag, isEnabled };
};

export type { FeatureFlag };
export { Flags };
