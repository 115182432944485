/**
 * An argument to an import statement, e.g., `import { PullRequestList } from "@moment/github"`.
 *
 * We currently support importing from several sources:
 *
 *   import { PullRequestList } from "@moment/github" // From an org in Moment's service.
 *   import { PullRequestList } from "1234567890" // From a Canvas SHA in Moment's service.
 *   import { PullRequestList } from "local:1234567890" // From a locally-stored Canvas.
 */
export type ImportArgument =
	| ImportFromMomentNamespace
	| ImportFromMomentId
	| ImportFromLocal
	| ImportFromPage;

export interface ImportFromMomentNamespace {
	type: "fromMomentNamespace";
	namespace: string;
	canvasName: string;
	pageSlug?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isImportFromMomentNamespace = (o: any): o is ImportFromMomentNamespace => {
	return o && o.type === "fromMomentNamespace";
};

export interface ImportFromMomentId {
	type: "fromMomentId";
	canvasId: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isImportFromMomentId = (o: any): o is ImportFromMomentId => {
	return o && o.type === "fromMomentId";
};

export interface ImportFromLocal {
	type: "fromLocal";
	canvasId: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isImportFromLocal = (o: any): o is ImportFromLocal => {
	return o && o.type === "fromLocal";
};

export interface ImportFromPage {
	type: "fromPage";
	slug: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isImportFromPage = (o: any): o is ImportFromPage => {
	return o && o.type === "fromPage";
};

/**
 * Parse the argument to an import statement. For example, in the following import "@moment/github"
 * is the argument:
 *
 *   import { PullRequestList } from "@moment/github"
 */
export const parseImportArgument = (arg: string): ImportArgument => {
	if (arg === ".") {
		return { type: "fromPage", slug: "index" };
	} else if (arg.startsWith("./")) {
		const slug = arg.replace(/^.\//, "");

		return { type: "fromPage", slug };
	} else if (arg.startsWith("local:")) {
		const id = arg.replace(/^local:/, "");

		return { type: "fromLocal", canvasId: id };
	} else {
		if (arg.startsWith("@")) {
			const [namespace, canvasName, pageSlug, ...rest] = arg.split("/");

			if (namespace === undefined) {
				throw new Error("invalid namespace: undefined");
			}

			if (canvasName === undefined) {
				throw new Error("invalid canvasName: undefined");
			}

			if (rest.length !== 0) {
				throw new Error(
					"scoped imports must be of the form '@owner/canvasName' or '@owner/canvasName/pageSlug'"
				);
			}

			// pageSlug is undefined (not equal to "") if not present (@owner/canvasName)
			// if it is defined, it must be a non-zero length
			if (namespace.length < 2 || canvasName.length < 1 || pageSlug === "") {
				throw new Error(
					"scoped imports must be of the form '@owner/canvasName' or '@owner/canvasName/pageSlug'"
				);
			}

			return {
				type: "fromMomentNamespace",
				namespace: namespace.slice(1),
				canvasName,
				pageSlug,
			};
		} else {
			return { type: "fromMomentId", canvasId: arg };
		}
	}
};
