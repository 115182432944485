import { useMutation } from "@tanstack/react-query";

import { getGiteaApiUrl } from "~/utils/gitea";
import { trpc } from "~/utils/trpc";

export const useAddGitRemoteMutation = () => {
	const { mutateAsync: addRemote } = trpc.git.addRemote.useMutation();

	const mutation = useMutation({
		mutationFn: async (args: { workspaceId: string; documentId: string }) => {
			const { workspaceId, documentId } = args;

			const remoteUrl = `${getGiteaApiUrl()}/git/${workspaceId}/${documentId}.git`;
			await addRemote({ workspaceId, documentId, remoteUrl });
		},
	});

	return mutation;
};
