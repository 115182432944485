import { Checkmark, Close, Error } from "@carbon/icons-react";
import { useButton } from "@react-aria/button";
import cx from "classnames";
import { useRef } from "react";

import { Button } from "../Button";
import { ForceTheme } from "../ThemeProvider";
import type { ToastComponentProps } from "./types";

const ActionButton = ({ label, callback }) => {
	const el = useRef<HTMLSpanElement | null>(null);

	const { buttonProps } = useButton(
		{
			"aria-label": label,
			"onPress": () => callback(),
			// elemetType field helps @react-aria to create
			// appropriate labels for our button
			"elementType": "span",
		},
		el
	);

	return (
		<div className={cx("pb-1", "text-sm")}>
			<span {...buttonProps} ref={el}>
				{label}
			</span>
		</div>
	);
};

const DismissButton = (props) => {
	const el = useRef<HTMLButtonElement | null>(null);
	const { buttonProps } = useButton(
		{
			...props,
			"aria-label": "dismiss toast",
		},
		el
	);
	return (
		<ForceTheme theme="dark">
			<Button ref={el} level="quaternary" {...buttonProps} icon={Close} label="Close" />
		</ForceTheme>
	);
};

export const Toast: React.FC<ToastComponentProps> = ({
	timer,
	message,
	action,
	variant,
	customIcon,
	ariaProps,
	onDismiss,
}) => {
	const className = cx(
		"gap-2",
		"flex",
		"max-w-md",
		"items-start",
		"rounded",
		"bg-inverse-primary",
		"pl-4",
		"pr-2",
		"py-2",
		"text-left",
		"text-md",
		"text-inverse-primary",
		{ "bg-negative": variant === "error" }
	);

	// boolean to show dismiss button
	const showDismissButton = ["dismissible", "timed-dismissible"].includes(timer);

	// find appropriate default icon for toast level
	const createIcon = (variant: string): React.ReactElement | null => {
		switch (variant) {
			case "success": {
				return <Checkmark className="fill-positive" />;
			}
			case "error": {
				return <Error />;
			}
			default: {
				return null;
			}
		}
	};

	const Icon = customIcon || createIcon(variant);

	return (
		<ForceTheme theme="light">
			<div className={className} {...ariaProps}>
				{Icon !== null && <div className={cx("py-2")}>{Icon}</div>}

				<div
					className={cx(
						"flex",
						"flex-col",
						"py-1",
						"space-y-1",
						"[& a]:color-inherit",
						"[& button]:color-inherit",
						"[& a]:underline",
						"[& button]:underline"
					)}
				>
					<div>{message}</div>
					{action && (
						<ActionButton
							label={action.label}
							callback={() => action.callback({ dismiss: onDismiss })}
						/>
					)}
				</div>

				{showDismissButton && <DismissButton onPress={() => onDismiss()} />}
			</div>
		</ForceTheme>
	);
};
