import { type Attrs, type DOMOutputSpec, type Node, type NodeSpec } from "prosemirror-model";

import { CELL_SPEC_ATTRS } from "../../prosemirror-utils/constants";
import { makeTextCellDefaultToDOMAttrs } from "../../prosemirror-utils/makeTextCellDefaultToDOMAttrs";

// :: NodeSpec A blockquote (`<blockquote>`) wrapping one or more blocks.

export const blockquote: NodeSpec = {
	content: "paragraph+",
	group: "block",
	defining: true,
	attrs: { ...CELL_SPEC_ATTRS },
	parseDOM: [{ tag: "blockquote" }],
	toDOM(node: Node) {
		const attrs: Attrs = makeTextCellDefaultToDOMAttrs(node);

		const blockquoteDOM: DOMOutputSpec = ["blockquote", attrs, 0];
		return blockquoteDOM;
	},
};
