export interface SEOConfig {
	baseTitle: string;
	description: string;
	themeColor: string;
	url?: string;
}

export const seoConfig: SEOConfig = {
	baseTitle: "Moment",
	description: "A modern docs platform, built on Markdown.",
	themeColor: `#04032C`,
};
