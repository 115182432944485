import { Logger } from "./logging";

const logger = new Logger("utils/printEnvironment");

export function printEnvironment() {
	// also log to datadog
	logger.debug("Starting application", {
		env: process.env.APP_ENV,
		releaseSHA: process.env.APP_SHA,
	});
}
