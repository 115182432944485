import type { NodePath } from "@babel/traverse";
import {
	type ArrowFunctionExpression,
	type FunctionExpression,
	type Identifier,
	type IfStatement,
	type JSXElement,
	type JSXIdentifier,
	type Node,
	type ReturnStatement,
	type TSType,
	type VariableDeclaration, // arrowFunctionExpression,
} from "@babel/types";
import { Node as ProseMirrorNode } from "prosemirror-model";

import { schema } from "@moment/api-collab/prosemirror-schema";

import { type Parsed } from "~/components/canvas/easel/runtime";
import { type Cell, type CellInfo, CellType } from "~/models/cell";
import {
	type FunctionBodyCell,
	FunctionBodyCellDispatcher,
	isFunctionBodyCell,
} from "~/utils/function-body-cell";

import { type ImportFromMomentNamespace, type ImportFromPage } from "../../import";
import { isTaggedTemplateExpression } from "../ast";
import { AstPattern } from "../ast-pattern";
import { type ImportDeclaration, isCell } from "../index";
import { type JsxArgumentInfo } from "./JsxArgumentInfo";
import { type JsxParam, type JsxParameterInfo, type MomentFieldMetadata } from "./JsxParameterInfo";
import { type NodeLocation, toNodeLocation } from "./NodeLocation";
import { matchGetServiceDetailFromUrlRouteCallPattern } from "./getServiceDetailFromUrlRoutePattern";
import {
	type GetServiceDetailReturnArgMatches,
	matchGetServiceDetailCallPattern,
} from "./getServiceDetailPattern";
import {
	type KubernetesAtlasProxyFetchClusterMatches,
	matchKubernetesAtlasProxyFetchPattern,
} from "./kubernetesAtlasProxyFetchPattern";
import { renderCallExpressionPattern } from "./patterns";
import {
	momentFieldMetadataFromTypeLiteral,
	transformPropSignatureTypeAnnotation,
	transformUnionTypeAnnotation,
} from "./transform";

const types = import("@babel/types");

/**
 * Parses the contents of a cell, producing static analysis metadata useful to various parts of the
 * Moment application.
 */
export const analyze = async (cell: Cell): Promise<SourceAnalysis | undefined> => {
	if (cell.type === "ProseMirrorJSONCell") {
		return {
			inspectInfo: undefined,
			parsedMarkdown: undefined,
			editorInfo: { kind: "RichTextCell", empty: await isEmptyProsemirrorCell(cell) },
			serviceDetails: {},
			fetchServiceDetailsFromUrlRoute: false,
			kubernetesAtlasProxyFetch: undefined,
		};
	} else if (cell.type === "JavaScriptFunctionBodyCell") {
		const dispatcher = new FunctionBodyCellDispatcher<SourceAnalysis | undefined>({
			onPageImport: (parsed, declaration, argument) => ({
				inspectInfo: {
					kind: "PageImportInfo",
					specifiers: importSpecifiers(declaration),
					argument,
				},
				parsedMarkdown: undefined,
				editorInfo: undefined,
				serviceDetails: {},
				fetchServiceDetailsFromUrlRoute: false,
				kubernetesAtlasProxyFetch: undefined,
			}),
			onMomentNamespaceImport: (parsed, declaration, argument) => ({
				inspectInfo: {
					kind: "NamespacedCanvasImportInfo",
					specifiers: importSpecifiers(declaration),
					argument,
				},
				parsedMarkdown: undefined,
				editorInfo: undefined,
				serviceDetails: {},
				fetchServiceDetailsFromUrlRoute: false,
				kubernetesAtlasProxyFetch: undefined,
			}),
			onMomentIdImport: () => undefined,
			onLocalImport: () => undefined,
			onStaticError: () => {
				return undefined;
			},
			onEmptyCell: () => undefined,
			onFunctionBodyCell: async (parsed: FunctionBodyCell) => {
				const serviceDetails = await matchGetServiceDetailCallPattern(parsed);
				const fetchServiceDetailsFromUrlRoute =
					(await matchGetServiceDetailFromUrlRouteCallPattern(parsed)).length > 0;
				const parsedMarkdown = await tryGetMarkdownContents(parsed);

				const kubernetesAtlasProxyFetch =
					await matchKubernetesAtlasProxyFetchPattern(parsed);

				const callExpr = await tryAnalyzeRenderCallExpression(
					cell,
					parsed,
					serviceDetails,
					fetchServiceDetailsFromUrlRoute,
					kubernetesAtlasProxyFetch
				);
				if (callExpr) {
					return { ...callExpr, parsedMarkdown };
				}

				const fnComponentDefn = tryAnalyzeFunctionComponentDefinition(
					parsed,
					serviceDetails,
					fetchServiceDetailsFromUrlRoute,
					kubernetesAtlasProxyFetch
				);
				if (fnComponentDefn) {
					return { ...fnComponentDefn, parsedMarkdown };
				}

				const inspectHints = await tryFindMomentInspectHints(parsed);
				if (inspectHints) {
					return {
						inspectInfo: { kind: "CodeInfo", inspectHints },
						parsedMarkdown,
						editorInfo: undefined,
						serviceDetails,
						fetchServiceDetailsFromUrlRoute,
						kubernetesAtlasProxyFetch,
					};
				}

				return Object.assign(
					{
						inspectInfo: undefined,
						editorInfo: undefined,
						serviceDetails,
						fetchServiceDetailsFromUrlRoute,
						kubernetesAtlasProxyFetch,
					},
					{ parsedMarkdown }
				);
			},
		});

		if (!cell.cellName) {
			return undefined;
		}

		return await dispatcher.dispatch(cell.cellName, cell.code);
	}

	return;
};

export interface SourceAnalysis {
	inspectInfo: InspectInfo | undefined;
	parsedMarkdown: string | undefined;
	editorInfo: { kind: "ViewCell" } | { kind: "RichTextCell"; empty: boolean } | undefined;
	serviceDetails: GetServiceDetailReturnArgMatches;
	fetchServiceDetailsFromUrlRoute: boolean;
	refsInRenderCall?: Map<string, Identifier | JSXIdentifier>;
	kubernetesAtlasProxyFetch: KubernetesAtlasProxyFetchClusterMatches | undefined;
}

export const importSpecifiers = (declaration: ImportDeclaration) =>
	declaration.specifiers.reduce(
		(acc, spec) => {
			const localIdentifier = spec.local.name;
			acc[localIdentifier] = { localIdentifier, imported: spec.imported.name };
			return acc;
		},
		{} as { [localIdentifier: string]: SpecifierInfo }
	);

/**
 * Static analysis metadata types
 */
export type InspectInfo =
	| FunctionComponentDefinition
	| RenderCallExpressionInfo
	| ImportInfo
	| CodeInfo;

/**
 * Static analysis metadata retrieved from a function body cell that contains a React function
 * component. In particular, this captures the React props, e.g., the `greeting` in the following
 * code:
 *
 *     return ({ greeting }) => <div>{greeting}</div>;
 */
export interface FunctionComponentDefinition {
	kind: "FunctionComponentDefinition";
	params: { [key: string]: JsxParameterInfo };
}

/**
 * Static analysis metadata retrieved from a function body cell that contains a call to
 * `React.render`. In particular, this captures the React props passed to the top-level React
 * component passed to `render`, e.g., `className` in the following code.
 *
 *     return render(() => <div className="bg-primary">hi</div>);
 */
export interface RenderCallExpressionInfo {
	kind: "RenderCallExpressionInfo";
	rootJsxTag: RootJsxTagInfo;

	/**
	 * functionArgumentInRenderCall takes the first argument in the render() function
	 * e.g.
	 * `render(() => (<div /))`
	 * will yield
	 * functionArgumentInRenderCall = `() => (<div />)`
	 */
	functionArgumentInRenderCall: ArrowFunctionExpression | FunctionExpression;

	arguments: { [key: string]: JsxArgumentInfo };
	gridWidth?: number;
	gridHeight?: number;
	refs: string[];
}

export interface RootJsxTagInfo extends NodeLocation {
	name: string;
	type: JSXIdentifier["type"];
}

export type ImportInfo = PageImportInfo | NamespacedCanvasImportInfo;

export const isImportInfo = (o?: InspectInfo): o is ImportInfo => {
	return (
		o !== undefined && (o.kind === "PageImportInfo" || o.kind === "NamespacedCanvasImportInfo")
	);
};

export interface CodeInfo {
	kind: "CodeInfo";
	inspectHints: InspectHint[];
}

/**
 * Static analysis metadata retrieved from a function body cell that contains an import of a
 * namespaced moment canvas. This routine also captures the locations of the import variables, e.g.,
 * `foo` and `bar` below:
 *
 *     import { foo as bar } from "@moment.dev/foo"
 */
export interface NamespacedCanvasImportInfo {
	kind: "NamespacedCanvasImportInfo";
	specifiers: { [localIdentifier: string]: SpecifierInfo };
	argument: ImportFromMomentNamespace;
}

export interface PageImportInfo {
	kind: "PageImportInfo";
	specifiers: { [localIdentifier: string]: SpecifierInfo };
	argument: ImportFromPage;
}

export interface SpecifierInfo {
	localIdentifier: string;
	imported: string;
}

export const gridWidthParamName = "_momentGridWidth";
export const gridHeightParamName = "_momentGridHeight";

const getRefsInRenderCall = (
	capturedRenderCall: Node,
	referencePaths: Map<string, NodePath<Identifier | JSXIdentifier>>
) => {
	// figure out which refs are embedded in the render call
	// e.g. a cell like:
	//
	// ```
	//		cell0;
	// 		render(() => <div>{cell1}</div>)
	// ```
	//
	// should mean
	// 		refsInRenderCall = [cell1]
	// 		references = [cell0, cell1]
	//
	// n.b. this will not account for react components that are exported from cells. e.g. <cell1></cell1>
	// n.b. this will also erroneously capture anything within the render call

	const refsInRenderCall = new Map<string, Identifier | JSXIdentifier>();
	for (const [nodeName, path] of referencePaths) {
		let currentPath: NodePath = path;
		if (path.node.name === "React" || path.node.type === "JSXIdentifier") {
			// Make sure we don't accidentally remove React from the refs.
			// React components get compiled to `React.createElement()`, so if
			// we're missing the React reference, they won't run.
			//
			// also don't include JSXIdentifiers otherwise we'll get stuff like
			// 	const InputTextAlpha = useCellValue("InputTextAlpha")
			// which isn't what we want right now
			continue;
		}
		while (currentPath.parentPath !== null) {
			currentPath = currentPath.parentPath;
			// check if the reference is within the render call
			if (currentPath.node === capturedRenderCall) {
				refsInRenderCall.set(nodeName, path.node);
			}
		}
	}
	return refsInRenderCall;
};

const createCodeLinesWithUseCellValue = async (
	refsInRenderCall: Map<string, Identifier | JSXIdentifier>
) => {
	const {
		variableDeclaration,
		variableDeclarator,
		identifier,
		callExpression,
		stringLiteral,
		ifStatement,
		logicalExpression,
		binaryExpression,
		blockStatement,
		returnStatement,
	} = await types;

	const allUseCellValueAsts: VariableDeclaration[] = [];
	const allFalsyChecks: IfStatement[] = [];
	[...refsInRenderCall.values()].forEach(({ name }) => {
		// this generates the ast for:
		// 		const cell0 = useCellValue("cell0")
		const useCellValueAst = variableDeclaration("const", [
			variableDeclarator(
				identifier(name),
				callExpression(identifier("useCellValue"), [stringLiteral(name)])
			),
		]);

		// this generates the ast for:
		//		if (cell0 === undefined || cell0 === null) {
		//			return "Loading";
		// 		}
		const falsyChecks = ifStatement(
			logicalExpression(
				"||",
				binaryExpression("===", identifier(name), identifier("undefined")),
				binaryExpression("===", identifier(name), identifier("null"))
			),
			blockStatement([returnStatement(stringLiteral("Loading"))])
		);
		allUseCellValueAsts.push(useCellValueAst);
		allFalsyChecks.push(falsyChecks);
	});
	// all hooks must be called at the top level of the function
	// so we need to return the all useCellValue lines first and then
	// the early return lines
	return [...allUseCellValueAsts, ...allFalsyChecks];
};

const getRefsInRenderCallAndInjectUseCellValue = async (
	capturedRenderCall: Node,
	referencePaths: Map<string, NodePath<Identifier | JSXIdentifier>>
) => {
	const { blockStatement, returnStatement } = await types;

	const refsInRenderCall = getRefsInRenderCall(capturedRenderCall, referencePaths);

	if (
		capturedRenderCall &&
		(capturedRenderCall.type === "ArrowFunctionExpression" ||
			capturedRenderCall.type === "FunctionExpression")
	) {
		if (capturedRenderCall.body.type !== "BlockStatement") {
			// turn this into a block statement
			capturedRenderCall.body = blockStatement([returnStatement(capturedRenderCall.body)]);
		}

		// create the useCellValue line(s)
		const codeLinesWithUseCellValue = await createCodeLinesWithUseCellValue(refsInRenderCall);

		// insert the new code lines into the block statement
		capturedRenderCall.body.body.unshift(...codeLinesWithUseCellValue);

		const returnStmt = capturedRenderCall.body.body[
			capturedRenderCall.body.body.length - 1
		] as ReturnStatement;
		const jsxElement = returnStmt.argument as JSXElement;
		const withThemeProvider = await wrapJsxInThemeProvider(jsxElement);
		const withBoundary = await wrapJsxInErrorBoundary(withThemeProvider);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(capturedRenderCall.body.body[capturedRenderCall.body.body.length - 1] as any).argument =
			withBoundary;
	}
	return refsInRenderCall;
};

const wrapJsxInErrorBoundary = async (element: JSXElement) => {
	const {
		arrowFunctionExpression,
		identifier,
		jsxAttribute,
		jsxElement,
		jsxExpressionContainer,
		jsxIdentifier,
		jsxMemberExpression,
		jsxOpeningElement,
		memberExpression,
	} = await types;

	//
	// Given a JSX element like this:
	//
	//     <div>Whatever</div>
	//
	// This function wraps it in an error boundary, like this:
	//
	//     <ReactErrorBoundary.ErrorBoundary
	//         FallbackComponent={(error) =>
	//             <designSystem.ValueErrorAlpha message={error.error.message} />
	//         }
	//     >
	//         <div>Whatever</div>
	//     </ReactErrorBoundary.ErrorBoundary>
	//

	return jsxElement(
		jsxOpeningElement(
			// The `.` expression in the JSX tag `<ReactErrorBoundary.ErrorBoundary>`
			jsxMemberExpression(
				jsxIdentifier("ReactErrorBoundary"),
				jsxIdentifier("ErrorBoundary")
			),
			[
				// FallbackComponent={(error) =>
				//     <designSystem.ValueErrorAlpha message={error.error.message} />
				// }
				jsxAttribute(
					jsxIdentifier("FallbackComponent"),
					// {(error) => <designSystem.ValueErrorAlpha message={error.error.message} />}
					jsxExpressionContainer(
						// (error) => <designSystem.ValueErrorAlpha message={error.error.message} />
						arrowFunctionExpression(
							// (error)
							[identifier("error")],
							// <designSystem.ValueErrorAlpha message={error.error.message} />
							jsxElement(
								jsxOpeningElement(
									// designSystem.ValueErrorAlpha
									jsxMemberExpression(
										jsxIdentifier("designSystem"),
										jsxIdentifier("ValueErrorAlpha")
									),
									[
										// message={error.error.message}
										jsxAttribute(
											// message
											jsxIdentifier("message"),
											// {error.error.message}
											jsxExpressionContainer(
												memberExpression(
													memberExpression(
														identifier("error"),
														identifier("error")
													),
													identifier("message")
												)
											)
										),
									]
								),
								null,
								[],
								true
							)
						)
					)
				),
			]
		),
		null,
		[element],
		true
	);
};

const wrapJsxInThemeProvider = async (element: JSXElement) => {
	const {
		booleanLiteral,
		jsxAttribute,
		jsxElement,
		jsxExpressionContainer,
		jsxIdentifier,
		jsxMemberExpression,
		jsxOpeningElement,
	} = await types;

	//
	// Given a JSX element like this:
	//
	//     <div>Whatever</div>
	//
	// This function wraps it in an error boundary, like this:
	//
	//     <DesignSystemThemeProvider.ThemeProvider isEnabled={true}>
	//         <div>Whatever</div>
	//     </DesignSystemThemeProvider.ThemeProvider>
	//

	return jsxElement(
		jsxOpeningElement(
			// The ThemeProvider identifier in the JSX tag `<DesignSystemThemeProvider.ThemeProvider>`
			jsxMemberExpression(
				jsxIdentifier("DesignSystemThemeProvider"),
				jsxIdentifier("ThemeProvider")
			),
			[
				// noInsertScript={true}
				jsxAttribute(
					jsxIdentifier("noInsertScript"),
					jsxExpressionContainer(booleanLiteral(true))
				),
			]
		),
		null,
		[element],
		true
	);
};

/**
 * Returns a `RenderCallExpressionInfo` if a cell's AST is of the following form:
 *
 *     return render(() => <div>hi</div>);
 */
export const tryAnalyzeRenderCallExpression = async (
	cell: Cell,
	parsed: Parsed,
	serviceDetails: GetServiceDetailReturnArgMatches,
	fetchServiceDetailsFromUrlRoute: boolean,
	kubernetesAtlasProxyFetch: KubernetesAtlasProxyFetchClusterMatches
): Promise<SourceAnalysis | undefined> => {
	if (!isFunctionBodyCell(parsed)) {
		return;
	}

	const matches = (await AstPattern.match(renderCallExpressionPattern, parsed.body)).map(
		(match) => ({
			...(match as Record<string, unknown>),
			refs: Array.from(new Set((parsed.references || []).map((ref) => ref.name))),
		})
	);

	if (matches.length > 1) {
		console.warn("got more than 1 match for a render call expression at root");
		return;
	}

	if (matches.length === 0) {
		return;
	}

	const match = matches[0] as RenderCallExpressionInfo;

	// Legacy grid stuff.
	const gridWidthAttribute = match.arguments[gridWidthParamName]?.value;
	match.gridWidth =
		gridWidthAttribute?.start && gridWidthAttribute.end
			? parseInt(cell.code.slice(gridWidthAttribute.start, gridWidthAttribute.end))
			: undefined;

	const gridHeightAttribute = match.arguments[gridHeightParamName]?.value;
	match.gridHeight =
		gridHeightAttribute?.start && gridHeightAttribute.end
			? parseInt(cell.code.slice(gridHeightAttribute.start, gridHeightAttribute.end))
			: undefined;

	const capturedRenderCall: Node = (matches[0] as RenderCallExpressionInfo)
		.functionArgumentInRenderCall;
	const refsInRenderCall = capturedRenderCall
		? await getRefsInRenderCallAndInjectUseCellValue(capturedRenderCall, parsed.referencePaths)
		: undefined;

	return {
		inspectInfo: match,
		parsedMarkdown: undefined,
		editorInfo: undefined,
		serviceDetails,
		fetchServiceDetailsFromUrlRoute,
		kubernetesAtlasProxyFetch,
		refsInRenderCall,
	};
};

/**
 * Returns a `FunctionComponentDefinition` if a cell's AST represents a React function component, e.g.:
 *
 *     return ({ greeting }) => <div>{greeting}</div>;
 */
const tryAnalyzeFunctionComponentDefinition = (
	parsed: Parsed,
	serviceDetails: GetServiceDetailReturnArgMatches,
	fetchServiceDetailsFromUrlRoute: boolean,
	kubernetesAtlasProxyFetch: KubernetesAtlasProxyFetchClusterMatches
): SourceAnalysis | undefined => {
	if (!isFunctionBodyCell(parsed) || parsed.body.body.length === 0) {
		return;
	}

	// Check last statement is a `return`.
	const body = parsed.body.body;
	const lastStatement = body[body.length - 1];
	if (lastStatement?.type !== "ReturnStatement") {
		// TODO: Make sure there are no other return statements in the cell.
		return;
	}

	// Check that we're `return`'ing a React function component.
	const returnArg = lastStatement.argument;
	if (returnArg?.type !== "ArrowFunctionExpression" && returnArg?.type !== "FunctionExpression") {
		return;
	}

	// React function components take a single argument, which is an object. Verify this.
	const param = returnArg.params[0];
	if (param === undefined || returnArg.params.length !== 1 || param.type !== "ObjectPattern") {
		// TODO: Add support for `ArrayPattern`, `AssignmentPattern`, `Identifier`, `RestElement`
		return;
	}

	const parameters = param.properties.reduce<{
		[key: string]: JsxParameterInfo;
	}>((acc, prop) => {
		// TODO: Add support for `RestElement`.
		if (prop.type !== "ObjectProperty" || prop.computed || prop.key.type !== "Identifier") {
			return acc;
		}

		acc[prop.key.name] = {
			name: toNodeLocation(prop.key),
		};

		return acc;
	}, {});

	// Get types for all parameters.
	const typeAnnotation = param.typeAnnotation;
	if (
		typeAnnotation?.type === "TSTypeAnnotation" &&
		typeAnnotation.typeAnnotation.type === "TSTypeLiteral"
	) {
		for (const member of typeAnnotation.typeAnnotation.members) {
			if (member.type !== "TSPropertySignature" || member.key.type !== "Identifier") {
				continue;
			}
			const name = member.key.name;

			if (member.typeAnnotation?.type !== "TSTypeAnnotation") {
				continue;
			}

			const namedParameters = parameters[name];
			if (!namedParameters) {
				continue;
			}

			let paramType = member.typeAnnotation.typeAnnotation;
			let paramMeta: MomentFieldMetadata = {};
			if (
				paramType.type === "TSTypeReference" &&
				paramType.typeName.type === "Identifier" &&
				paramType.typeName.name === "MomentField" &&
				paramType.typeParameters?.type === "TSTypeParameterInstantiation" &&
				paramType.typeParameters.params.length === 2
			) {
				//
				// A "moment field", e.g., `MomentField<string, { section: "Basic" }>`.
				//

				const meta = paramType.typeParameters.params[1];

				if (meta && meta.type === "TSTypeLiteral") {
					paramMeta = momentFieldMetadataFromTypeLiteral(meta);
				}

				const p = paramType.typeParameters.params[0];
				if (!p) {
					console.warn("found an invalid param");
					continue;
				}
				paramType = p;
			}

			if (paramType.type === "TSUnionType") {
				//
				// CASE: Union of strings, e.g., `"s1" | "s2" | "s3"`.
				//

				namedParameters.typeAnnotation = transformUnionTypeAnnotation(paramType, paramMeta);
			} else if (paramType.type === "TSArrayType") {
				//
				// CASE: Array of "simple" objects, e.g., `{ field1: "field1" }[]`. Does NOT cover
				// the case of nested objects.
				//

				namedParameters.typeAnnotation = transformPropSignatureTypeAnnotation(
					paramType,
					paramMeta
				);
			} else {
				namedParameters.typeAnnotation = { type: paramType.type, metadata: paramMeta };
			}
		}
	}

	return {
		inspectInfo: {
			kind: "FunctionComponentDefinition",
			params: parameters,
		},
		parsedMarkdown: undefined,
		editorInfo: undefined,
		serviceDetails,
		fetchServiceDetailsFromUrlRoute,
		kubernetesAtlasProxyFetch,
	};
};

export interface InspectHint {
	start: number;
	end: number;
	fieldType: JsxParam;
	metadata: MomentFieldMetadata;
}

const tryFindMomentInspectHints = async (
	parsed: FunctionBodyCell
): Promise<InspectHint[] | undefined> => {
	const pattern = AstPattern.anywhere({
		type: "TSAsExpression",

		expression: {
			start: (start: number | null | undefined) => ({ start }),
			end: (end: number | null | undefined) => ({ end }),
		},

		typeAnnotation: {
			type: "TSTypeReference",
			typeName: {
				type: "Identifier",
				name: "MomentInspectHint",
			},
			typeParameters: {
				type: "TSTypeParameterInstantiation",
				// TODO: Fix fiddly TS types in `Pattern`
				params: (typeParameters: unknown[]) => {
					if (!typeParameters || typeParameters.length === 0) {
						return { fieldType: { type: "TSAnyKeyword" }, metadata: {} };
					}

					const typed = typeParameters as TSType[];
					const [fieldType, fieldMeta] = typed;
					if (!fieldMeta || fieldMeta.type !== "TSTypeLiteral") {
						return { fieldType, metadata: {} };
					}

					return { fieldType, metadata: momentFieldMetadataFromTypeLiteral(fieldMeta) };
				},
			},
		},
	});

	return (await AstPattern.match(pattern, parsed.body)) as InspectHint[];
};

export const getTextContent = async (cell: Cell): Promise<string | undefined> => {
	switch (cell.type) {
		case "JavaScriptFunctionBodyCell": {
			const parsed = await parseCellContents(cell);
			return tryGetMarkdownContents(parsed);
		}
		case "ProseMirrorJSONCell": {
			const parsed = await parseCellContents(cell);
			return tryGetProsemirrorJSONText(parsed);
		}
		default:
			return undefined;
	}
};

export const textIsEmpty = async (cell: Cell): Promise<boolean> => {
	const textContent = await getTextContent(cell);
	return textContent === "";
};

async function tryGetMarkdownContents(parsed?: Parsed): Promise<string | undefined> {
	const { isReturnStatement } = await types;

	if (isFunctionBodyCell(parsed)) {
		const body = parsed.body.body;
		if (body.length !== 1) {
			return undefined;
		}

		const stmt = body[0];
		return isReturnStatement(stmt) &&
			isTaggedTemplateExpression(stmt.argument) &&
			stmt.argument.tag.name === "md" &&
			stmt.argument.quasi.quasis.length === 1 &&
			stmt.argument.quasi.expressions.length === 0
			? stmt.argument.quasi.quasis[0]?.value.cooked
			: undefined;
	} else if (isCell(parsed)) {
		const body = parsed.body;
		return isTaggedTemplateExpression(body) &&
			body.tag.name === "md" &&
			body.quasi.quasis.length === 1 &&
			body.quasi.expressions.length === 0
			? body.quasi.quasis[0]?.value.cooked
			: undefined;
	}
	return undefined;
}

function tryGetProsemirrorJSONText(parsed: Parsed): string | undefined {
	if (parsed?.type !== "json") {
		return undefined;
	}

	try {
		const prosemirrorNode = ProseMirrorNode.fromJSON(schema, parsed.json);
		return prosemirrorNode.textContent;
	} catch (e) {
		return "";
	}
}

export async function isInitialEmptyCell(cellInfo: CellInfo): Promise<boolean> {
	if (cellInfo.analysis?.parsedMarkdown === "") {
		return true;
	}

	return await isEmptyProsemirrorCell(cellInfo.cell);
}

export const isEmptyRichTextCell = (cellInfo: CellInfo) => {
	return (
		cellInfo.analysis?.editorInfo?.kind === "RichTextCell" && cellInfo.analysis.editorInfo.empty
	);
};

export const isEmptyProsemirrorNode = (node: ProseMirrorNode): boolean => {
	// Checks whether this is the initial Prosemirror json node of type:
	// {"type":"doc","content":[{"type":"paragraph"}]}
	return (
		node.childCount === 1 &&
		node.firstChild?.type.name === "paragraph" &&
		node.textContent === ""
	);
};

export const isEmptyProsemirrorCell = async (cell: Cell): Promise<boolean> => {
	if (cell.type !== "ProseMirrorJSONCell") {
		return false;
	}

	if (cell.code === "") {
		return true;
	}

	const parsed = await parseCellContents(cell);
	if (parsed?.type !== "json") {
		return false;
	}
	try {
		const prosemirrorNode = ProseMirrorNode.fromJSON(schema, parsed.json);
		return isEmptyProsemirrorNode(prosemirrorNode);
	} catch (e) {
		// invalid prosemirror json.
		return false;
	}
};

export const parseCellContents = async (cell: Cell): Promise<Parsed> => {
	switch (cell.type) {
		case CellType.JavaScriptFunctionBodyCell: {
			const dispatcher = new FunctionBodyCellDispatcher<Parsed>({
				onPageImport: (): Parsed => undefined,
				onMomentNamespaceImport: (): Parsed => undefined,
				onMomentIdImport: (): Parsed => undefined,
				onLocalImport: (): Parsed => undefined,
				onEmptyCell: (parsed) => parsed,
				onFunctionBodyCell: (parsed: FunctionBodyCell) => parsed,
				onStaticError: () => {
					return undefined;
				},
			});

			if (!cell.cellName) {
				return undefined;
			}

			return await dispatcher.dispatch(cell.cellName, cell.code);
		}

		case CellType.ProseMirrorJSONCell: {
			if (cell.code === "") {
				return {
					type: "json",
					json: {},
				};
			}

			try {
				return {
					type: "json",
					json: JSON.parse(cell.code),
				};
			} catch {
				console.warn("parseCellContents: Failed to parse cell json");
				return undefined;
			}
		}
	}
};
