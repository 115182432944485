import { type NodeSpec } from "prosemirror-model";

import { CELL_SPEC_ATTRS } from "../../prosemirror-utils/constants";
import { makeTextCellDefaultToDOMAttrs } from "../../prosemirror-utils/makeTextCellDefaultToDOMAttrs";

export type ToDoItemAttrs = {
	status: "completed" | "to do";
	indent?: number;
};

export const to_do_item: NodeSpec = {
	defining: true,
	group: "block",
	content: "paragraph",
	attrs: {
		...CELL_SPEC_ATTRS,
		status: { default: "to do" },
		indent: { default: 0 },
	},
	parseDOM: [
		{
			priority: 51, // Needs higher priority than other nodes that use a "li" tag
			tag: "div[data-node-type='to_do_item']",
			preserveWhitespace: "full",
			getAttrs(node: string | HTMLElement) {
				if (typeof node === "string") {
					return false;
				}

				const status = node.getAttribute("data-status") === "to do" ? "to do" : "completed";
				const indent = Number(node.getAttribute("data-indent") || 0);

				const attrs: ToDoItemAttrs = { status, indent };

				return attrs;
			},
		},
	],
	toDOM(node) {
		const attrs = makeTextCellDefaultToDOMAttrs(node);

		return [
			"div",
			{
				...attrs,
				"data-node-type": "to_do_item",
				"data-status": node.attrs["status"],
				"data-indent": node.attrs["indent"],
			},
			["button", { contenteditable: "false" }],
			["div", 0],
		];
	},
};
