import { z } from "zod";

import { pageMetadata } from "./pageMetadata";

/**
 * A direct mirror of the GraphQL `CanvasMetadata` type
 */
export const canvasMetadata = z.object({
	id: z.string(),
	type: z.string(),

	title: z.string(),
	sharedName: z.optional(
		z
			.object({
				sharedName: z.string(),
				organizationID: z.string(),
				namespace: z.string(),
			})
			.nullable()
	),
	owner: z.string(),
	organizationID: z.string(),
	created: z.string(),
	updated: z.string(),
	updatedBy: z.string(),

	archived: z.boolean(),
	isTemplate: z.boolean(),
	pinnedAt: z.string(),
	archivedAt: z.string(),

	pagesMetadata: z.array(pageMetadata),

	atlasUserToken: z.union([z.undefined(), z.null(), z.string()]),
	latestVersion: z.number(),
});

export const messageIdempotentInitMetadataRoomResponseAccepted = z.object({
	type: z.literal("idempotent-init-metadata-room-response-accepted"),
	version: z.number(),
	canvasMetadata,
	page: z.unknown(),
});

export type MessageInitMetadataRoomResponseAccepted = z.infer<
	typeof messageIdempotentInitMetadataRoomResponseAccepted
>;

export const messageIdempotentInitMetadataRoomResponseRejected = z.object({
	type: z.literal("idempotent-init-metadata-room-response-rejected"),
	version: z.number(),
});

export type MessageInitMetadataRoomResponseRejected = z.infer<
	typeof messageIdempotentInitMetadataRoomResponseRejected
>;

/**
 * A direct mirror of the GraphQL `CanvasMetadata` type.
 */
export type CanvasMetadata = z.infer<typeof canvasMetadata>;

export type CanvasPageMetadata = CanvasMetadata["pagesMetadata"][number];
