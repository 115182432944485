import { type Node, type ResolvedPos } from "prosemirror-model";

import { findAncestorCell } from "./findAncestorCell";

export const getTargetNode = ($cursor: ResolvedPos): Node | undefined => {
	const ancestorCell = findAncestorCell($cursor);

	if (ancestorCell !== undefined) {
		return ancestorCell.node;
	}

	if ($cursor.nodeBefore) {
		return $cursor.nodeBefore;
	}

	return undefined;
};
