import { Schema } from "prosemirror-model";

import { marks } from "./marks";
import { nodes } from "./nodes";

export { DEFAULT_INDENT } from "./nodes/list_item";

// The schema is the same as prosemirror's Basic Schema, with the exception that the image node is removed.
// Basic Schema: https://prosemirror.net/docs/ref/#schema-basic
export const schema = new Schema({
	nodes,
	marks,
});
