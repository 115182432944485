import { Logger } from "../logging";

const logger = new Logger("utils/helpers/json");

export const extractJSON = (value: string, fallback?: string): string => {
	try {
		return JSON.parse(value);
	} catch (error) {
		logger.error("Failed to parse JSON", { value, error });
		return fallback || "";
	}
};

export const encodeJSON = (value: unknown, fallback?: string): string => {
	try {
		return JSON.stringify(value);
	} catch (error) {
		logger.error("Failed to encode JSON", { value, error });
		return fallback || "";
	}
};
