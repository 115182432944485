import { InputRule } from "prosemirror-inputrules";
import { type NodeType } from "prosemirror-model";
import { type EditorState } from "prosemirror-state";

import { safeInsert } from "../helpers/safeInsert";

// Given a horizontal rule  markType returns an input rule that turns `"***" in a text block
// into an <hr> tag.
export const horizontalRuleRule = (nodeType: NodeType): InputRule => {
	const matchHandler = function (
		state: EditorState,
		_match: string[],
		start: number,
		end: number
	) {
		// look for the paragraph we're in
		const para = state.doc.resolve(start);

		// If there's more text in the paragraph (aka we are not on a new line)
		// bail out.
		if (para.end() !== end) {
			return null;
		}

		// replace the *** with an hr tag
		const tr = state.tr.replaceWith(start - 1, end, nodeType.createChecked());

		// if the paragraph is the last child of its parent, then insert a
		// new paragraph
		if (para.after() == para.end(-1)) {
			const node = state.schema.nodes["paragraph"]?.createChecked();

			if (!node) {
				return;
			}

			return safeInsert(node, tr.mapping.map(para.after()))(tr);
		}

		// if the next node is an hr, then insert a new paragraph
		const nextNode = state.doc.resolve(para.after()).nodeAfter;

		if (!nextNode) {
			return tr;
		}
		if (nextNode.type === nodeType) {
			const node = state.schema.nodes["paragraph"]?.createChecked();

			if (!node) {
				return;
			}

			return safeInsert(node, tr.mapping.map(para.after()))(tr);
		}

		return tr;
	};

	// hr rules should be triggered by ***, ---, ___,  —- (em dash and hyphen)
	const rule = new RegExp(/^(\*\*\*|---|___|—-)$/);
	return new InputRule(rule, matchHandler);
};
