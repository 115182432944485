import NextHead from "next/head";

export type Opengraphprops = {
	url: string;
	type: string;
	title: string;
	description: string;
	site_name: string;
	locale: string;
	image_url: HttpString | HttpsString;
};

export const Opengraph = ({ url, type, title, description, site_name, locale, image_url }) => {
	return (
		<NextHead>
			<meta property="og:url" key="og:url" content={url} />
			<meta property="og:type" key="og:type" content={type} />
			<meta property="og:title" key="og:title" content={title} />
			<meta property="og:description" key="og:description" content={description} />
			<meta property="og:site_name" key="og:site_name" content={site_name} />
			<meta property="og:locale" key="og:locale" content={locale} />
			<meta property="og:image" key="og:image" content={image_url} />
			<meta property="og:image:secure_url" key="og:image:secure_url" content={image_url} />
		</NextHead>
	);
};
