import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { isDesktopAppMode } from "~/auth/interop/common";
import { Logger } from "~/utils/logging";

const logger = new Logger("desktopAppAPIs");

export const desktopAppAPIs = createApi({
	reducerPath: "desktopAppConfig",
	baseQuery: fetchBaseQuery(),
	endpoints: (build) => ({
		fetchConfig: build.query({
			queryFn: async () => {
				if (!isDesktopAppMode()) {
					logger.warn("Fetching desktop app config in non-desktop app mode");
					return {
						data: {
							config: null,
						},
					};
				}

				const config = await window.desktopIpcApi?.fetchDesktopConfig();
				if (!config) {
					throw new Error("Failed to fetch desktop config");
				}

				return {
					data: {
						config,
					},
				};
			},
		}),
	}),
});

export const { useFetchConfigQuery } = desktopAppAPIs;
