import { type MutableRefObject, createContext } from "react";

import { type CanvasMetadata } from "@moment/api-collab/api-types";

import { isDesktopAppMode } from "~/auth/interop/common";
import { notImplementedFn } from "~/utils/context";

import { type QueryBuilderState } from "./query-builder/QueryBuilderState";
import { type QueryBuilderStateManager } from "./query-builder/QueryBuilderStateManager";
import { type CanvasRuntimeManager } from "./runtime";

export type EaselContextType = {
	workspaceId: string;
	documentId: string;
	pageId: string;

	// :: Canvas ::
	canvasRef: MutableRefObject<CanvasMetadata | null>;
	canvasWritable: boolean;
	prosemirrorWritable: boolean;

	// :: Runtime ::
	runtime: CanvasRuntimeManager | null;

	// :: Query Builder ::
	queryBuilderState: QueryBuilderState | null;
	queryBuilderStateManager: QueryBuilderStateManager;
};

export const initialEaselState: EaselContextType = {
	workspaceId: "",
	documentId: "",
	pageId: "",

	// :: Canvas ::
	canvasRef: { current: null },
	canvasWritable: isDesktopAppMode(),
	prosemirrorWritable: isDesktopAppMode(),

	// :: Runtime ::
	runtime: null,

	// :: Query Builder ::
	queryBuilderState: null,
	queryBuilderStateManager: {
		useQueryBuilderState: notImplementedFn("useQueryBuilderState", "Easel", null),
		setQueryActive: notImplementedFn("setQueryActive", "Easel", undefined),
		setInitialQueryNodeEditing: notImplementedFn(
			"setInitialQueryNodeEditing",
			"Easel",
			undefined
		),
		setCurrQueryNodeEditing: notImplementedFn("setCurrQueryNodeEditing", "Easel", undefined),
		setToggleFieldSelected: notImplementedFn("setToggleFieldSelected", "Easel", undefined),
		setFieldSelected: notImplementedFn("setFieldSelected", "Easel", undefined),
		setAppendWherePredicate: notImplementedFn("setAppendWherePredicate", "Easel", undefined),
		setReplaceFieldInWherePredicate: notImplementedFn(
			"setReplaceFieldInWherePredicate",
			"Easel",
			undefined
		),
		setReplaceOperationInWherePredicate: notImplementedFn(
			"setReplaceOperationInWherePredicate",
			"Easel",
			undefined
		),
		setReplaceArgInWherePredicate: notImplementedFn(
			"setReplaceArgInWherePredicate",
			"Easel",
			undefined
		),
		setDeleteWherePredicate: notImplementedFn("setDeleteWherePredicate", "Easel", undefined),
		setAppendGroupedFields: notImplementedFn("setAppendGroupedFields", "Easel", undefined),
		setReplaceGroupedField: notImplementedFn("setReplaceGroupedField", "Easel", undefined),
		setDeleteGroupedField: notImplementedFn("setDeleteGroupedField", "Easel", undefined),
		setAppendOrderedFields: notImplementedFn("setAppendOrderedFields", "Easel", undefined),
		setReplaceOrderedField: notImplementedFn("setReplaceOrderedField", "Easel", undefined),
		setDeleteOrderedField: notImplementedFn("setDeleteOrderedField", "Easel", undefined),
		setQueryInactive: notImplementedFn("setQueryInactive", "Easel", undefined),
	},
};

export const EaselStateContext = createContext<EaselContextType>(initialEaselState);
