import { isDesktopAppMode } from "~/auth/interop/common";
import { trpc } from "~/utils/trpc";

export const useGitConfigQuery = (args: { workspaceId: string; documentId?: string }) => {
	const query = trpc.git.config.useQuery(args, {
		enabled: isDesktopAppMode(),
	});

	return query;
};
