import { z } from "zod";

import { generateShortUuid } from "../id";
import { workspaceSchema } from "./workspace";

export const appConfigSchema = z.object({
	userID: z.string().default(() => `anonymous_${generateShortUuid()}`),
	workspaces: z.array(workspaceSchema),
});

export type AppConfig = z.infer<typeof appConfigSchema>;
