import type { Visitor } from "@babel/traverse";
import type { BlockStatement, Node, Program, Statement } from "@babel/types";

import { traverseAsync } from "~/utils/deferred-babel";

import {
	type Capture,
	MatchAllElements,
	MatchAny,
	MatchAnyElements,
	MatchAnywhere,
	MatchAtRoot,
	MatchCaptureGroup,
	MatchLastElement,
	MatchOptional,
	MatchOr,
	Matchable,
	type NodePatternSpec,
	type PatternCapture,
} from "./types";

/**
 * NOTE:
 * You can use this tool to debug pattern matches
 * https://astexplorer.net/
 */

export const AstPattern = {
	any: <N>(capture?: PatternCapture<N>) => new MatchAny(capture),

	atRoot: <N extends Partial<Node>>(pattern: NodePatternSpec<N>) => new MatchAtRoot(pattern),

	anywhere: <N extends Partial<Node>>(pattern: NodePatternSpec<N>) => new MatchAnywhere(pattern),

	allElements: <N>(pattern: NodePatternSpec<N>) => new MatchAllElements(pattern),

	anyElements: <N>(pattern: NodePatternSpec<N>) => new MatchAnyElements(pattern),

	lastElement: <N>(pattern: NodePatternSpec<N>) => new MatchLastElement(pattern),

	optional: <N>(pattern: NodePatternSpec<N>) => new MatchOptional(pattern),

	captureGroup: <N>(pattern: NodePatternSpec<N>, capture: Capture) =>
		new MatchCaptureGroup(pattern, capture),

	or: (...pattern: NodePatternSpec<unknown>[]) => new MatchOr(pattern),

	match: async <N>(
		matchable: MatchAnywhere<N> | MatchAtRoot<N>,
		node: BlockStatement
	): Promise<unknown[]> => {
		if (matchable instanceof MatchAtRoot) {
			const matches: unknown[] = [];
			const match = matchPattern(matchable.pattern, node, {});
			if (match) {
				matches.push(match);
			}
			return matches;
		}

		const matches: unknown[] = [];
		const patternVisitor: Visitor = {
			enter: (path) => {
				const match = matchPattern(matchable.pattern, path.node, {});
				if (!match) {
					return;
				}
				matches.push(match);
			},
		};

		await traverseAsync(programNodeFromBody(node.body), patternVisitor);
		return matches;
	},
};

const programNodeFromBody = (body: Statement[]): Program => ({
	type: "Program",
	body,
	sourceType: "module",

	leadingComments: null,
	innerComments: null,
	trailingComments: null,
	start: null,
	end: null,
	loc: null,
	directives: [],
});

const matchPattern = <N extends Partial<Node>>(
	pattern: NodePatternSpec<N>,
	node: Node,
	acc: Record<string, unknown>
): Record<string, unknown> | undefined => {
	let allMatch = true;

	for (const patternProp in pattern) {
		// eslint-disable-next-line no-prototype-builtins
		if (!pattern.hasOwnProperty(patternProp)) {
			continue;
		}

		const desiredField = pattern[patternProp];
		// Casting to make the index usage work
		const empiricalField = (node as NodePatternSpec<N>)[patternProp];

		// TODO figure out why desiredField and Matchable are not related types
		if ((desiredField as unknown) instanceof Matchable) {
			const match = matchMatchable(desiredField, empiricalField, {});
			if (!match) {
				allMatch = false;
				break;
			}
			acc = { ...acc, ...match };
			continue;
		} else {
			const match = matchPatternField(desiredField, empiricalField, {});
			if (!match) {
				allMatch = false;
				break;
			}
			acc = { ...acc, ...match };
			continue;
		}
	}

	return allMatch ? acc : undefined;
};

export const matchPatternField = (
	desiredField: unknown,
	empiricalField: unknown,
	acc: Record<string, unknown>
): Record<string, unknown> | undefined => {
	if (typeof desiredField === "function") {
		const captured = desiredField(empiricalField, acc);
		acc = { ...acc, ...captured };
	} else if (typeof empiricalField !== "object") {
		if (desiredField !== empiricalField) {
			return undefined;
		} else {
			return acc;
		}
	}

	// `null` is actually an object. Ergo the special case.
	if (empiricalField === null && desiredField === null) {
		acc = { ...acc };
		return acc;
	}

	if (Array.isArray(desiredField) && !Array.isArray(empiricalField)) {
		return undefined;
	}

	const submatch = matchPattern(
		desiredField as NodePatternSpec<Partial<Node>>,
		empiricalField as Node,
		acc
	);
	if (!submatch) {
		return undefined;
	}
	acc = { ...acc, ...submatch };

	return acc;
};

export const matchMatchable = <_N>(
	pattern: Matchable,
	node: Node,
	acc: Record<string, unknown>
): Record<string, unknown> | undefined => {
	let desiredField = pattern;
	const empiricalField = node;

	if (desiredField instanceof MatchAny) {
		// We can match `any`, and also capture the value, e.g., `any((value) => ({ value }))`.
		// If there is no capture, just skip validation altogether.
		if (!desiredField.capture) {
			return acc;
		}
		desiredField = desiredField.capture;
	} else if (desiredField instanceof MatchOptional) {
		// Optional field not present. We consider this a match, continue on.
		if (empiricalField === undefined) {
			return acc;
		}

		if (desiredField.pattern instanceof Matchable) {
			return matchMatchable(desiredField.pattern, node, acc);
		}

		desiredField = desiredField.pattern;
	} else if (desiredField instanceof MatchCaptureGroup) {
		// If a built-in pattern operator like `any`, `or`, etc., "repackage" the current
		// property so that `matchPattern` recursively unpacks it correctly.
		const submatch =
			desiredField.pattern instanceof Matchable
				? matchMatchable(desiredField.pattern, empiricalField, {})
				: matchPattern(desiredField.pattern, empiricalField, {});

		if (submatch) {
			acc = { ...acc, ...desiredField.capture(submatch, acc, empiricalField) };
			return acc;
		}
		return undefined;
	} else if (desiredField instanceof MatchAllElements) {
		if (!Array.isArray(empiricalField)) {
			return undefined;
		}

		// If a built-in pattern operator like `any`, `or`, etc., "repackage" the current
		// property so that `matchPattern` recursively unpacks it correctly.
		const pattern = desiredField.pattern;
		const matches = empiricalField.flatMap((field) => {
			const submatch =
				pattern instanceof Matchable
					? matchMatchable(pattern, field, {})
					: matchPattern(pattern, field, {});

			return !submatch ? [] : [submatch];
		});

		if (matches.length !== empiricalField.length) {
			return undefined;
		}

		acc = { ...acc, ...matches };
		return acc;
	} else if (desiredField instanceof MatchAnyElements) {
		if (!Array.isArray(empiricalField)) {
			return undefined;
		}

		// If a built-in pattern operator like `any`, `or`, etc., "repackage" the current
		// property so that `matchPattern` recursively unpacks it correctly.
		const pattern = desiredField.pattern;
		const matches = empiricalField.flatMap((field) => {
			const submatch =
				pattern instanceof Matchable
					? matchMatchable(pattern, field, {})
					: matchPattern(pattern, field, {});

			return !submatch ? [] : [submatch];
		});

		acc = { ...acc, ...matches };
		return acc;
	} else if (desiredField instanceof MatchLastElement) {
		if (!Array.isArray(empiricalField) || empiricalField.length < 1) {
			return undefined;
		}

		// If a built-in pattern operator like `any`, `or`, etc., "repackage" the current
		// property so that `matchPattern` recursively unpacks it correctly.
		const pattern = desiredField.pattern;
		const last = empiricalField[empiricalField.length - 1];
		const submatch =
			pattern instanceof Matchable
				? matchMatchable(pattern, last, {})
				: matchPattern(pattern, last, {});

		if (!submatch) {
			return undefined;
		}
		acc = { ...acc, ...submatch };
		return acc;
	} else if (desiredField instanceof MatchOr) {
		const matches = desiredField.pattern.flatMap((pattern) => {
			// If a built-in pattern operator like `any`, `or`, etc., "repackage" the current
			// property so that `matchPattern` recursively unpacks it correctly.
			const submatch =
				pattern instanceof Matchable
					? matchMatchable(pattern, empiricalField, {})
					: matchPattern(pattern, empiricalField, {});
			return !submatch ? [] : [submatch];
		});

		if (matches.length === 0) {
			return undefined;
		}
		acc = { ...acc, ...matches[0] };
		return acc;
	}

	if (typeof desiredField === "function") {
		const captured = desiredField(empiricalField, acc);
		acc = { ...acc, ...captured };
	} else if (typeof empiricalField !== "object") {
		if (desiredField !== empiricalField) {
			return undefined;
		} else {
			return acc;
		}
	}

	// `null` is actually an object. Ergo the special case.
	if (empiricalField === null && desiredField === null) {
		acc = { ...acc };
		return acc;
	}

	if (Array.isArray(desiredField) && !Array.isArray(empiricalField)) {
		return undefined;
	}

	const submatch = matchPattern(desiredField, empiricalField, acc);
	if (!submatch) {
		return undefined;
	}
	acc = { ...acc, ...submatch };

	return acc;
};
