import { type Command } from "prosemirror-state";

import {
	type ConvertNodeCommandHelper,
	type ConvertNodeOptions,
	makeConvertNodeCommandHelper,
} from "./makeConvertNodeCommandHelper";

export function convertNodesInSelection(convertTo: ConvertNodeOptions): Command {
	return function (state, dispatch) {
		if (!dispatch) {
			return false;
		}

		let canConvert = true;
		const helpers: ConvertNodeCommandHelper[] = [];

		for (let i = 0; i < state.selection.ranges.length && canConvert; i++) {
			const selection = state.selection.ranges[i];
			if (!selection) {
				return false;
			}

			const {
				$from: { pos: from },
				$to: { pos: to },
			} = selection;
			state.doc.nodesBetween(from, to, (node, pos, parent) => {
				// Only convert block nodes that are direct children of the document.
				if (!node.isBlock || !node.firstChild || parent !== state.doc) {
					return false;
				}
				const helper = makeConvertNodeCommandHelper(node, pos, convertTo);
				if (helper.conversionType === "invalid") {
					canConvert = false;
				}
				helpers.push(helper);
			});

			if (!canConvert) {
				return false;
			}
		}

		const tr = state.tr;
		for (const helper of helpers) {
			if (helper.conversionType === "valid") {
				helper.addTransformToTransaction(tr);
			}
		}

		dispatch(tr.scrollIntoView());

		return true;
	};
}
