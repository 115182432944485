import { Plugin, PluginKey } from "prosemirror-state";

import { ImagePlaceholderView } from "./ImagePlaceholderView";

/**
 * imagePlaceholderPluginKey
 * @description This is the plugin key for the image placeholder plugin.
 */
export const imagePlaceholderPluginKey = new PluginKey("image-placeholder-plugin");

/**
 * imagePlaceholderPlugin
 * @description binds imagePlaceholder node view to editor state
 */
export const imagePlaceholderPlugin = () => {
	const p = new Plugin({
		key: imagePlaceholderPluginKey,
		state: {
			init() {
				return {};
			},
			apply(_transaction, value, _prevState, _nextState) {
				return value;
			},
		},
		props: {
			nodeViews: {
				image_placeholder: () => {
					return new ImagePlaceholderView();
				},
			},
		},
	});

	return p;
};
