import { type Expression } from "@babel/types";

import { type FunctionBodyCell } from "~/utils/function-body-cell";

import { AstPattern } from "../ast-pattern";
import { makeLocImmerSerializable } from "./patternUtil";

export type KubernetesAtlasProxyFetchClusterMatches = Pick<
	Expression,
	"type" | "start" | "end" | "loc"
>[];

export const kubernetesAtlasProxyFetchPattern = AstPattern.anywhere({
	type: "AwaitExpression",
	argument: {
		type: "CallExpression",
		callee: { type: "Identifier", name: "atlasProxyFetch" },
		arguments: [
			AstPattern.any(),
			// {
			// 	type: "Literal",
			// 	value: "/v1/apis/kubernetes/api/v1/nodes",
			// },
			{
				type: "ObjectExpression",
				properties: [
					{
						// type: "Property",
						key: {
							type: "Identifier",
							name: "headers",
						},
						value: {
							type: "ObjectExpression",
							properties: [
								{
									// type: "Property",
									key: {
										// type: "Literal",
										value: "Atlas-Apis-Kubernetes-Clusterid",
										// raw: '"Atlas-Apis-Kubernetes-Clusterid"',
									},
									value: AstPattern.captureGroup(
										AstPattern.any(),
										(
											capture,
											acc,
											secretArg
										): KubernetesAtlasProxyFetchClusterMatches =>
											makeLocImmerSerializable(secretArg)
									),
								},
							],
						},
						// kind: "init",
					},
				],
			},
		],
	},
});
/**
 * Matches function calls of the form `Moment.serviceDetails.fromUrlRoute()`.
 */
export const matchKubernetesAtlasProxyFetchPattern = async (
	cell: FunctionBodyCell | null
): Promise<KubernetesAtlasProxyFetchClusterMatches> => {
	// return if cell body is undefined
	if (!cell?.body) {
		return [];
	}

	return (await AstPattern.match(
		kubernetesAtlasProxyFetchPattern,
		cell.body
	)) as KubernetesAtlasProxyFetchClusterMatches;
};
