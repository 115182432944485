import { type Attrs, type Node, type NodeSpec } from "prosemirror-model";

import { camelCaseToDash } from "../../prosemirror-utils/camelCaseToDash";
import { createCellID } from "../../prosemirror-utils/createCellID";

export const text_cell: NodeSpec = {
	content: "(block)",
	draggable: false,
	attrs: {
		id: {},
		isCollapsed: {
			default: "false",
		},
	},
	parseDOM: [
		{
			tag: "div[data-node-type='text_cell']",
			preserveWhitespace: "full",
			getAttrs: (node: string | HTMLElement) => {
				if (typeof node === "string") {
					return false;
				}

				if (!(node instanceof HTMLElement)) {
					return false;
				}

				return {
					id: createCellID(),
					isCollapsed:
						node.getAttribute(`data-${camelCaseToDash("isCollapsed")}`) === "true",
				};
			},
		},
	],
	toDOM(node: Node) {
		const id = node.attrs["id"];
		const isCollapsed = node.attrs["isCollapsed"] === "true";

		const attrs: Attrs = {
			id,
			"data-node-type": "text_cell",
			[`data-${camelCaseToDash("isCollapsed")}`]: String(isCollapsed),
		};

		// note: this is overwritten by "text-cell-plugin" but needs a default value to meet schema requirements
		return ["div", attrs, 0];
	},
};
