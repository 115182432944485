import {
	type AppState,
	type Auth0ContextInterface,
	type User as Auth0User,
	useAuth0,
} from "@auth0/auth0-react";
import { type NextRouter } from "next/router";

import { setUserProperty } from "@moment/logging";

import { getAuthConfigs } from "~/auth/authconfig";
import { getAuthURLs, isDesktopAppMode } from "~/auth/interop/common";
import {
	clearStore,
	createRedirectEntry,
	deleteRedirectEntry,
	findValidRedirectEntry,
	getRedirectEntry,
	saveNewWindowId,
	saveRedirectEntry,
} from "~/auth/redirectStore";
import { type User, auth0UserIdToGiteaUserId } from "~/models/user";
import { log } from "~/utils/datadog/datadog";

/**
 * Custom hook provider for all auth-related functionality.
 */

/**
 * Context interface for the auth information.
 *
 * This context is very similar to the Auth0Context that that the Auth0 library provides,
 * with some additional overridden properties to meet our implementation. With the
 * exception of `user`, all overridden properties should be functionally identical
 * so that the Auth0 documentation is still usable.
 *
 * Available properties:

 * State booleans (e.g. yes/no):
 * * isAuthenticated
 * * isAuthenticatedOrLocal
 * * isLoading
 * * isLocalApp

 * User data:
 * * user
 * * _Auth0User <-- use this one only if truly necessary

 * Token promises (consider add anything needed from these as well-named getters):
 * * getAccessTokenSilently
 * * getIdTokenClaims

 * Auth workflow funcs. you shouldn't need these if you aren't implementing auth code.
 * * error
 * * getAccessTokenWithPopup
 * * loginWithRedirect
 * * loginWithPopup
 * * logout
 * * handleRedirectCallback
*/
export interface AuthContext
	extends Omit<
		Auth0ContextInterface,
		"user" | "loginWithRedirect" | "logout" | "getAccessTokenSilently"
	> {
	user: User | undefined;
	getAccessTokenSilently: (boolean?, string?) => Promise<string | undefined>;
	isAuthenticatedOrLocal: boolean;
	isLocalApp: boolean;
	loginWithRedirect: (string?) => Promise<void>;
	logoutWithRedirect: (string?) => Promise<void>;
	_Auth0User?: Auth0User;
}

export const useAuth = (): AuthContext => {
	const {
		getAccessTokenSilently,
		isAuthenticated,
		loginWithRedirect,
		logout,
		user,
		...moreAuth0
	} = useAuth0();
	const { auth0ClientId } = getAuthConfigs(process.env.AUTH_ENV);
	const { logoutRedirectUri } = getAuthURLs(process.env.AUTH_ENV);

	const isLocalApp = isDesktopAppMode();
	const isAuthenticatedOrLocal = isAuthenticated || isLocalApp;
	const mappedUser = mapUser(user);

	const getAccessToken = (autoLogin?: boolean, redirectPath?: string) => {
		return getAccessTokenSilently().catch((e) => {
			if (autoLogin) {
				console.log(`Login was invalid, redirecting to Login page`);
				void loginAction(redirectPath);
			}
			return Promise.reject(e);
		});
	};

	// Use a popup window, intended for electron. This performs the
	// authentication in context of the electron app, instead
	// of in a separate browser. During the process we will
	// not hide the previous page; if the user closes the popup
	// then they can immediately resume viewing public pages.
	const performPopupLogin = async (
		appState: AppState,
		redirectEntry
	): Promise<number | undefined> => {
		// check if a rediect entry is already present, if so, we will
		const currentRedirectEntry = findValidRedirectEntry();

		if (currentRedirectEntry !== null) {
			const { loginUrl, windowId } = currentRedirectEntry;
			if (windowId && loginUrl) {
				const newWindowId = await window.desktopIpcApi?.focusWindow(windowId, loginUrl);

				// we want to save the old login url, so just update the window id
				if (newWindowId !== windowId) {
					saveNewWindowId(currentRedirectEntry.id, newWindowId);
				}

				return newWindowId;
			}
		}

		let windowId = undefined;

		await loginWithRedirect({
			appState,
			async openUrl(url) {
				console.log(`navigating to auth0 login in popup browser window, url=${url}`);
				windowId = await window.desktopIpcApi?.launchWindow(url);
				// if we got back a valid window id, attempt to update the redirect
				// entry with that value so that we can use it again later
				if (windowId && redirectEntry) {
					attemptSaveRedirect({
						id: redirectEntry.id,
						redirectPath: redirectEntry.redirectPath,
						windowId,
						loginUrl: url,
					});
				}
			},
		});

		return windowId;
	};

	// Use normal in-browser tab redirect, intended for website.
	// This will redirect the tab to the Auth0 auth portal.
	// Users can use their browser navigation to return
	// if they just want to view public pages.
	const performRedirectLogin = async (appState) => {
		console.log(`navigating to auth0 login in current browser tab`);
		await loginWithRedirect({ appState });
	};

	// redirectPath logic is always best effort
	const attemptSaveRedirect = (entry: {
		id?: string;
		redirectPath?: string;
		windowId?: number;
		loginUrl?: string;
	}) => {
		if (entry.redirectPath || entry.windowId) {
			const redirectEntry = createRedirectEntry({ ...entry });
			try {
				saveRedirectEntry(redirectEntry);
				return redirectEntry;
			} catch (e) {
				log(
					"Failed to save redirect entry before login/logout redirect",
					{ error: e },
					"error"
				);
			}
		}
		return undefined;
	};

	// Login will stash the redirect information as redirectId in the "state" query value.
	// In the callback, that same "state" value will be provided as the argument.
	// If state (redirectId) is not provided, or does not retrieve a redirectPath,
	// then login will redirect to landing page.
	const loginAction = async (redirectPath?: string) => {
		const redirectEntry = attemptSaveRedirect({ redirectPath });
		const state = redirectEntry ? { redirectId: redirectEntry.id } : {};
		if (isDesktopAppMode()) {
			await performPopupLogin(state, redirectEntry);
		} else {
			await performRedirectLogin(state);
		}
	};

	// Logout will stash the redirectId as the query parameter "redirectId".
	// logoutParams.returnTo must be a consistent, allowlisted URL, and
	// we pass the intended redirect target via the redirectEntry.
	const logoutAction = async (redirectPath?: string) => {
		const redirectEntry = attemptSaveRedirect({ redirectPath });
		// this is the URL that Auth0 will redirect to, from there we use the
		// redirectId to further redirect within the app.
		const redirectUrl = `${logoutRedirectUri}${redirectEntry ? `?redirectId=${redirectEntry.id}` : ""}`;
		console.log(`attempting logout with redirect url ${redirectUrl}`);
		await logout({
			clientId: auth0ClientId,
			logoutParams: {
				returnTo: redirectUrl,
				federated: false,
			},
		});
	};

	if (isAuthenticated) {
		// if the user is authenticated, we should clear the redirect store
		// and update datadog with the user information
		clearStore();
		if (mappedUser) {
			setUserProperty("email", mappedUser.email);
			setUserProperty("isMoment", mappedUser.isMomentEmployee);
			setUserProperty("id", mappedUser.id);
			setUserProperty("name", mappedUser.name);
		}
	}

	return {
		getAccessTokenSilently: getAccessToken,
		isAuthenticated,
		isAuthenticatedOrLocal,
		isLocalApp,
		loginWithRedirect: loginAction,
		logoutWithRedirect: logoutAction,
		user: mappedUser,
		_Auth0User: user,
		...moreAuth0,
	};
};

// Exported login callback implementation. As described above,
// the onLoginCallback will be given a parameter "appState"
// which contains the redirectId, which can load the redirectEntry.
export const onLoginCallback = (router: NextRouter) => (appState) => {
	const redirectId = appState?.redirectId;

	if (redirectId) {
		const { redirectPath, windowId } = getRedirectEntry(redirectId) || {};
		if (windowId) {
			// we don't care if this passes
			void window.desktopIpcApi?.closeWindow(windowId);
		}

		// lets delete the redirect
		deleteRedirectEntry(redirectId);

		if (redirectPath) {
			void router.replace(redirectPath);
			return;
		}
	}

	void router.replace("/docs");
};

// Exported logout callback. As describe above, the onLogoutCallback
// will retrieve the redirectId from the query parameters, which can
// load the redirectEntry.
export const onLogoutCallback = (router: NextRouter) => {
	const returnId = router.query["redirectId"];
	if (returnId && typeof returnId === "string") {
		const redirectPath = getRedirectEntry(returnId)?.redirectPath;
		if (redirectPath) {
			void router.replace(redirectPath);
			return;
		}
	}

	void router.replace("/docs");
};

// Maps the Auth0User to our existing User interface.
const mapUser = (user: Auth0User | undefined) => {
	if (!user) {
		return undefined;
	}

	const id = user.sub;
	const name = user.name;
	const email = user.email;

	const giteaUserId = user.sub ? auth0UserIdToGiteaUserId(user.sub) : "";
	const nickname = user.nickname || "";
	const givenName = user.given_name || "";
	const familyName = user.family_name || "";
	const profilePicture = user.picture || "";
	const updatedAt = user.updated_at || "";
	const isMomentEmployee = user["http://api.moment.dev/isMoment"] || false;

	const organizationID = "";
	const alternateIDs = null;

	if (!(id && name && email)) {
		console.log(`Auth0 returned a user but expected properties are missing`, {
			id,
			name,
			email,
		});
		return undefined;
	}

	const mappedUser: User = {
		id,
		name,
		email,
		giteaUserId,
		nickname,
		givenName,
		familyName,
		profilePicture,
		updatedAt,
		organizationID,
		alternateIDs,
		isMomentEmployee,
	};

	return mappedUser;
};

export const isTokenError = (e) => {
	return (
		e instanceof Error &&
		"error" in e &&
		(e.error === "missing_refresh_token" || e.error === "invalid_grant")
	);
};
