import { CloseOutline, View, ViewOff } from "@carbon/icons-react";
import cx from "classnames";
import type { FC, ForwardRefRenderFunction, InputHTMLAttributes, ReactElement, Ref } from "react";
import { forwardRef, useRef, useState } from "react";

import styles from "./_.module.css";

import { Button } from "../Button";

export type InputTextVariant = "default" | "mono" | "success" | "negative";
export type InputTextSize = "lg" | "md" | "sm";
export type InputTextProps = {
	variant?: InputTextVariant | InputTextVariant[];
	size?: InputTextSize;
	segmentPosition?: "left" | "center" | "right" | "none";
	grow?: boolean;
	fullWidth?: boolean;
	hideValue?: boolean;
	clearValue?: boolean;
	hideClearValueIfEmpty?: boolean;
	StartIcon?: ReactElement;
	EndIcon?: ReactElement;
	value?: string;
	ref?: Ref<HTMLInputElement>;
	disableElevation?: boolean;
	noBorder?: boolean;
	noChrome?: boolean;
};

type UnionProps = Omit<InputHTMLAttributes<HTMLInputElement>, "size"> & InputTextProps;

// This allows the input to be resized in a flex layout below the default of 20
const DEFAULT_INPUT_SIZE = 10;

const BaseInputText: ForwardRefRenderFunction<HTMLInputElement, UnionProps> = (
	{
		// HTML Attributes
		// className = "",
		placeholder = "Value",
		disabled: isDisabled = false,
		// Style Attributes
		hideValue = false,
		clearValue = false,
		hideClearValueIfEmpty = false,
		variant = "default",
		size = "lg",
		segmentPosition = "none",
		fullWidth = false,
		value,
		disableElevation = false,
		noBorder = false,
		noChrome = false,
		// Utilities
		// tooltip = null,
		// Child Components
		StartIcon,
		EndIcon,
		// remaining props
		...props
	},
	ref
) => {
	const inputWrapper = useRef<HTMLDivElement | null>(null);

	const [isObscured, setIsObscured] = useState<boolean>(hideValue || false);

	const getClassNames = () => {
		const variantClassname = Array.isArray(variant)
			? variant.map((i) => "input-text--" + i).join(" ")
			: "input-text--" + variant;
		return cx([
			styles["input-text"],
			"input-text",
			variantClassname,
			size && styles[`input-text--${size}`],
			segmentPosition && styles[`input-text--segment-${segmentPosition}`],
			isDisabled && styles["input-text--disabled"],
			(hideValue || clearValue) && styles["input-text--with-modifiers"],
			fullWidth && "w-full",
			noBorder && "!border-none !shadow-none",
			noChrome && [
				"!border-none",
				"!shadow-none",
				"!ring-transparent",
				"!bg-transparent",
				"[&>input]:!p-0",
			],
		]);
	};

	const takeFocus = () => {
		if (ref && "current" in ref) {
			ref?.current?.focus();
		}
	};

	const toggleObscured = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setIsObscured(!isObscured);
		event.currentTarget.blur();
	};

	const clearInput = (event: React.SyntheticEvent<HTMLButtonElement>) => {
		event.preventDefault();
		if (ref && "current" in ref && ref.current) {
			const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
				window.HTMLInputElement.prototype,
				"value"
			)?.set;

			if (!nativeInputValueSetter) return;

			nativeInputValueSetter.call(ref.current, "");

			const ev2 = new Event("input", { bubbles: true });
			ref.current.dispatchEvent(ev2);
		}
		takeFocus();
	};

	const Modifiers = () => {
		return (
			<>
				{[hideValue, clearValue].some((i) => i) && (
					<div className={styles["input-text__modifiers"]}>
						{hideValue && (
							<Button
								aria-label="show/hide input"
								level="tertiary"
								size="xs"
								onClick={toggleObscured}
								disabled={isDisabled}
								icon={isObscured ? View : ViewOff}
							/>
						)}
						{clearValue && (
							<Button
								aria-label="clear input"
								level="tertiary"
								size="xs"
								onClick={clearInput}
								disabled={isDisabled || value === undefined || value.length === 0}
								className={
									hideClearValueIfEmpty &&
									(value === undefined || value.length === 0)
										? "hidden"
										: "block"
								}
								icon={CloseOutline}
							/>
						)}
					</div>
				)}
			</>
		);
	};

	return (
		<div
			ref={inputWrapper}
			className={getClassNames()}
			data-disableElevation={disableElevation}
			style={{ position: "relative" }}
		>
			<MainIcon StartIcon={StartIcon} />
			<input
				ref={ref}
				placeholder={placeholder}
				autoComplete={"off"}
				type={isObscured ? "password" : "text"}
				disabled={isDisabled}
				value={value}
				size={DEFAULT_INPUT_SIZE}
				{...props}
			/>
			<SecondaryIcon EndIcon={EndIcon} />
			<Modifiers />
			{/* {tooltip && <Tooltip options={tooltip} display={isFocus} />} */}
		</div>
	);
};
const MainIcon: FC<{ StartIcon?: ReactElement }> = ({ StartIcon }) => {
	return (
		<>
			{StartIcon && (
				<div
					className={cx(
						styles["input-text__main-icon], styles[input-text__icon"],
						"flex flex-row items-center pl-2"
					)}
				>
					{StartIcon}
				</div>
			)}
		</>
	);
};

const SecondaryIcon: FC<{ EndIcon?: ReactElement }> = ({ EndIcon }) => {
	return (
		<>
			{EndIcon && (
				<div className={styles["input-text__secondary-icon input-text__icon"]}>
					{EndIcon}
				</div>
			)}
		</>
	);
};

export const InputText = forwardRef(BaseInputText);
