import type { NodePath, Visitor } from "@babel/traverse";
import type { Identifier, JSXIdentifier, Node } from "@babel/types";

const disallowedCellNames = new Set([
	// In addition to the fact that it would be weird to have a cell named `undefined`, there are
	// technical reasons to disallow it. `undefined` is a legal identifier in JavaScript, so the
	// cell parser always believes it's an unbound global identifier. Fixing this means re-parsing
	// every cell any time someone registers or updates a cell with the name `undefined`, which is a
	// pretty high price for somethign we never want people to do anyway.
	"undefined",
]);

// Used to check if an identifier is inside a TS type annotation or definition.
const tsAstNodeTypes = new Set(["TSTypeAliasDeclaration", "TSTypeReference"]);

export const refVisitor = (
	onRef: (path: NodePath<Identifier | JSXIdentifier>) => void
): Visitor => {
	return {
		JSX: (path) => {
			// IMPORTANT: References to `React` symbol are generally implied by the use of JSX tags.
			// Here we emit a synthetic identifier so that the code using this visitor doesn't miss
			// this implicit reference.
			const newPath = {
				...path,
				node: {
					type: "Identifier",
					name: "React",
					decorators: null,
					optional: null,
					typeAnnotation: null,
					leadingComments: null,
					innerComments: null,
					trailingComments: null,
					start: null,
					end: null,
					loc: null,
				},
			} as unknown as NodePath<Identifier>;

			onRef(newPath);
		},
		Identifier: (path: NodePath<Identifier>) => {
			//
			// Any identifier that's a reference to something and also *NOT* bound in lexical scope
			// (i.e., is a free variable) is a ref to another cell.
			//

			const freeVar =
				path.isReferencedIdentifier() && !path.scope.hasBinding(path.node.name, true);

			// Identifiers that are part of type definitions are not references!
			const insideTsType =
				!Array.isArray(path.container) && tsAstNodeTypes.has((path.container as Node).type);
			if (freeVar && !disallowedCellNames.has(path.node.name) && !insideTsType) {
				onRef(path);
			}
		},
		JSXIdentifier: (path: NodePath<JSXIdentifier>) => {
			//
			// Any `JSXIdentifier` that's a reference to something, and also *NOT* bound in lexical
			// scope (i.e., is a free variable) is a ref to another cell.
			//
			// Custom components are denoted by `JSXIdentifier`s. For any such identifier,
			// `isReferencedIdentifier` will return true, thus any referenced `JSXIdentifier` that
			// is not bound in lexical scope must be a ref. Note that built-in tags like `<div />`
			// are encoded differently, so this rule should always hold.
			//
			// For example, `<div />` turns into this:
			//
			//     React.createElement("div", null));
			//
			// While `<Boop />` turns into this:
			//
			//     React.createElement(Boop, null));
			//
			// So `Boop` could be a ref while `"div"` can't.
			//

			const freeVar =
				path.isReferencedIdentifier() && !path.scope.hasBinding(path.node.name, true);
			if (freeVar && !disallowedCellNames.has(path.node.name)) {
				onRef(path); // <- MIGHT be a cell ref, depending
			}
		},
	};
};
