import { type EditorState } from "prosemirror-state";

import { getCellPositions } from "./getCellPositions";
import { type CellPosition } from "./types";

export const getFirstCell = (state: EditorState): CellPosition | undefined => {
	const cellPositions = getCellPositions(state);
	if (cellPositions.length === 0) {
		return;
	}
	return cellPositions[0];
};
