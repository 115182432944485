import { type Attrs, type Node, type NodeSpec } from "prosemirror-model";

import { safeStringify } from "../../json";
import { Logger } from "../../logger";
import { type LoggerInterface, noopLogger } from "../../logger-types";
import { camelCaseToDash } from "../../prosemirror-utils/camelCaseToDash";
import { createCellID } from "../../prosemirror-utils/createCellID";

// for some reason, ci did not like constructing this logger.
// This is a band-aid fix.
const getLogger = () => {
	// for some reason, ci did not like constructing this logger.
	// This is a band-aid fix.
	const env = process.env["APP_ENV"] || "local";
	let logger: LoggerInterface;
	if (env === "testing") {
		logger = noopLogger;
	} else {
		logger = new Logger(env, "compute_cell");
	}
	return logger;
};
export const compute_cell: NodeSpec = {
	content: "text*",
	draggable: false,
	atom: true,
	attrs: {
		id: {},
		cellName: {},
		isCollapsed: { default: "false" },
		cellWidth: { default: "narrow" },
		cellState: { default: "{}" },
		checksum_hash: { default: undefined },
		checksum_version: { default: undefined },
		version: { default: 0 },
	},
	parseDOM: [
		{
			tag: "compute_cell",
			preserveWhitespace: "full",
			getAttrs: (node: string | HTMLElement) => {
				const logger = getLogger();
				if (typeof node === "string") {
					return false;
				}

				if (!(node instanceof HTMLElement)) {
					return false;
				}

				let cellState: string | undefined;

				try {
					const attribute = node.getAttribute(`data-${camelCaseToDash("cellState")}`);
					if (attribute === null) {
						cellState = "{}";
					} else {
						const parsedValue = JSON.parse(attribute);
						if (typeof parsedValue === "object" && parsedValue !== null) {
							cellState = safeStringify(parsedValue);
						} else {
							cellState = "{}";
						}
					}
				} catch (e) {
					logger.error("Failed to parse cellState", { error: e, node });
					cellState = "{}";
				}

				return {
					id: createCellID(),
					cellName: node.getAttribute(`data-${camelCaseToDash("cellName")}`),
					isCollapsed:
						node.getAttribute(`data-${camelCaseToDash("isCollapsed")}`) === "true",
					cellWidth:
						node.getAttribute(`data-${camelCaseToDash("cellWidth")}`) || "narrow",
					cellState,
				};
			},
		},
	],
	toDOM(node: Node) {
		const cellName = node.attrs["cellName"];
		const isCollapsed = node.attrs["isCollapsed"];
		const cellWidth = node.attrs["cellWidth"];
		const cellState = node.attrs["cellState"];

		const attrs: Attrs = {
			id: node.attrs["id"],
			[`data-${camelCaseToDash("cellName")}`]: cellName || "",
			[`data-${camelCaseToDash("isCollapsed")}`]: isCollapsed ? "true" : "false",
			[`data-${camelCaseToDash("cellWidth")}`]: cellWidth || "narrow",
			[`data-${camelCaseToDash("cellState")}`]: safeStringify(cellState || {}),
		};

		// this is overwritten by computeCellPlugin.ts
		// but if it's empty, it breaks cell selection
		return ["compute_cell", attrs, 0];
	},
};
