import { type NodeView } from "prosemirror-view";
import { renderToString } from "react-dom/server";

import { LoadingSpinner } from "@moment/design-system/LoadingSpinner";

const ImagePlaceholder = () => {
	return (
		<>
			<LoadingSpinner />
		</>
	);
};

export class ImagePlaceholderView implements NodeView {
	public dom: HTMLElement;

	constructor() {
		const div = document.createElement("div");

		const htmlString = renderToString(<ImagePlaceholder />);

		this.dom = div;
		this.dom.innerHTML = htmlString;
		this.dom.classList.add("image_placeholder");
	}
}
