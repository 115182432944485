import { Close } from "@carbon/icons-react";
import * as RadixDialog from "@radix-ui/react-dialog";
import { Separator } from "@radix-ui/react-separator";
import cx from "classnames";
import type {
	FC,
	ForwardRefRenderFunction,
	HTMLAttributes,
	PropsWithChildren,
	RefAttributes,
} from "react";
import { forwardRef, useLayoutEffect, useRef, useState } from "react";

import { Button } from "../Button";

const DialogTrigger: ForwardRefRenderFunction<
	HTMLButtonElement,
	RadixDialog.DialogTriggerProps & RefAttributes<HTMLButtonElement>
> = ({ children, ...props }, ref) => {
	return (
		<RadixDialog.Trigger ref={ref} {...props}>
			{children}
		</RadixDialog.Trigger>
	);
};

const DialogTitle: ForwardRefRenderFunction<
	HTMLHeadingElement,
	RadixDialog.DialogTitleProps &
		RefAttributes<HTMLHeadingElement> & {
			onCloseClick?: () => void;
			noMargin?: boolean;
			hideClose?: boolean;
		}
> = ({ children, onCloseClick, noMargin, hideClose, ...props }, ref) => {
	return (
		<div className={cx({ "mb-6": !noMargin })}>
			<RadixDialog.Title
				ref={ref}
				className="flex flex-row items-center px-6 py-4"
				{...props}
			>
				<div className="flex items-center space-x-2 text-lg">{children}</div>
				{!hideClose && (
					<div className="-mr-2 ml-auto">
						<Dialog.Close asChild tabIndex={-1}>
							<Button
								level="tertiary"
								aria-label="Close"
								onClick={onCloseClick}
								icon={Close}
							/>
						</Dialog.Close>
					</div>
				)}
			</RadixDialog.Title>
			<Separator className="border-b" />
		</div>
	);
};

const DialogContent: ForwardRefRenderFunction<
	HTMLDivElement,
	RadixDialog.DialogContentProps & RefAttributes<HTMLDivElement> & { wide?: boolean }
> = ({ children, className, wide = false, ...props }, ref) => {
	const innerRef = useRef<HTMLDivElement | null>(null);
	const [alignTop, setAlignTop] = useState(false);

	useLayoutEffect(() => {
		const resizeListener = () => {
			const overlay = document.querySelector("[data-overlay=true]");
			const height = innerRef.current?.clientHeight;
			const overlayHeight = overlay?.clientHeight;

			if (!height || !overlayHeight) return;

			setAlignTop((p) => {
				const newValue = height > overlayHeight;
				// Only update state if it has changed
				return newValue !== p ? newValue : p;
			});
		};

		resizeListener();

		window.addEventListener("resize", resizeListener);
		const resizeObserver = new ResizeObserver(resizeListener);

		if (innerRef.current !== null) {
			resizeObserver.observe(innerRef.current);
		}

		return () => {
			window.removeEventListener("resize", resizeListener);
			resizeObserver.disconnect();
		};
	}, []);

	return (
		<RadixDialog.Content
			{...props}
			ref={(node) => {
				innerRef.current = node;
				if (typeof ref === "function") {
					ref(node);
				} else if (ref) {
					ref.current = node;
				}
			}}
			className={cx(
				"border",
				"border-primary",
				"flex",
				"flex-col",
				"rounded-md",
				"shadow-lg",
				"z-dialog",

				// On small screens, don't render wider than the screen
				"w-[90%]",
				"max-w-[90%]",

				// On larger screens, enforce min/max width
				"md:w-auto",
				"md:h-auto",
				"md:min-w-[400px]",
				!wide && "md:max-w-[640px]",
				wide && "md:max-w-[1000px]",

				// Handle when content becomes larger than overlay and forces scroll
				!alignTop && [
					"fixed",
					"left-1/2",
					"top-1/2",
					"-translate-x-1/2",
					"-translate-y-1/2",
				],
				alignTop && ["relative", "left-1/2", "my-4", "-translate-x-1/2"],

				"bg-primary",

				className
			)}
			onClick={(e) => {
				// Prevent clicks from propagating to the overlay
				e.stopPropagation();
				props.onClick?.(e);
			}}
		>
			{children}
		</RadixDialog.Content>
	);
};

const DialogFooter: FC<{ children: React.ReactNode }> = ({ children }) => {
	return (
		<div>
			<Separator className="border-b" />
			<div
				className={cx(
					"flex",
					"justify-end",
					"space-x-2",
					"rounded-b-md",
					"bg-secondary",
					"px-6",
					"py-4"
				)}
			>
				{children}
			</div>
		</div>
	);
};

const DialogOverlay: ForwardRefRenderFunction<
	HTMLDivElement,
	RadixDialog.DialogOverlayProps & RefAttributes<HTMLDivElement>
> = ({ children, ...props }, ref) => {
	return (
		<RadixDialog.Overlay
			{...props}
			ref={ref}
			data-overlay="true"
			className={cx("fixed", "inset-0", "bg-modal", "z-dialog", "overflow-auto")}
		>
			{children}
		</RadixDialog.Overlay>
	);
};

const sectionClasses = "px-6 pb-6 flex-1";

const DialogSection: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({
	children,
	className,
	...props
}) => {
	return (
		<div className={cx(className, sectionClasses)} {...props}>
			{children}
		</div>
	);
};

const DialogDescription: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({
	children,
	className,
	...props
}) => {
	return (
		<RadixDialog.Description className={cx(className, sectionClasses)} {...props}>
			{children}
		</RadixDialog.Description>
	);
};

export const Dialog = {
	Root: RadixDialog.Root,
	Trigger: forwardRef(DialogTrigger),
	Portal: RadixDialog.Portal,
	Overlay: forwardRef(DialogOverlay),
	Content: forwardRef(DialogContent),
	Section: DialogSection,
	Footer: DialogFooter,
	Title: forwardRef(DialogTitle),
	Description: DialogDescription,
	Close: RadixDialog.Close,
};
