import { reactKeys } from "@nytimes/react-prosemirror";
import { type CreateTRPCClient } from "@trpc/client";
import { history } from "prosemirror-history";
import { keymap } from "prosemirror-keymap";
import { type Schema } from "prosemirror-model";
import { Plugin, PluginKey } from "prosemirror-state";

import { type AppRouter } from "@moment/desktop-app-ws-api";

import { Flags, isEnabled } from "~/features";

import { baseKeymap } from "../commands/baseKeymap";
import { keyMap } from "../commands/keymap";
import { buildInputRules } from "../rules";
import { clickableLinkPlugin } from "./clickable-link-plugin";
import { documentPlaceholderPlugin } from "./document-placeholder-plugin/documentPlaceholderPlugin";
import { dropCursorPlugin } from "./dropCursorPlugin";
import { highlightPlugin } from "./highlight-plugin/highlightPlugin";
import { imagePlaceholderPlugin } from "./image-placeholder-plugin/imagePlaceholderPlugin";
import { imageDropPlugin } from "./imageDropPlugin";
import { textPlaceholderPlugin } from "./text-placeholder-plugin/textPlaceholderPlugin";
import { visualDebugPlugin } from "./visual-debug-plugin/visualDebugPlugin";

export type Options = {
	workspaceId: string;
	documentId: string;
	pageId: string;
	schema: Schema;
	readonly: boolean;
	trpcClient: CreateTRPCClient<AppRouter> | null;
};

// !! This module exports helper functions for deriving a set of basic
// menu items, input rules, or key bindings from a schema. These
// values need to know about the schema for two reasons—they need
// access to specific instances of node and mark types, and they need
// to know which of the node and mark types that they know about are
// actually present in the schema.
//
// The `exampleSetup` plugin ties these together into a plugin that
// will automatically enable this basic functionality in an editor.

// A convenience plugin that bundles together a simple menu with basic
// key bindings, input rules, and styling for the example schema.
// Probably only useful for quickly setting up a passable
// editor—you'll need more control over your settings in most
// real-world situations.
//
//   options::- The following options are recognized:
//
//     schema:: Schema
//     The schema to generate key bindings and menu items for.
//
//     history:: ?bool
//     Set to false to disable the history plugin.
//
//     menuBar:: ?bool
//     Set to false to disable the menu bar.

export function createPlugins({
	workspaceId,
	documentId,
	pageId,
	schema,
	readonly,
	trpcClient,
}: Options): Plugin[] {
	const plugins: Plugin[] = [];

	if (trpcClient) {
		plugins.push(imageDropPlugin(workspaceId, documentId, pageId, trpcClient));
	}

	plugins.push(buildInputRules(schema));
	//
	// IMPORTANT: Base keymap needs to come before anything. Add-ons can interfere, and
	// generally plugins assume the base keymap has already terminated before executing.
	//

	plugins.push(keymap(baseKeymap(schema)));

	//
	// Custom keymap.
	//

	plugins.push(keymap(keyMap()));

	//
	// Everything else.
	//

	plugins.push(highlightPlugin());

	plugins.push(dropCursorPlugin());

	plugins.push(imagePlaceholderPlugin());
	plugins.push(clickableLinkPlugin());

	if (!readonly) {
		plugins.push(documentPlaceholderPlugin());

		plugins.push(textPlaceholderPlugin());
	}

	if (isEnabled(Flags.CanvasLayoutVisualDebug)) {
		plugins.push(visualDebugPlugin());
	}

	plugins.push(history());

	plugins.push(reactKeys());

	return plugins.concat(
		new Plugin({
			key: new PluginKey("moment-setup-style"),
			props: {
				attributes: { class: "rich-text ProseMirror-moment-setup-style" },
			},
		})
	);
}
