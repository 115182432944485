import { type InputRule, wrappingInputRule } from "prosemirror-inputrules";
import { type NodeType } from "prosemirror-model";

/**
 * Given a list node type, returns an input rule that turns a bullet (dash, plus, or asterisk) at the start of a textblock into a
 * list item.
 *
 * @param nodeType - The node type to wrap.
 * @returns - An input rule that turns a bullet (dash, plus, or asterisk) at the start of a textblock into a list item.
 */
export const listItemRule = (nodeType: NodeType): InputRule => {
	return wrappingInputRule(/^\s*([-+*]|\d+\.)\s$/, nodeType, (match) => {
		return {
			// If match is a number followed by a dot, set it to #
			bullet: match[1]?.match(/^\d+\.$/) ? "#" : match[1],
			indent: 0,
		};
	});
};
