import { type Attrs, type DOMOutputSpec, type Node, type NodeSpec } from "prosemirror-model";

import { CELL_SPEC_ATTRS } from "../../prosemirror-utils/constants";
import { makeTextCellDefaultToDOMAttrs } from "../../prosemirror-utils/makeTextCellDefaultToDOMAttrs";

export type BulletChar = (typeof BULLET_CHARS)[number];
export const BULLET_CHARS = ["-", "*", "+", "#"] as const;

export const DEFAULT_BULLET = "-";
export const DEFAULT_INDENT = 0;

export const list_item: NodeSpec = {
	defining: true,
	group: "block",
	content: "paragraph",
	attrs: {
		...CELL_SPEC_ATTRS,
		bullet: { default: DEFAULT_BULLET },
		indent: { default: DEFAULT_INDENT },
	},
	parseDOM: [
		{
			tag: "div[data-node-type='list_item']",
			preserveWhitespace: "full",
			getAttrs: (p: string | HTMLElement) => {
				if (!(p instanceof HTMLElement)) {
					return false;
				}

				const attrs: Attrs = {
					bullet: p.getAttribute("data-bullet") ?? DEFAULT_BULLET,
					indent: parseInt(p.getAttribute("data-indent") ?? "0"),
				};

				return attrs;
			},
		},
		{
			tag: "li",
			preserveWhitespace: "full",
			getAttrs: (p: string | HTMLElement) => {
				if (!(p instanceof HTMLElement)) {
					return false;
				}

				const parent = p.parentElement;
				let bullet = p.getAttribute("data-bullet");
				if (!bullet && parent?.nodeName === "OL") {
					bullet = `#`;
				} else if (!bullet) {
					bullet = "-";
				}
				const attrs: Attrs = {
					bullet,
					indent: parseInt(p.getAttribute("data-indent") ?? "0"),
				};

				return attrs;
			},
		},
	],
	toDOM(node: Node): DOMOutputSpec {
		const attrs: Attrs = makeTextCellDefaultToDOMAttrs(node);

		return [
			"div",
			{
				...attrs,
				"data-node-type": "list_item",
				"data-bullet": node.attrs["bullet"],
				"data-indent": node.attrs["indent"],
			},
			0,
		];
	},
};
