import { type LoggerInterface, noopLogger } from "../logger-types";

export const safeParse = (str: string, logger: LoggerInterface) => {
	try {
		return JSON.parse(str);
	} catch (e) {
		const metadata = {
			input: str,
			error: e,
			trace: null,
		};
		if (e instanceof Error) {
			if (window === undefined) {
				// For node processes, I think we need to call this to create the stack.
				// See https://nodejs.org/api/errors.html#errorcapturestacktracetargetobject-constructoropt
				// for more details.
				Error.captureStackTrace(e);
			}
			logger.error("error parsing string", {
				...metadata,
				trace: e.stack,
			});
		} else {
			logger?.error("error parsing string", metadata);
		}
	}
	return {};
};

export const safeStringify = (o: unknown, space = 0, logger: LoggerInterface = noopLogger) => {
	try {
		if (space > 0) {
			return JSON.stringify(o, null, space);
		}
		return JSON.stringify(o);
	} catch (error) {
		logger?.error("error stringifying object", {
			error,
		});
		return "error_in_json_serialize";
	}
};
