import { useMutation } from "@tanstack/react-query";
import { useRouter } from "next/router";

import { useAuth } from "~/auth/useAuth";
import { giteaClient } from "~/utils/gitea";

import { giteaQueryKeys } from ".";

export const useArchiveRepoMutation = () => {
	const { getAccessTokenSilently } = useAuth();
	const router = useRouter();

	return useMutation({
		mutationKey: giteaQueryKeys.archiveRepo,
		mutationFn: async ({
			workspaceId,
			documentId,
		}: {
			workspaceId: string;
			documentId: string;
		}) => {
			const token = await getAccessTokenSilently(true, router.asPath);

			const gitea = giteaClient(token);

			const { data } = await gitea.repos.repoEdit(workspaceId, documentId, {
				archived: true,
			});

			return data;
		},
	});
};
