import { type Step } from "prosemirror-transform";
import z from "zod";

import { type Patch, patch } from "../api-canvas-metadata/Patch";
import { messageRequestUnauthorized } from "./auth";
import { type Checksum, checksum } from "./checksum";
import { type ProseMirrorClientId, prosemirrorClientId } from "./prosemirrorClientId";

//
// "Steps since" request.
//

/**
 * A request to the server for all transactions since a specific version.
 */
export const messageStepsSinceRequest = z.object({
	type: z.literal("steps-since-request"),
	version: z.number(),
});
export type MessageStepsSinceRequest = z.infer<typeof messageStepsSinceRequest>;

export function makeMessageStepsSinceRequest(version: number): MessageStepsSinceRequest {
	return { type: "steps-since-request", version };
}

//
// "Steps since" response, cavas collab server.
//

/**
 * A list of all transactions since a specific version.
 */
export const messageStepsSinceResponseAccepted = z.object({
	type: z.literal("steps-since-response-accepted"),
	version: z.number(),
	steps: z.array(z.unknown()),
	prosemirrorClientIds: z.array(prosemirrorClientId),
	checksum: checksum.optional(),
});
export type MessageStepsSinceResponseAccepted = z.infer<typeof messageStepsSinceResponseAccepted>;

export function makeMessageStepsSinceResponseAccepted(
	version: number,
	steps: Step[],
	prosemirrorClientIds: ProseMirrorClientId[],
	checksum?: Checksum | undefined
): MessageStepsSinceResponseAccepted {
	return {
		type: "steps-since-response-accepted",
		version,
		steps,
		prosemirrorClientIds,
		checksum,
	};
}

export const messageStepsSinceResponseRejected = z.object({
	type: z.literal("steps-since-response-rejected"),
	error: z.string(),
});
export type MessageStepsSinceResponseRejected = z.infer<typeof messageStepsSinceResponseRejected>;

export function makeMessageStepsSinceResponseRejected(
	error: string
): MessageStepsSinceResponseRejected {
	return { type: "steps-since-response-rejected", error };
}

export const messageStepsSinceResponse = z.union([
	messageStepsSinceResponseAccepted,
	messageStepsSinceResponseRejected,
	messageRequestUnauthorized,
]);
export type MessageStepsSinceResponse = z.infer<typeof messageStepsSinceResponse>;

//
// "Steps since" response, metadata collab server.
//

/**
 * A list of all transactions since a specific version.
 */
export const stepsSinceResponseAccepted = z.object({
	type: z.literal("steps-since-response-accepted"),
	version: z.number(),
	patches: z.array(patch),
});
export type StepsSinceResponseAccepted = z.infer<typeof stepsSinceResponseAccepted>;

export function makeStepsSinceResponseAccepted(
	version: number,
	patches: Patch[]
): StepsSinceResponseAccepted {
	return { type: "steps-since-response-accepted", version, patches };
}

export const stepsSinceResponseRejected = messageStepsSinceResponseRejected;
export type StepsSinceResponseRejected = z.infer<typeof stepsSinceResponseRejected>;

export function makeStepsSinceResponseRejected(error: string): StepsSinceResponseRejected {
	return { type: "steps-since-response-rejected", error };
}

export const stepsSinceResponse = z.union([
	stepsSinceResponseAccepted,
	stepsSinceResponseRejected,
	messageRequestUnauthorized,
]);
export type StepsSinceResponse = z.infer<typeof stepsSinceResponse>;
