import { InputRule } from "prosemirror-inputrules";
import { type MarkType } from "prosemirror-model";
import { type EditorState } from "prosemirror-state";

/* eslint-disable @typescript-eslint/no-explicit-any */
type AttrType = { [key: string]: any };

export const wrappingMarkRule = (
	regexp: RegExp,
	markType: MarkType,
	attrs: AttrType,

	/**
	 * The name of the match group that contains prefix that should be
	 * preserved in the text. For example, if the regexp is
	 * `/(?<prefixToPreserve> )~~(.+)~~/` and the text is ` ~~strike~~`,
	 * the text to be preserved is ` `.
	 */
	beginningMatchGroupName?: string
): InputRule => {
	const matchHandler = function (
		state: EditorState,
		match: RegExpMatchArray,
		start: number,
		_end: number
	) {
		const tr = state.tr;
		let end = _end;

		if (match.length >= 2) {
			// If the match has two groups, the second group is the text to be marked
			const firstMatch = match[0];
			const secondMatch = beginningMatchGroupName && match.length >= 3 ? match[2] : match[1];

			if (firstMatch === undefined || secondMatch === undefined) {
				return tr;
			}

			// Delete the matched text
			const beforeText =
				(beginningMatchGroupName && match.groups?.[beginningMatchGroupName]) || "";
			const textStart = start + firstMatch.indexOf(secondMatch);
			const textEnd = textStart + secondMatch.length;
			if (textEnd < end) {
				tr.delete(textEnd, end);
			}

			if (textStart > start) {
				// if there's no beginning match group
				// delete the beginning of the match
				start = start + beforeText.length;
				tr.delete(start, textStart);
			}
			end = start + secondMatch.length;
		}
		tr.addMark(start, end, markType.create(attrs));
		// Remove the mark when the text is deleted
		tr.removeStoredMark(markType);
		return tr;
	};
	return new InputRule(regexp, matchHandler);
};
