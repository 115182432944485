import { createContext } from "react";

import {
	type Pane,
	type PropertiesPaneState,
	type PropertiesPaneStateValues,
} from "~/store/canvas/types";
import { notImplementedFn } from "~/utils/context";

export type State = {
	paneState: PropertiesPaneState;
	pulsedPane: PropertiesPaneState;
	closePane: () => void;
	setPane: (s: PropertiesPaneState) => void;
	togglePane: (s: PropertiesPaneState) => void;
	togglePaneToggle: (pane: Exclude<Pane, undefined>) => void;
	paneEnabledState: Record<(typeof PropertiesPaneStateValues)[number], boolean>;
	paneTogglesState: Record<(typeof PropertiesPaneStateValues)[number], boolean>;
};

const initialState: State = {
	paneState: { pane: undefined },
	pulsedPane: { pane: undefined },
	closePane: notImplementedFn("closePane", "PropertiesPaneContext", undefined),
	setPane: notImplementedFn("setPane", "PropertiesPaneContext", undefined),
	togglePane: notImplementedFn("togglePane", "PropertiesPaneContext", undefined),
	togglePaneToggle: notImplementedFn("togglePaneToggle", "PropertiesPaneContext", undefined),
	paneTogglesState: {
		components: false,
		cellDependencies: false,
	},
	paneEnabledState: {
		components: false,
		cellDependencies: false,
	},
};

export const PropertiesPaneContext = createContext<State>(initialState);
