import { Node } from "prosemirror-model";

import { schema } from "@moment/api-collab/prosemirror-schema";

export function createEmptyPage() {
	return Node.fromJSON(schema, {
		type: "doc",
		content: [
			{
				type: "paragraph",
				content: [],
			},
		],
	});
}
