import shortuuid from "short-uuid";

export function generateShortUuid(): string {
	return shortuuid(shortuuid.constants.uuid25Base36).generate();
}

export function convertShortUuidToUuid(shortUuid: string): string {
	return shortuuid(shortuuid.constants.uuid25Base36).toUUID(shortUuid);
}

export function convertUuidToShortUuid(uuid: string): string {
	return shortuuid(shortuuid.constants.uuid25Base36).fromUUID(uuid);
}
