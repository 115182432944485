import { type Middleware } from "redux";

import { Logger } from "~/utils/logging";

const logger = new Logger("store/middlewares");

export const exceptionHandler = (logErrors: boolean): Middleware => {
	return () => (next) => (action) => {
		try {
			return next(action);
		} catch (error) {
			if (logErrors) {
				logger.error("exceptionHandler: Redux error", { action, error });
			}
			throw error;
		}
	};
};
