import { type InputRule, wrappingInputRule } from "prosemirror-inputrules";
import { type NodeType } from "prosemirror-model";

/**
 * Given a to-do node type, returns an input rule that turns a checkbox ([ ]) at the start of a textblock into a
 * to-do item.
 */
export const toDoRule = function (nodeType: NodeType): InputRule {
	// MATCHES
	// `[] `
	// `[ ] `
	// `[x] `
	// `[X] `

	const exp = /^\[(\s|x|X)?\]\s$/;

	return wrappingInputRule(exp, nodeType, (match) => {
		return {
			// If match contains an x, set it to completed, otherwise set it to to do
			status: match[1]?.match(/^[xX]$/) ? "completed" : "to do",
			indent: 0,
		};
	});
};
