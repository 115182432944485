import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";

import { useAuth } from "~/auth/useAuth";
import { getGiteaApiUrl } from "~/utils/gitea";
import { trpc } from "~/utils/trpc";

import { documentQueryKeys } from "../documents";
import { pageQueryKeys } from "../pages";

export const useCloneRepoMutation = () => {
	const queryClient = useQueryClient();
	const utils = trpc.useUtils();

	const { getAccessTokenSilently } = useAuth();
	const router = useRouter();

	const { mutateAsync: cloneGitRepo } = trpc.git.clone.useMutation({
		onSuccess: (_, { workspaceId, documentId }) => {
			void queryClient.invalidateQueries({
				queryKey: documentQueryKeys.document(workspaceId, documentId),
			});
			void queryClient.invalidateQueries({
				queryKey: pageQueryKeys.documentPages(workspaceId, documentId),
			});
		},
		onSettled: () => utils.git.invalidate(),
	});

	const mutation = useMutation({
		mutationFn: async (args: { workspaceId: string; documentId: string }) => {
			const { workspaceId, documentId } = args;

			if (workspaceId === "local") return;

			const remoteUrl = `${getGiteaApiUrl()}/git/${workspaceId}/${documentId}.git`;

			const authToken = await getAccessTokenSilently(true, router.asPath);
			if (!authToken) return;

			return await cloneGitRepo({ workspaceId, documentId, remoteUrl, authToken });
		},
	});

	return mutation;
};
