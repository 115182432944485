import { type ContentMatch } from "prosemirror-model";

export const defaultBlockAt = (match: ContentMatch) => {
	for (let i = 0; i < match.edgeCount; i++) {
		const { type } = match.edge(i);
		if (type.isTextblock && !type.hasRequiredAttrs()) {
			return type;
		}
	}
	return null;
};
