import { type TransformOptions } from "@babel/core";
import { type NodePath, type Visitor } from "@babel/traverse";
import {
	type FunctionDeclaration,
	type FunctionExpression,
	type Identifier,
	type JSXIdentifier,
	type Program,
} from "@babel/types";

import { type Parsed } from "../../components/canvas/easel/runtime";
import { type ImportDeclaration } from "../../runtime/cell-compiler";
import { standardParserPlugins } from "../../runtime/cell-compiler/parser/standardParserPlugins";
import { refVisitor } from "../../runtime/cell-compiler/visitor/refVisitor";
import {
	type ImportFromLocal,
	type ImportFromMomentId,
	type ImportFromMomentNamespace,
	type ImportFromPage,
	isImportFromLocal,
	isImportFromMomentId,
	isImportFromMomentNamespace,
	isImportFromPage,
	parseImportArgument,
} from "../../runtime/import";
import { parseAsync, transformFromAstAsync, traverseAsync } from "../deferred-babel";
import { isFile, isImportDeclaration } from "../deferred-babel/types";
import { isError } from "../helpers/error";
import { isObjectWithType } from "../helpers/object";

export const parseCell = async (
	cellName: string,
	code: string
): Promise<FunctionBodyCell | null> => {
	//
	// NOTES: This function needs to do a little extra work to make `JavaScriptFunctionBodyCell`
	// actually-valid JavaScript.
	//
	// A `JavaScriptFunctionBodyCell` contains code that's written as if it was wrapped in a
	// function block, e.g., it almost always should have a `return` and/or a `yield`. Neither of
	// those things are valid outside a function, so we have to actually wrap the cell code in a
	// function so that Babel can actually parse it as if it was a file.
	//
	// So, for example, if we had this code:
	//
	//     let i = 99;
	//     return i;
	//
	// We'll wrap it in a function called `SHOULD_BE_ERASED`, so that it looks like this:
	//
	//     function SHOULD_BE_ERASED() {
	//         let i = 99;
	//         return i;
	//     }
	//
	// At code emission, this wrapping function will then be removed before the code string is
	// passed to `Function(...)`.
	//
	// A few more notes:
	//
	//   * It's important that we use `function` rather than `=>` syntax because only `function` can
	//     be a generator, i.e., there is no `=>` equivalent of `function*`.
	//   * Just to be clear: `SHOULD_BE_ERASED` will *NEVER* be exposed directly to a user. It
	//     exists *ONLY* so that Babel can parse the function. It's removed at code emission.
	//

	const parseOpts: TransformOptions = {
		parserOpts: {
			allowReturnOutsideFunction: true,
			allowAwaitOutsideFunction: true,
			allowImportExportEverywhere: true,
		},
		plugins: await standardParserPlugins(),
	};

	//
	// Parse.
	//

	// NOTE: There is no way to know ahead of time what type of function to emit. Cells that contain
	// `yield` will need to be `function*` and normal cells will be plain-old `function`. Thus, we
	// parse the more common kind and if it fails "fall back" to `function*`.

	const { tree, prefixLen } = await (async () => {
		try {
			const fn = wrapInAsyncFunc(code);
			return {
				tree: await parseAsync(fn[0], parseOpts),
				prefixLen: fn[1],
			};
		} catch (e) {
			const fn = wrapInAsyncGeneratorFunc(code);
			return {
				tree: await parseAsync(fn[0], parseOpts),
				prefixLen: fn[1],
			};
		}
	})();

	//
	// Find all refs.
	//

	// NOTE: References must be *unique*. Key them by name to enforce this invariant.
	const refPaths = new Map<string, NodePath<Identifier | JSXIdentifier>>();
	const refCollector = refVisitor((refPath) => {
		refPaths.set(refPath.node.name, refPath);
	});

	const refsVisitor: Visitor = {
		...refCollector,
		enter: (path) => {
			/* eslint-disable no-param-reassign */
			if (path.node.start !== null && path.node.start !== undefined) {
				path.node.start = path.node.start = path.node.start - prefixLen;
			}

			if (path.node.end !== null && path.node.end !== undefined) {
				path.node.end = path.node.end - prefixLen;
			}

			if (path.node.loc?.start.line !== undefined) {
				path.node.loc.start.line = path.node.loc.start.line - 1;
			}

			if (path.node.loc?.end.line !== undefined) {
				path.node.loc.end.line = path.node.loc.end.line - 1;
			}
			/* eslint-enable no-param-reassign */
		},
	};
	await traverseAsync(tree, refsVisitor);

	if (isFile(tree)) {
		const fn = tree.program.body[0] as FunctionDeclaration;
		return {
			...fn,
			type: "FunctionBodyCell",
			cellName,
			body: fn.body,
			references: Array.from(refPaths).map(([_, path]) => {
				return path.node;
			}),
			referencePaths: refPaths,
		};
	}
	return null;
};

export const transform = async (root: FunctionBodyCell, code?: string) => {
	//
	// Transform to normal JS.
	//

	// NOTE: Preserve `start` and `end` by manually constructing a `Program` object.
	const program: Program = {
		type: "Program",
		body: root.body.body,
		interpreter: null,
		directives: [],
		sourceType: "module",
		leadingComments: root.leadingComments,
		innerComments: root.innerComments,
		trailingComments: root.trailingComments,
		start: root.start,
		end: root.end,
		loc: root.loc,
	};

	return await transformFromAstAsync(program, code, { plugins: await standardParserPlugins() });
};

/**
 * A parsed cell containing code written as if it was in the body of a function.
 */
export interface FunctionBodyCell extends Omit<FunctionExpression, "type" | "params"> {
	type: "FunctionBodyCell";
	cellName: string;
	references: (Identifier | JSXIdentifier)[] | null;
	referencePaths: Map<string, NodePath<Identifier | JSXIdentifier>>;
}

export const isFunctionBodyCell = (o: Parsed): o is FunctionBodyCell => {
	return isObjectWithType(o) && o.type === "FunctionBodyCell";
};

/**
 * Callbacks for the FunctionBodyCellDispatcher. Allows users to specify actions to do when the
 * FunctionBodyCellDispatcher encounters cells of various types, e.g., an import cell.
 */
export interface FunctionBodyCellDispatcherActions<T> {
	onPageImport: (
		parsed: FunctionBodyCell,
		declaration: ImportDeclaration,
		arg: ImportFromPage
	) => Promise<T> | T;
	onMomentNamespaceImport: (
		parsed: FunctionBodyCell,
		declaration: ImportDeclaration,
		arg: ImportFromMomentNamespace
	) => Promise<T> | T;
	onMomentIdImport: (
		parsed: FunctionBodyCell,
		declaration: ImportDeclaration,
		arg: ImportFromMomentId
	) => Promise<T> | T;
	onLocalImport: (
		parsed: FunctionBodyCell,
		declaration: ImportDeclaration,
		arg: ImportFromLocal
	) => Promise<T> | T;
	onFunctionBodyCell: (parsed: FunctionBodyCell, refs: string[]) => Promise<T> | T;
	onEmptyCell: (parsed: FunctionBodyCell | undefined | null) => Promise<T> | T;
	onStaticError: (staticError: Error) => Promise<T> | T;
}

/**
 * FunctionBodyCellDispatcher will parse a cell and dispatch an action to the appropriate handler
 * provided to the constructor. For example, the following code will cause an import cell to be
 * printed any time the dispatcher is passed a named cell.
 *
 *     const d = new FunctionBodyCellDispatcher({ onLocalImport: (cell) => { console.log(cell); } })
 *     d.dispatch(myCell)
 */
export class FunctionBodyCellDispatcher<T> {
	constructor(private readonly _actions: FunctionBodyCellDispatcherActions<T>) {}

	dispatch = async (cellName: string, code: string): Promise<T> => {
		const helper = async (): Promise<T> => {
			//
			// Parse cell.
			//

			const parsed = await parseCell(cellName, code);
			const refs = Array.from(new Set((parsed?.references || []).map((ref) => ref.name)));

			//
			// Dispatch to appropriate handler.
			//

			const body = parsed?.body.body[0];
			if (parsed === null || body === undefined || parsed.body.body.length === 0) {
				return this._actions.onEmptyCell(parsed);
			} else if (isImportDeclaration(body)) {
				//
				// TODO: Error on more than one import.
				// TODO: Don't allow import inside a function body.
				//

				const arg = parseImportArgument(body.source.value.toString());

				// TODO: Don't do cast.
				const imp = body as unknown as ImportDeclaration;

				if (isImportFromPage(arg)) {
					return await this._actions.onPageImport(parsed, imp, arg);
				} else if (isImportFromMomentNamespace(arg)) {
					return await this._actions.onMomentNamespaceImport(parsed, imp, arg);
				} else if (isImportFromMomentId(arg)) {
					return await this._actions.onMomentIdImport(parsed, imp, arg);
				} else if (isImportFromLocal(arg)) {
					return await this._actions.onLocalImport(parsed, imp, arg);
				} else {
					return await this._actions.onStaticError(
						new Error(`invalid argument to import`)
					);
				}
			} else if (isFunctionBodyCell(parsed)) {
				return await this._actions.onFunctionBodyCell(parsed, refs);
			} else {
				return await this._actions.onStaticError(new Error(`invalid cell`));
			}
		};

		try {
			return await helper();
		} catch (staticError) {
			return await this._actions.onStaticError(
				isError(staticError) ? staticError : new Error(`${staticError}`)
			);
		}
	};
}

const fnName = "SHOULD_BE_ERASED";

const wrapInAsyncFunc = (code: string): [string, number] => {
	const def = `async function ${fnName}() {
`;
	return [
		`${def}${code}
}`,
		def.length,
	];
};

const wrapInAsyncGeneratorFunc = (code: string): [string, number] => {
	const def = `async function* ${fnName}() {
`;
	return [
		`${def}${code}
}`,
		def.length,
	];
};
