import { useMutation } from "@tanstack/react-query";
import { useRouter } from "next/router";

import { makeToast } from "@moment/design-system/Toast";

import { useAuth } from "~/auth/useAuth";
import { trpc } from "~/utils/trpc";

export const usePushRepoMutation = () => {
	const utils = trpc.useUtils();

	const { getAccessTokenSilently } = useAuth();
	const router = useRouter();

	const { mutateAsync: push } = trpc.git.push.useMutation({
		onError: (error) => {
			makeToast({
				message: `Failed to push repo: ${error.message}`,
				variant: "error",
			});
		},
		onSettled: () => {
			void utils.git.invalidate();
		},
	});

	// Wrap the mutation so we can add the auth token
	const mutation = useMutation({
		mutationFn: async ({
			workspaceId,
			documentId,
		}: {
			workspaceId: string;
			documentId: string;
		}) => {
			if (workspaceId === "local") return;

			const token = await getAccessTokenSilently(true, router.asPath);
			if (!token) return;

			return await push({ workspaceId, documentId, authToken: token });
		},
	});

	return mutation;
};
