import { useCallback, useLayoutEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { config } from "@moment/design-system/config";

const getPxFromScreen = (key: string) => {
	const sizeString = config.theme?.screens?.[key] as string;

	if (!sizeString) {
		throw new Error(`No screen size found for key ${key}`);
	}
	const pixelWidth = parseInt(sizeString, 10);

	if (isNaN(pixelWidth)) {
		throw new Error(`Invalid screen size ${sizeString} for key ${key}`);
	}

	return pixelWidth;
};

const isTouchDevice = () =>
	typeof window !== "undefined" &&
	// @ts-ignore msMaxTouchPoints is not on the navigator type
	("ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);

export enum ScreenSize {
	SM = getPxFromScreen("sm"),
	MD = getPxFromScreen("md"),
	LG = getPxFromScreen("lg"),
	XL = getPxFromScreen("xl"),
	"2XL" = getPxFromScreen("2xl"),
	"3XL" = getPxFromScreen("3xl"),
}

export const getScreenSize = () => {
	let innerWidth = 0;

	if (typeof window !== "undefined") {
		innerWidth = window.innerWidth;
	}

	return {
		isSm: innerWidth >= ScreenSize.SM,
		isMd: innerWidth >= ScreenSize.MD,
		isLg: innerWidth >= ScreenSize.LG,
		isXl: innerWidth >= ScreenSize.XL,
		is2xl: innerWidth >= ScreenSize["2XL"],
		is3xl: innerWidth >= ScreenSize["3XL"],
		isTouchDevice: isTouchDevice(),
		innerWidth,
	};
};

export const useResponsive = () => {
	const [screenSize, setScreenSize] = useState<ReturnType<typeof getScreenSize>>(getScreenSize());
	const updateScreenSize = useCallback(() => setScreenSize(getScreenSize()), [setScreenSize]);
	const debouncedUpdateScreenSize = useDebouncedCallback(() => updateScreenSize(), 200);

	useLayoutEffect(() => {
		// Run once on mount
		updateScreenSize();

		window.addEventListener("resize", debouncedUpdateScreenSize);

		return () => window.removeEventListener("resize", debouncedUpdateScreenSize);
	}, []);

	return screenSize;
};
