/*
    See the following for reference material.
        * https://aws.amazon.com/blogs/architecture/exponential-backoff-and-jitter/
        * https://aws.amazon.com/builders-library/timeouts-retries-and-backoff-with-jitter/
    The goal is to make this work out of the box, but we need to know some characteristics at the application level to know what the retry policy should be.
    If you are not sure, I recommend reading the above guides to make a good judgement call.
*/
import { UnauthorizedError } from ".";
import { type LoggerInterface, noopLogger } from "../logger-types";

export const waitFor: (ms?: number) => void = (ms?: number) =>
	new Promise((resolve) => setTimeout(resolve, ms));

type JitterStrategy = (baseBackoff: number) => number;

type FetchFunction = (init?: RequestInit | undefined) => Promise<Response>;

// Note that any time settings are in milliseconds
export interface RetryOptions {
	backOffMs?: number;
	// pass in a function for cases where you want to do only retry on certain errors.
	doRetry?: (e: unknown) => boolean;
	exponentialOption?: {
		maxIntervalMs: number;
		multiplier: number;
		jitterStrategy?: JitterStrategy;
	};
	maxAttempts?: number;
	logger?: LoggerInterface;
	// Rather than sending the original error,
	// send this error instead.
	errorOverride?: Error;
}

export const exponentialBackoffFullJitter = (baseBackoff: number) => {
	return Math.random() * baseBackoff;
};

export const exponentialBackoffEqualJitter = (baseBackoff: number) => {
	return baseBackoff / 2 + (Math.random() * baseBackoff) / 2;
};

export const defaultRetryPolicy = (e: unknown) => {
	if (!(e instanceof Error)) {
		return true;
	}

	// Do not retry if we aborted the request or if we get a 401.
	// This will never succeed.
	// Abort error is a DOMException which is of type Error.
	if (e.name === "AbortError" || e instanceof UnauthorizedError) {
		return false;
	}

	// we probably want to add the cases for 4xx do not retry,
	// but lets add that later.
	return true;
};

export const doNotRetry = () => false;

const DEFAULTS = {
	backOffMs: 1000,
	doRetry: doNotRetry,
	maxAttempts: 3,
	logger: noopLogger,
	exponentialOption: {
		maxIntervalMs: 20000,
		multiplier: 2,
		jitterStrategy: exponentialBackoffEqualJitter,
	},
};

export class Retryable {
	private opts;
	constructor(options: RetryOptions = DEFAULTS) {
		// Note that order matters here
		this.opts = {
			...DEFAULTS,
			...options,
			exponentialOption: {
				...DEFAULTS.exponentialOption,
				...options?.exponentialOption,
			},
		};
	}

	public retryable = (fetchFn: FetchFunction): FetchFunction => {
		return async (init?: RequestInit | undefined): Promise<Response> => {
			const retry = async (
				fn: FetchFunction,
				attempts: number,
				backoff: number
			): Promise<Response> => {
				try {
					return await fetchFn(init);
				} catch (e) {
					if (attempts >= this.opts.maxAttempts || !this.opts.doRetry(e)) {
						this.opts.logger.error("Max attempts reached", { error: e });
						throw this.opts.errorOverride ? this.opts.errorOverride : e;
					}

					await waitFor(this.opts.exponentialOption.jitterStrategy(backoff));
					const nextBackoff = Math.min(
						backoff * this.opts.exponentialOption.multiplier,
						this.opts.exponentialOption.maxIntervalMs
					);
					return retry(fn, attempts + 1, nextBackoff);
				}
			};
			return retry(fetchFn, 1, this.opts.backOffMs);
		};
	};
}
