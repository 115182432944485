import { useMutation, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";

import { useLogger } from "~/components/canvas/hooks/useLogger";
import { toGQL as canvasListingToGQL } from "~/models/CanvasListingItem";

import { type Workspace, workspaceQueryKeys } from ".";

export const useUpdateWorkspaceMutation = () => {
	const logger = useLogger("useUpdateWorkspaceMutation");

	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationKey: workspaceQueryKeys.updateWorkspace,
		mutationFn: async (args: { workspaceId: string; workspace: Workspace }) => {
			const workspace = {
				...args.workspace,
				documents: Object.fromEntries(
					Object.entries(args.workspace.documents).map(([key, value]) => {
						return [key, canvasListingToGQL(value)];
					})
				),
			};

			await window.desktopIpcApi?.writeLocalWorkspaceMetadata(
				args.workspaceId,
				workspace as unknown as Workspace
			);

			return workspace;
		},
	});

	return {
		...mutation,
		updateAsync: (
			args: { workspaceId: string },
			updaterFn: (workspace: Workspace | undefined) => Workspace | undefined
		) => {
			const workspace = queryClient.setQueryData<Workspace>(
				workspaceQueryKeys.workspace("local", args.workspaceId),
				(draft) => produce(draft, updaterFn)
			);

			if (!workspace) {
				logger.error("Workspace not found");
				return;
			}

			// Invalidate the remote workspace query
			void queryClient.invalidateQueries({
				queryKey: workspaceQueryKeys.workspace("remote", args.workspaceId),
			});

			return mutation.mutateAsync({
				...args,
				workspace,
			});
		},
	};
};
