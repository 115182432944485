import { Fragment } from "prosemirror-model";
import { type Command, Selection } from "prosemirror-state";

import { schema } from "@moment/api-collab/prosemirror-schema";

import { singleComputeCellSelected } from "../helpers/singleComputeCellSelected";

export const handleArrowUpInComputeCell: Command = (state, dispatch) => {
	// Arrow key should work normally if not in a `compute_cell`.
	const { selection } = state;

	const selectionInCodeBlock =
		selection.empty && selection.$from.node().type === schema.nodes.code_block;
	if (!singleComputeCellSelected(selection) && !selectionInCodeBlock) {
		return false;
	}

	// If the compute cell is not at the beginning of the document,
	// proceed normally
	if (selection.$from.nodeBefore) {
		return false;
	}

	if (!dispatch) {
		return true;
	}

	const tr = state.tr;
	const emptyParagraph = Fragment.from(schema.nodes.paragraph.create());

	tr.insert(selection.from, emptyParagraph);
	const newSelection = Selection.findFrom(
		tr.doc.resolve(tr.mapping.map(selection.from)),
		-1,
		true
	);
	if (newSelection) {
		tr.setSelection(newSelection);
	}

	dispatch(tr.scrollIntoView());
	return true;
};
