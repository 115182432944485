import { type InputRule, wrappingInputRule } from "prosemirror-inputrules";
import { type NodeType } from "prosemirror-model";

import { createCellID } from "@moment/api-collab/prosemirror-utils";

// Given a callout node type, returns an input rule that turns `">> "` at the start of a textblock
// into a callout.
export const calloutRule = (nodeType: NodeType): InputRule => {
	return wrappingInputRule(/^\s*>>\s$/, nodeType, () => ({
		id: createCellID(),
	}));
};
