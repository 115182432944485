import { isEmpty } from "lodash";
import { z } from "zod";

import { type CanvasListingItem as CanvasListingItemGQL } from "~/api/generated/graphql";

import { DEFAULT_CANVAS_TITLE } from "../strings";

export const CanvasListingItemSchema = z.object({
	id: z.string(),
	ownerID: z.string(),
	organizationID: z.string(),
	createdAt: z.coerce.date(),
	updatedAt: z.coerce.date(),
	title: z.string(),
	pinnedAt: z.coerce.date().nullish(),
	isTemplate: z.boolean().nullish(),
});

export type CanvasListingItem = z.infer<typeof CanvasListingItemSchema>;

export const fromGQL = (c: CanvasListingItemGQL): CanvasListingItem => {
	let pinnedAt: Date | undefined = undefined;

	if (c.pinnedAt) {
		pinnedAt = new Date(c.pinnedAt);
	}

	let title = c.title;
	if (isEmpty(title)) {
		title = DEFAULT_CANVAS_TITLE;
	}

	return {
		id: c.id,
		ownerID: c.ownerID,
		organizationID: c.organizationID,
		title,
		createdAt: new Date(c.createdAt),
		updatedAt: new Date(c.updatedAt),
		pinnedAt,
		isTemplate: false,
	};
};

export const toGQL = (c: CanvasListingItem): CanvasListingItemGQL => {
	return {
		...c,
		createdAt: c.createdAt.toISOString(),
		updatedAt: c.updatedAt.toISOString(),
		pinnedAt: c.pinnedAt?.toISOString() ?? "",
		version: 0,
	};
};
