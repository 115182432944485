import { type InputRule } from "prosemirror-inputrules";
import { type MarkType } from "prosemirror-model";

import { wrappingMarkRule } from "./wrappingMarkRule";

// Given an "strike" markType returns an input rule that turns `"~~text~~"` in a text block
// into an <s></s> tag.
export const strikeTextRule = (markType: MarkType): InputRule => {
	return wrappingMarkRule(/~~(.+)~~/, markType, {});
};
