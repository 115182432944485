import ClientTokenProduction from "./data/datadog-env-vars.web.production.json";
import ClientTokenStaging from "./data/datadog-env-vars.web.staging.json";

export const clientToken = (): string => {
	if (process.env["APP_ENV"] === "production") {
		return ClientTokenProduction.clientToken;
	}

	return ClientTokenStaging.clientToken;
};
