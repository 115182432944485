// import * as jsx from "@babel/plugin-transform-react-jsx";
// import * as ts from "@babel/plugin-transform-typescript";

const _jsx = import("@babel/plugin-transform-react-jsx").then((m) => m.default);
const _ts = import("@babel/plugin-transform-typescript").then((m) => m.default);

export const standardParserPlugins = async () => {
	const [jsx, ts] = await Promise.all([_jsx, _ts]);
	return [jsx, [ts, { isTSX: true }]];
};
