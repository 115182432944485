import cx from "classnames";
import { type FC, type HTMLAttributes, type ReactNode } from "react";

/**
 * <RowContent/>
 */

export type RowContentProps = {
	cellID?: string;
	children?: ReactNode;
	rootProps?: HTMLAttributes<HTMLDivElement>;
};

export const RowContent: FC<RowContentProps> = ({ cellID, rootProps, children }) => {
	const { className: rootStyles, ...rootPropsRest } = rootProps || {};
	// padding right to account for the component toolbar on the right
	// padding left to account for the `CellControl`s on the left
	const styles = ["canvas-content-row", { "h-full": false }, rootStyles];

	return (
		<div
			className={cx(styles)}
			// needed for prosemirror to insert content
			data-row-content
			// used by prosemirror to identify the cellID that row hold content for
			data-cell-contents={cellID}
			{...rootPropsRest}
		>
			{children}
		</div>
	);
};
