import type { BabelFileResult, Node, NodePath, ParseResult, TransformOptions } from "@babel/core";
import { type Scope, type TraverseOptions } from "@babel/traverse";

//
// A bundle-size-friendly deferred-loading Babel wrapper. Babel is loaded when the page is imported,
// Babel is NOT bundled into the main bundle, saving x00ms on parse time alone. API is async-only
// because loading is deferred.
//
// Babel is an enormous package unfit for loading in the main bundle. It costs 1.1MB unzipped, which
// means it takes x00ms for the browser to parse the code after receiving it, and even more time to
// download it and run it. It is un-tree-shakable, it is slow, and it is huge.
//
// This package exists so that we can load Babel immediately after the _app page is loaded, so that
// it's ready by the time we receive the data of the canvas to execute.
//

const babel = import("@babel/core");

export const parseAsync = async (
	code: string,
	options?: TransformOptions
): Promise<ParseResult | null> => {
	return (await babel).parseAsync(code, options);
};

export const traverseAsync = async (
	parent: Node | null,
	opts?: TraverseOptions<Node>,
	scope?: Scope | undefined,
	state?: Node,
	parentPath?: NodePath
): Promise<void> => {
	return (await babel).traverse(parent as Node, opts, scope, state, parentPath);
};

export const makeTraverseSync = async () => {
	return (await babel).traverse;
};

export const transformFromAstAsync = async (
	ast: Node,
	code?: string,
	opts?: TransformOptions
): Promise<BabelFileResult | null> => {
	return (await babel).transformFromAstAsync(ast, code, opts);
};
