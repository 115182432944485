import { useMutation, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";

import type { CanvasMetadata } from "@moment/api-collab/api-types";

import type { CanvasMetadata as CanvasMetadataGQL } from "~/api/generated/graphql";
import { useLogger } from "~/components/canvas/hooks/useLogger";

import { documentQueryKeys } from ".";

export const useUpdateDocumentMutation = () => {
	const logger = useLogger("useUpdateDocumentMutation");

	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationKey: documentQueryKeys.updateDocument,
		mutationFn: async (args: { workspaceId: string; document: CanvasMetadata }) => {
			const { workspaceId, document } = args;

			await window.desktopIpcApi?.writeLocalDocumentMetadata(
				workspaceId,
				document.id,
				document as CanvasMetadataGQL
			);
		},
	});

	return {
		...mutation,
		updateAsync: (
			args: { workspaceId: string; documentId: string },
			updaterFn: (document: CanvasMetadata | undefined) => CanvasMetadata | undefined
		) => {
			const document = queryClient.setQueryData<CanvasMetadata>(
				documentQueryKeys.document(args.workspaceId, args.documentId),
				(draft) => produce(draft, updaterFn)
			);

			if (!document) {
				logger.error("Document not found");
				return;
			}

			return mutation.mutateAsync({
				...args,
				document,
			});
		},
	};
};
