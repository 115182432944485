import { Plugin, PluginKey } from "prosemirror-state";

import { DocumentPlaceholderView } from "./DocumentPlaceholderView";

export const documentPlaceholderName = "document_placeholder";

/**
 * documentPlaceholderPluginKey
 * @description reference to documentPlaceholderPlugin
 */
export const documentPlaceholderPluginKey = new PluginKey("document-placeholder-plugin");

/**
 * documentPlaceholderPlugin
 * @description binds placeholder node view to editor state. This is shown when a canvas/page is created and the document is empty
 */
export const documentPlaceholderPlugin = () => {
	const p = new Plugin({
		key: documentPlaceholderPluginKey,
		state: {
			init() {
				return {};
			},
			apply(_transaction, value, _prevState, _nextState) {
				return value;
			},
		},
		props: {
			nodeViews: {
				[documentPlaceholderName]: (node) => {
					return new DocumentPlaceholderView(node);
				},
			},
		},
	});

	return p;
};
