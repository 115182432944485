import { useMutation } from "@tanstack/react-query";

import { useLogger } from "~/components/canvas/hooks/useLogger";

import { useUpdateWorkspaceMutation } from "../workspaces";

export const useToggleDocumentPinnedMutation = () => {
	const logger = useLogger("useRenameDocumentMutation");

	const { updateAsync: updateWorkspace } = useUpdateWorkspaceMutation();

	return useMutation({
		mutationFn: async (args: { workspaceId: string; documentId: string; pinned: boolean }) => {
			const { workspaceId, documentId, pinned } = args;

			return await updateWorkspace({ workspaceId }, (workspace) => {
				if (!workspace) {
					logger.error("Workspace not found");
					return;
				}

				const document = workspace.documents[documentId];
				if (!document) {
					logger.error("Document not found");
					return;
				}

				document.pinnedAt = pinned ? new Date() : undefined;
				return workspace;
			});
		},
	});
};
