import { type EditorView } from "prosemirror-view";

import { getTargetNode } from "./getTargetNode";

export type DropDestination =
	| { target: "top" }
	| { target: "after"; targetCellID: string }
	| { target: "bottom" };

export const getDropDestination = (
	view: EditorView,
	event: DragEvent | React.DragEvent,
	fromEditorBottom = false
): DropDestination | undefined => {
	if (fromEditorBottom) {
		return { target: "bottom" };
	}

	const dropPos = view.posAtCoords({ left: event.clientX, top: event.clientY });
	if (dropPos === null) {
		return undefined;
	}

	const $cursor = view.state.doc.resolve(dropPos.pos);
	const targetNode = getTargetNode($cursor);

	if (targetNode === undefined) {
		return { target: "top" };
	}

	const targetCellID = targetNode.attrs["id"];

	if (typeof targetCellID !== "string") {
		return undefined;
	}

	return { target: "after", targetCellID };
};
