import { Fragment, Node } from "prosemirror-model";
import { NodeSelection, Selection } from "prosemirror-state";

// ⚠️ WARNING
// DO NOT ADD ANYTHING TO THIS DOCUMENT
// DO NOT USE THIS FILE DIRECTLY
// THIS WAS INTRODUCED SO THAT WE CAN REMOVE `prosemirror-utils` module

// LIFTED FROM https://github.com/atlassian/prosemirror-utils/blob/1b97ff08f1bbaea781f205744588a3dfd228b0d1/src/transforms.js#L145

// (nodeType: union<NodeType, [NodeType]>) → boolean
// Checks if the type a given `node` equals to a given `nodeType`.
export const equalNodeType = (nodeType, node) => {
	return (Array.isArray(nodeType) && nodeType.indexOf(node.type) > -1) || node.type === nodeType;
};

// :: ($pos: ResolvedPos, content: union<ProseMirrorNode, Fragment>) → boolean
// Checks if a given `content` can be inserted at the given `$pos`
//
// ```javascript
// const { selection: { $from } } = state;
// const node = state.schema.nodes.atom.createChecked();
// if (canInsert($from, node)) {
//   // ...
// }
// ```
export const canInsert = ($pos, content) => {
	const index = $pos.index();

	if (content instanceof Fragment) {
		return $pos.parent.canReplace(index, index, content);
	} else if (content instanceof Node) {
		return $pos.parent.canReplaceWith(index, index, content.type);
	}
	return false;
};

// :: ($pos: ResolvedPos, predicate: (node: ProseMirrorNode) → boolean) → ?{pos: number, start: number, depth: number, node: ProseMirrorNode}
// Iterates over parent nodes starting from the given `$pos`, returning the closest node and its start position `predicate` returns truthy for. `start` points to the start position of the node, `pos` points directly before the node.
//
// ```javascript
// const predicate = node => node.type === schema.nodes.blockquote;
// const parent = findParentNodeClosestToPos(state.doc.resolve(5), predicate);
// ```
export const findParentNodeClosestToPos = ($pos, predicate) => {
	for (let i = $pos.depth; i > 0; i--) {
		const node = $pos.node(i);
		if (predicate(node)) {
			return {
				pos: i > 0 ? $pos.before(i) : 0,
				start: $pos.start(i),
				depth: i,
				node,
			};
		}
	}
};

// :: (predicate: (node: ProseMirrorNode) → boolean) → (selection: Selection) → ?{pos: number, start: number, depth: number, node: ProseMirrorNode}
// Iterates over parent nodes, returning the closest node and its start position `predicate` returns truthy for. `start` points to the start position of the node, `pos` points directly before the node.
//
// ```javascript
// const predicate = node => node.type === schema.nodes.blockquote;
// const parent = findParentNode(predicate)(selection);
// ```
export const findParentNode =
	(predicate) =>
	({ $from }) =>
		findParentNodeClosestToPos($from, predicate);

// :: (nodeType: union<NodeType, [NodeType]>) → (selection: Selection) → ?{pos: number, start: number, depth: number, node: ProseMirrorNode}
// Iterates over parent nodes, returning closest node of a given `nodeType`. `start` points to the start position of the node, `pos` points directly before the node.
//
// ```javascript
// const parent = findParentNodeOfType(schema.nodes.paragraph)(selection);
// ```
export const findParentNodeOfType = (nodeType) => (selection) => {
	return findParentNode((node) => equalNodeType(nodeType, node))(selection);
};

// ($pos: ResolvedPos, doc: ProseMirrorNode, content: union<ProseMirrorNode, Fragment>, ) → boolean
// Checks if replacing a node at a given `$pos` inside of the `doc` node with the given `content` is possible.
export const canReplace = ($pos, content) => {
	const node = $pos.node($pos.depth);
	return (
		node &&
		node.type.validContent(content instanceof Fragment ? content : Fragment.from(content))
	);
};

// (position: number, content: union<ProseMirrorNode, Fragment>) → (tr: Transaction) → Transaction
// Returns a `replace` transaction that replaces a node at a given position with the given `content`.
// It will return the original transaction if replacing is not possible.
// `position` should point at the position immediately before the node.
export const replaceNodeAtPos = (position, content) => (tr) => {
	const node = tr.doc.nodeAt(position);
	const $pos = tr.doc.resolve(position);
	if (canReplace($pos, content)) {
		tr = tr.replaceWith(position, position + node.nodeSize, content);
		const start = tr.selection.$from.pos - 1;
		// put cursor inside of the inserted node
		tr = setTextSelection(Math.max(start, 0), -1)(tr);
		// move cursor to the start of the node
		tr = setTextSelection(tr.selection.$from.start())(tr);
		return cloneTr(tr);
	}
	return tr;
};

// :: (nodeType: union<NodeType, [NodeType]>, content: union<ProseMirrorNode, Fragment>) → (tr: Transaction) → Transaction
// Returns a new transaction that replaces parent node of a given `nodeType` with the given `content`. It will return an original transaction if either parent node hasn't been found or replacing is not possible.
//
// ```javascript
// const node = schema.nodes.paragraph.createChecked({}, schema.text('new'));
//
// dispatch(
//  replaceParentNodeOfType(schema.nodes.table, node)(tr)
// );
// ```
export const replaceParentNodeOfType = (nodeType, content) => (tr) => {
	if (!Array.isArray(nodeType)) {
		nodeType = [nodeType];
	}
	for (let i = 0, count = nodeType.length; i < count; i++) {
		const parent = findParentNodeOfType(nodeType[i])(tr.selection);
		if (parent) {
			const newTr = replaceNodeAtPos(parent.pos, content)(tr);
			if (newTr !== tr) {
				return newTr;
			}
		}
	}
	return tr;
};

// (node: ProseMirrorNode) → boolean
// Checks if a given `node` is an empty paragraph
export const isEmptyParagraph = (node) => {
	return !node || (node.type.name === "paragraph" && node.nodeSize === 2);
};

// (tr: Transaction) → Transaction
// Creates a new transaction object from a given transaction
export const cloneTr = (tr) => {
	return Object.assign(Object.create(tr), tr).setTime(Date.now());
};

const isSelectableNode = (node) => node.type && node.type.spec.selectable;
const shouldSelectNode = (node) => isSelectableNode(node) && node.type.isLeaf;

// :: (position: number, dir: ?number) → (tr: Transaction) → Transaction
// Returns a new transaction that tries to find a valid cursor selection starting at the given `position`
// and searching back if `dir` is negative, and forward if positive.
// If a valid cursor position hasn't been found, it will return the original transaction.
//
// ```javascript
// dispatch(
//   setTextSelection(5)(tr)
// );
// ```
export const setTextSelection =
	(position, dir = 1) =>
	(tr) => {
		const nextSelection = Selection.findFrom(tr.doc.resolve(position), dir, true);
		if (nextSelection) {
			return tr.setSelection(nextSelection);
		}
		return tr;
	};

const setSelection = (node, pos, tr) => {
	if (shouldSelectNode(node)) {
		return tr.setSelection(new NodeSelection(tr.doc.resolve(pos)));
	}
	return setTextSelection(pos)(tr);
};

// :: (content: union<ProseMirrorNode, ProseMirrorFragment>) → (tr: Transaction) → Transaction
// Returns a new transaction that replaces selected node with a given `node`, keeping NodeSelection on the new `node`.
// It will return the original transaction if either current selection is not a NodeSelection or replacing is not possible.
//
// ```javascript
// const node = schema.nodes.paragraph.createChecked({}, schema.text('new'));
// dispatch(
//   replaceSelectedNode(node)(tr)
// );
// ```
export const replaceSelectedNode = (content) => (tr) => {
	if (isNodeSelection(tr.selection)) {
		const { $from, $to } = tr.selection;
		if (
			(content instanceof Fragment &&
				$from.parent.canReplace($from.index(), $from.indexAfter(), content)) ||
			$from.parent.canReplaceWith($from.index(), $from.indexAfter(), content.type)
		) {
			return cloneTr(
				tr
					.replaceWith($from.pos, $to.pos, content)
					// restore node selection
					.setSelection(new NodeSelection(tr.doc.resolve($from.pos)))
			);
		}
	}
	return tr;
};

// :: (selection: Selection) → boolean
// Checks if current selection is a `NodeSelection`.
//
// ```javascript
// if (isNodeSelection(tr.selection)) {
//   // ...
// }
// ```
export const isNodeSelection = (selection) => {
	return selection instanceof NodeSelection;
};

// :: (content: union<ProseMirrorNode, Fragment>, position: ?number, tryToReplace?: boolean) → (tr: Transaction) → Transaction
// Returns a new transaction that inserts a given `content` at the current cursor position, or at a given `position`, if it is allowed by schema. If schema restricts such nesting, it will try to find an appropriate place for a given node in the document, looping through parent nodes up until the root document node.
// If `tryToReplace` is true and current selection is a NodeSelection, it will replace selected node with inserted content if its allowed by schema.
// If cursor is inside of an empty paragraph, it will try to replace that paragraph with the given content. If insertion is successful and inserted node has content, it will set cursor inside of that content.
// It will return an original transaction if the place for insertion hasn't been found.
//
// ```javascript
// const node = schema.nodes.extension.createChecked({});
// dispatch(
//   safeInsert(node)(tr)
// );
// ```
export const safeInsert = (content, position?: number, tryToReplace?: boolean) => (tr) => {
	const hasPosition = typeof position === "number";
	const { $from } = tr.selection;
	let $insertPos = $from;

	if (hasPosition) {
		$insertPos = tr.doc.resolve(position);
	} else if (isNodeSelection(tr.selection)) {
		$insertPos = tr.doc.resolve($from.pos + 1);
	}

	const { parent } = $insertPos;

	// try to replace selected node
	if (isNodeSelection(tr.selection) && tryToReplace) {
		const oldTr = tr;
		tr = replaceSelectedNode(content)(tr);
		if (oldTr !== tr) {
			return tr;
		}
	}

	// try to replace an empty paragraph
	if (isEmptyParagraph(parent)) {
		const oldTr = tr;
		tr = replaceParentNodeOfType(parent.type, content)(tr);
		if (oldTr !== tr) {
			const pos = isSelectableNode(content)
				? // for selectable node, selection position would be the position of the replaced parent
					$insertPos.before($insertPos.depth)
				: $insertPos.pos;
			return setSelection(content, pos, tr);
		}
	}

	// given node is allowed at the current cursor position
	if (canInsert($insertPos, content)) {
		tr.insert($insertPos.pos, content);
		let pos;
		if (hasPosition) {
			pos = $insertPos.pos;
		} else if (isSelectableNode(content)) {
			// for atom nodes selection position after insertion is the previous pos
			pos = tr.selection.$anchor.pos - 1;
		} else {
			pos = tr.selection.$anchor.pos;
		}

		return cloneTr(setSelection(content, pos, tr));
	}

	// looking for a place in the doc where the node is allowed
	for (let i = $insertPos.depth; i > 0; i--) {
		const pos = $insertPos.after(i);
		const $pos = tr.doc.resolve(pos);
		if (canInsert($pos, content)) {
			tr.insert(pos, content);
			return cloneTr(setSelection(content, pos, tr));
		}
	}
	return tr;
};
