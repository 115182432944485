import { useQuery } from "@tanstack/react-query";
import { merge } from "lodash";
import { useNetworkState } from "react-use";

import type { CanvasListingItem as CanvasListingItemGQL } from "~/api/generated/graphql";
import { isDesktopAppMode } from "~/auth/interop/common";
import { useAuth } from "~/auth/useAuth";
import { useLogger } from "~/components/canvas/hooks/useLogger";
import type { Workspace } from "~/data/workspaces";
import { fromGQL as canvasListingFromGQL } from "~/models/CanvasListingItem";
import { queryReposByWorkspace } from "~/utils/gitea";

import { WorkspaceSchema, workspaceQueryKeys } from ".";
import { useRouteParams } from "../route";
import { LOCAL_WORKSPACE_ID, LOCAL_WORKSPACE_NAME } from "./constants";
import { getTitle } from "./repos";

export const useWorkspaceQuery = (args: { workspaceId: string }) => {
	const routeParams = useRouteParams();

	const { workspaceId = routeParams.workspaceId } = args;

	const logger = useLogger("useWorkspaceQuery");

	const { isAuthenticated, getAccessTokenSilently } = useAuth();
	const { online } = useNetworkState();

	const remoteWorkspaceQuery = useQuery({
		queryKey: workspaceQueryKeys.workspace("remote", workspaceId),
		queryFn: async (): Promise<Workspace> => {
			try {
				const token = await getAccessTokenSilently(true, routeParams.path);

				// If there is no token, we should not be able to fetch remote workspaces
				if (!token) {
					return {
						id: workspaceId,
						name: workspaceId,
						documents: {},
						recentlyViewed: [],
					};
				}

				const repos = await queryReposByWorkspace(token, workspaceId);
				return WorkspaceSchema.parse({
					id: workspaceId,
					name: workspaceId,
					documents: Object.fromEntries(
						Object.values(repos).map((repo) => {
							return [
								repo.name,
								{
									id: repo.name ?? "",
									title: getTitle(repo),
									createdAt: new Date(repo.created_at ?? 0),
									updatedAt: new Date(repo.updated_at ?? 0),
									organizationID: workspaceId,
									ownerID: "",
									version: 0,
									pinnedAt: undefined,
								},
							];
						})
					),
					recentlyViewed: [],
				});
			} catch {
				return WorkspaceSchema.parse({
					id: workspaceId,
					name: workspaceId,
					documents: {},
					recentlyViewed: [],
				});
			}
		},
		enabled: isAuthenticated && online && workspaceId !== LOCAL_WORKSPACE_ID,
	});

	const localWorkspaceQuery = useQuery({
		queryKey: workspaceQueryKeys.workspace(LOCAL_WORKSPACE_ID, workspaceId),
		queryFn: async (): Promise<Workspace | null> => {
			const ipcWorkspace = await window.desktopIpcApi?.fetchLocalWorkspace(workspaceId);
			if (!ipcWorkspace) {
				logger.error("Failed to fetch local workspace", args);
				throw new Error(`Failed to fetch local workspace: ${workspaceId}`);
			}

			return WorkspaceSchema.parse({
				...ipcWorkspace,
				id: LOCAL_WORKSPACE_ID,
				name: LOCAL_WORKSPACE_NAME,
				documents: Object.fromEntries(
					Object.values(ipcWorkspace.documents).map((value) => {
						return [
							value.id,
							canvasListingFromGQL(value as unknown as CanvasListingItemGQL),
						];
					})
				),
			});
		},
		enabled: isDesktopAppMode(),
	});

	return {
		data: merge(remoteWorkspaceQuery.data, localWorkspaceQuery.data),
		isFetching: localWorkspaceQuery.isFetching && remoteWorkspaceQuery.isFetching,
		isSuccess: localWorkspaceQuery.isSuccess && remoteWorkspaceQuery.isSuccess,
		isLoading: localWorkspaceQuery.isLoading || remoteWorkspaceQuery.isLoading,
		isError: localWorkspaceQuery.isError || remoteWorkspaceQuery.isError,
		refetch: () => {
			void localWorkspaceQuery.refetch();
			void remoteWorkspaceQuery.refetch();
		},
	};
};
