import { type FC, type HTMLAttributes, type ReactNode } from "react";

import { RowContent } from "./RowContent";
import { type RowWidth } from "./RowTypes";

/**
 * <Row/>
 * A canvas comprises rows of content.
 * For example, a row could contain a text cell, computed cell, or editing interfaces
 * like the canvas title editor.
 *
 * This ensures consistent visual spacing for content.
 */

export type RowProps = {
	cellID?: string;
	width?: (typeof RowWidth)[number];
	children?: ReactNode;
	rootProps?: {
		[k in "content"]?: HTMLAttributes<HTMLDivElement>;
	};
};

export const Row: FC<RowProps> = ({ cellID, children, rootProps }) => {
	return (
		<RowContent cellID={cellID} rootProps={rootProps?.content || {}}>
			{children}
		</RowContent>
	);
};
