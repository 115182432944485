import { combineReducers } from "redux";

import { canvas } from "./canvas/slice";
import { cells } from "./cells/slice";
import { client } from "./client/slice";
import { codeEditor } from "./code-editor/slice";
import { desktopAppAPIs } from "./desktop-app/api";
import { desktopApp } from "./desktop-app/slice";
import { editors } from "./editors/slice";
import { onboardingState } from "./onboarding/slice";
import { organizations } from "./organizations/slice";
import { pageStorageSlice } from "./pageStorage/pageStorageSlice";
import { permissions } from "./permissions/slice";
import { recentlyViewed } from "./recently-viewed/slice";
import { reducer as roles } from "./roles/slice";
import { users } from "./users/slice";

export const reducer = combineReducers({
	canvas,
	cells,
	client,
	codeEditor,
	desktopApp,
	editors,
	onboardingState,
	organizations,
	[pageStorageSlice.reducerPath]: pageStorageSlice.reducer,
	permissions,
	recentlyViewed,
	roles,
	users,
	[desktopAppAPIs.reducerPath]: desktopAppAPIs.reducer,
});
