export enum Flags {
	CanvasLayoutVisualDebug = "CANVAS_LAYOUT_VISUAL_DEBUGGING",
	CanvasPreviewAs = "PREVIEW_AS",
	CollabServiceConnectionCalloutBanner = "COLLAB_SERVICE_CONNECTION_CALLOUT_BANNER",
	ForceDisableCollabEditing = "FORCE_DISABLE_COLLAB_EDITING",
	OnboardingDevTools = "ONBOARDING_DEV_TOOLS",
	OpenSearch = "OPENSEARCH",
	SettingsAtlasForceSetup = "FORCE_ATLAS_SETUP",
	TestFlagFalse = "TEST_FLAG_FALSE",
	TestFlagTrue = "TEST_FLAG_TRUE",
}

export type FeatureFlag = {
	[key in Flags]: boolean;
};
