import { type FC, type PropsWithChildren, useMemo } from "react";

import { ForceTheme } from "./ForceTheme";
import { ThemeProvider as NextThemesProvider, useTheme as nextUseTheme } from "./next-themes";
import { THEME_CLASS_MAP, type Theme, Themes } from "./types";

export * from "./ForceTheme";
export * from "./types";

export type ThemeProviderProps = {
	forcedTheme?: Theme;
	noInsertScript?: boolean;
};

/*
	ThemeProvider

	manages dark mode / contrast

    Child components can access the `useTheme` hook

	TODO: Save theme to cookies so it can be loaded on next visit

	```
	import {useTheme} from 'design-system/ThemeProvider';

	const {theme, setTheme, resolvedTheme, systemTheme, themes } = useTheme()
	```
*/

// Temporary fix until https://github.com/pacocoursey/next-themes/issues/252 is resolved
// If the above issue is resolved, replace with the following if the resolvedTheme reflects the forcedTheme:
// export const useTheme = nextUseTheme;
export const useTheme = () => {
	const nextTheme = nextUseTheme();

	const resolvedTheme = useMemo(() => {
		// Prefer forcedTheme
		if (nextTheme.forcedTheme !== undefined) {
			return nextTheme.forcedTheme;
		}

		// Attempt to resolve the theme
		if (nextTheme.resolvedTheme !== undefined) {
			return nextTheme.resolvedTheme;
		}

		if (typeof window === "undefined") {
			return "light";
		}

		// Fallback to system theme
		if (window.matchMedia?.("(prefers-color-scheme: dark)").matches) {
			return "dark";
		}

		if (window.matchMedia?.("(prefers-color-scheme: light)").matches) {
			return "light";
		}

		// This should not happen, error will be logged in the console
		console.error("resolvedTheme is undefined. useTheme should be used within a ThemeProvider");
		return "light";
	}, [nextTheme.forcedTheme, nextTheme.resolvedTheme]);

	return {
		...nextTheme,
		resolvedTheme,
	};
};

/**
 * ThemeProvider
 *
 * @param children The children to wrap with the ThemeProvider
 * @param forcedTheme The theme to force
 * @param noInsertScript Whether or not to insert the script tag to handle body class changes.
 *                       This is useful for nested ThemeProviders or when rendering in child React Contexts.
 */
export const ThemeProvider: FC<PropsWithChildren<ThemeProviderProps>> = ({
	children,
	forcedTheme,
	noInsertScript,
}) => {
	const content =
		forcedTheme !== undefined ? (
			<ForceTheme theme={forcedTheme}>{children}</ForceTheme>
		) : (
			children
		);
	return (
		<NextThemesProvider
			attribute="class"
			defaultTheme="system"
			storageKey="moment:theme"
			enableSystem
			enableColorScheme={false}
			// Array copy is required as Themes is a readonly string[] and NextThemesProvider expects a string[]
			themes={[...Themes]}
			disableTransitionOnChange
			value={THEME_CLASS_MAP}
			forcedTheme={forcedTheme}
			noInsertScript={noInsertScript}
		>
			{content}
		</NextThemesProvider>
	);
};
