import { Fragment, Slice } from "prosemirror-model";
import { type Command } from "prosemirror-state";

import { schema } from "@moment/api-collab/prosemirror-schema";

/**
 * Prevent invalid deletes, and subsequently replace them with a relevant valid operation. For
 * example, if the document contains only a single compute cell, we would want to prevent that
 * delete, and instead simply replace the document with an empty paragraph.
 */
export const replaceInvalidDeletes: Command = (state, dispatch) => {
	const sel = state.selection;
	const anchorNode = sel.$anchor.node();
	const headNode = sel.$head.node();

	//
	// CASE: Entire document is a single compute cell.
	//

	const entireDocumentIsSingleComputeCell =
		state.doc.childCount === 1 &&
		anchorNode === headNode &&
		anchorNode.type === schema.nodes.compute_cell;
	if (entireDocumentIsSingleComputeCell) {
		const contentSize = state.tr.doc.content.size;
		const emptyParagraph = new Slice(Fragment.from(schema.nodes.paragraph.create()), 0, 0);

		// NOTE: Remove stored marks because otherwise they will remain in the document.
		dispatch?.(state.tr.setStoredMarks([]).replace(0, contentSize, emptyParagraph));
		return true;
	}

	return false;
};
