import { type FunctionBodyCell } from "~/utils/function-body-cell";

import { AstPattern } from "../ast-pattern";
import { identifierPattern, memberExpressionPattern } from "../ast-pattern/patterns";

export const getServiceDetailFromUrlRoutePattern = AstPattern.anywhere({
	type: "CallExpression",
	callee: memberExpressionPattern(
		memberExpressionPattern(identifierPattern("Moment"), identifierPattern("serviceDetails")),
		identifierPattern("fromUrlRoute")
	),
	arguments: [],
});

/**
 * Matches function calls of the form `Moment.serviceDetails.fromUrlRoute()`.
 */
export const matchGetServiceDetailFromUrlRouteCallPattern = async (
	cell: FunctionBodyCell | null
): Promise<unknown[]> => {
	// return if cell body is undefined
	if (!cell?.body) {
		return [];
	}

	return await AstPattern.match(getServiceDetailFromUrlRoutePattern, cell.body);
};
