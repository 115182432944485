import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useLogger } from "~/components/canvas/hooks/useLogger";

import { documentQueryKeys, useUpdateDocumentMutation } from ".";
import { useUpdateWorkspaceMutation, workspaceQueryKeys } from "../workspaces";
import { LOCAL_WORKSPACE_ID } from "../workspaces/constants";

export const useRenameDocumentMutation = () => {
	const logger = useLogger("useRenameDocumentMutation");

	const queryClient = useQueryClient();

	const { updateAsync: updateDocument } = useUpdateDocumentMutation();
	const { updateAsync: updateWorkspace } = useUpdateWorkspaceMutation();

	return useMutation({
		mutationKey: documentQueryKeys.renameDocument,
		mutationFn: async (args: { workspaceId: string; documentId: string; title: string }) => {
			const { workspaceId, documentId, title } = args;

			return await Promise.all([
				// Update the document
				updateDocument({ workspaceId, documentId }, (document) => {
					if (!document) {
						logger.error("Document not found");
						return;
					}

					document.title = title;
					return document;
				}),

				// Update the workspace
				updateWorkspace({ workspaceId }, (workspace) => {
					if (!workspace) {
						logger.error("Workspace not found");
						return;
					}

					const document = workspace.documents[documentId];
					if (!document) {
						logger.error("Workspace document not found");
						return;
					}

					document.title = title;
					return workspace;
				}),
			]);
		},
		async onSuccess(_data, variables) {
			const location = variables.workspaceId === LOCAL_WORKSPACE_ID ? "local" : "remote";
			await queryClient.invalidateQueries({
				queryKey: workspaceQueryKeys.workspaces(location, false),
			});
			await queryClient.invalidateQueries({
				queryKey: workspaceQueryKeys.workspaces(location, true),
			});
			await queryClient.invalidateQueries({
				queryKey: workspaceQueryKeys.workspace(location, variables.workspaceId),
			});
		},
	});
};
