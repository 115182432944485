import { type InputRule, textblockTypeInputRule } from "prosemirror-inputrules";
import { type NodeType } from "prosemirror-model";

import { createCellID } from "@moment/api-collab/prosemirror-utils";

// Given a node type and a maximum level, creates an input rule that
// turns up to that number of `#` characters followed by a space at
// the start of a textblock into a heading whose level corresponds to
// the number of `#` signs.
export const headingRule = (nodeType: NodeType, maxLevel: number): InputRule => {
	return textblockTypeInputRule(
		new RegExp("^(#{1," + maxLevel + "})\\s$"),
		nodeType,
		(match: string[]) => {
			const m1 = match[1];

			if (!m1) {
				return false;
			}

			// make a new heading that is expanded by default
			// TODO: the id from the previous cell is being replaced with a new cell id
			// instead we should preserve this in case there are comments attached
			return { level: m1.length, isExpanded: true, id: createCellID() };
		}
	);
};
