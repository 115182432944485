import { Node } from "prosemirror-model";
import { type EditorState } from "prosemirror-state";

import { schema } from "@moment/api-collab/prosemirror-schema";
import { type Cell, CellType } from "@moment/api-collab/prosemirror-serialization";
import { DEFAULT_CELL_ID, createCellID } from "@moment/api-collab/prosemirror-utils";

import { type Cell as CellGQL, type CellInput } from "../api/generated/graphql";
import { type Result } from "../components/canvas/easel/runtime/interfaces";
import { type SourceAnalysis } from "../runtime/cell-compiler/analysis";
import { type ValidCellEmitInfo } from "../runtime/cell-compiler/emit";
import { type UniqueSluggifier } from "../utils/format/UniqueSluggifier";
import { Logger } from "../utils/logging";

// Re-export
export { type Cell, CellType } from "@moment/api-collab/prosemirror-serialization";

const logger = new Logger("models/cell");

export interface CachedCell {
	result?: Result;
}

export const isComputedCell = (cell: Cell): boolean => {
	switch (cell.type) {
		case CellType.ProseMirrorJSONCell: {
			return false;
		}
		case CellType.JavaScriptFunctionBodyCell: {
			return true;
		}
	}
};

export type CellID = string;

export type CellInfo = CellInfoExecutable | CellInfoInitializing | CellInfoError;

export type CellMap = { [key: string]: CellInfo };

export interface CellInfoBase {
	cell: Cell;
}

export interface CellInfoInitializing extends CellInfoBase {
	analysis?: SourceAnalysis;
	status: "initializing";
}

export interface CellInfoError extends CellInfoBase {
	analysis?: SourceAnalysis;
	status: "error";
	message: string;
}

export interface CellInfoExecutable extends CellInfoBase {
	analysis?: SourceAnalysis;
	emitInfo: ValidCellEmitInfo;
	status: "executable";
}

export type NewCellInput = PartialWithRequired<Cell, "type" | "cellName">;

export const newCell = (cell: NewCellInput): Cell => {
	const cellID = cell.id || createCellID();
	return {
		type: cell.type,
		code: cell.code || "",
		cellName: cell.cellName,
		id: cellID,
		nodeAttrs: cell.nodeAttrs || {
			id: cellID,
		},
	};
};

export const newJavaScriptFunctionBodyCell = (cell: {
	readonly cellName: string;
	readonly code: string;
	readonly id?: string;
	readonly nodeAttrs?: Record<string, string>;
}): Cell => {
	return newCell({
		type: CellType.JavaScriptFunctionBodyCell,
		cellName: cell.cellName,
		id: cell.id || createCellID(),
		nodeAttrs: cell.nodeAttrs || {},
	});
};

export const newRichTextCell = (cellID?: CellID) => {
	let id = createCellID();

	if (cellID !== undefined) {
		id = cellID;
	}

	return newCell({
		type: CellType.ProseMirrorJSONCell,
		cellName: "",
		id,
		nodeAttrs: {},
	});
};

export const equals = (ca: Cell, cb: Partial<Cell>): boolean => {
	return (
		ca.type === cb.type && ca.code === cb.code && ca.id === cb.id && ca.cellName === cb.cellName
	);
};

/**
 * JavaScriptFunctionBodyCell contains executable JavaScript code formatted as if it were in a
 * function body, i.e., has a `return` statement or a `yield`.
 */
export interface JavaScriptFunctionBodyCell extends Cell {
	readonly type: CellType.JavaScriptFunctionBodyCell;
}

export function isJavaScriptFunctionBodyCell(o: unknown): o is JavaScriptFunctionBodyCell {
	return (
		typeof o === "object" &&
		o !== null &&
		"type" in o &&
		o["type"] === CellType.JavaScriptFunctionBodyCell
	);
}

export interface ProseMirrorJSONCell extends Cell {
	readonly type: CellType.ProseMirrorJSONCell;
}

export function isProseMirrorJSONCell(o: unknown): o is ProseMirrorJSONCell {
	return (
		typeof o === "object" &&
		o !== null &&
		"type" in o &&
		o["type"] === CellType.ProseMirrorJSONCell
	);
}

export const cellTypeToNameMapping: {
	[key: string]: string;
} = {
	ProseMirrorJSONCell: "Rich Text",
	JavaScriptFunctionBodyCell: "Function Body",
};

export const getCellTypeLabel = (value: string): string => {
	return cellTypeToNameMapping[value] || "Unknown";
};

export const getCellName = (cell: Cell): string | undefined => {
	return cell.cellName || undefined;
};

export const encodeNodeAttrs = (nodeAttrs?: Record<string, string> | null): string => {
	try {
		return JSON.stringify(nodeAttrs);
	} catch (e) {
		logger.error("Error encoding nodeAttrs: ", { nodeAttrs });
		return "{}";
	}
};

export const proseMirrorEditorStateToCells = (
	editorState: EditorState,
	filterPredicate: (node: Node) => boolean = () => true
): Cell[] => {
	const cells: Cell[] = [];
	editorState.doc.descendants((node: Node) => {
		if (!filterPredicate(node)) {
			return;
		}
		cells.push(proseMirrorNodeToCell(node));
	});

	return cells;
};

export interface ComputeCellNodeAttrs {
	id: string;
	cellName: string;
	pinned?: boolean;
}

export const proseMirrorNodeToCell = (node: Node): Cell => {
	const { id, cellName } = node.attrs;
	const code = node.textContent;
	const nodeAttrs = node.attrs;

	let type: CellType;
	switch (node.type.name) {
		case "compute_cell":
			type = CellType.JavaScriptFunctionBodyCell;
			break;
		default:
			type = CellType.ProseMirrorJSONCell;
			break;
	}

	return { type, id, cellName, code, nodeAttrs };
};

export const toGQL = (cell: Cell): CellInput => {
	const nodeAttrs = encodeNodeAttrs(cell.nodeAttrs);

	// ensure that prosemirror is source of truth
	return {
		type: cell.type,
		code: cell.code,
		cellName: getCellName(cell) || "",
		id: cell.id || DEFAULT_CELL_ID,
		nodeAttrs,
	};
};

export const extractNodeAttrs = (cell: CellGQL): Cell["nodeAttrs"] => {
	if (cell.nodeAttrs === null) {
		return {};
	}

	if (typeof cell.nodeAttrs === "object") {
		return cell.nodeAttrs;
	}

	try {
		const attrs = JSON.parse(cell.nodeAttrs);
		return attrs;
	} catch (e) {
		logger.error("Failed to parse nodeAttrs", { error: e });
		return {};
	}
};

// TODO reconcile with api-collab/api-types
export const fromGQL = (cell: CellGQL): Cell => {
	const nodeAttrs = extractNodeAttrs(cell);

	switch (cell.type) {
		case CellType.JavaScriptFunctionBodyCell:
		case CellType.ProseMirrorJSONCell: {
			return {
				...cell,
				type: cell.type,
				cellName: cell.cellName || "",
				id: cell.id || createCellID(),
				nodeAttrs,
			};
		}
		default:
			return {
				...cell,
				type: CellType.JavaScriptFunctionBodyCell,
				code: cell.code,
				cellName: cell.cellName || "",
				id: cell.id || createCellID(),
				nodeAttrs,
			};
	}
};

export const toProseMirrorJSON = (cell: Cell) => {
	if (!isProseMirrorJSONCell(cell)) {
		return;
	}

	if (cell.code === "") {
		return {};
	}

	try {
		return JSON.parse(cell.code);
	} catch {
		// no-op
	}
};

export const toProseMirrorNode = (cell: Cell): Node | undefined => {
	if (!isProseMirrorJSONCell(cell)) {
		return;
	}

	const json = toProseMirrorJSON(cell);

	if (json === undefined) {
		return;
	}

	try {
		return Node.fromJSON(schema, json);
	} catch (e) {
		logger.error("Failed to parse JSON", { error: e });
	}
	return;
};

export type Heading = {
	cellID: string;
	text: string;
	slug: string;
	level: number;
	collapsed: boolean;
};

export const parseHeading = (sluggifier: UniqueSluggifier, cell: Cell): Heading | undefined => {
	const node = toProseMirrorNode(cell);

	if (node === undefined) {
		return;
	}

	const child = node.child(0);
	const text = child.textContent;
	if (child.type.name == "heading") {
		return {
			level: parseInt(child.attrs["level"]),
			text,
			slug: sluggifier.generateSlug(text),
			cellID: cell.id || DEFAULT_CELL_ID,
			collapsed: child.attrs["isCollapsed"] == "true",
		};
	}
};

/**
 * Takes a cell and finds all cells it references.
 *
 * This is possible because we need a list of cells we reference to hook tell the runtime which
 * cells depend on which other cells. So, usually this involves either looking at the static
 * analysis, or the emitted code metadata.
 */
export const getReferencesFromCell = (cellInfo: CellInfo | undefined): Set<string> => {
	const refsArr: Set<string> = new Set();

	if (!cellInfo) {
		return refsArr;
	}

	// refs from analysis object for Rendered cells
	if (cellInfo.analysis?.inspectInfo?.kind === "RenderCallExpressionInfo") {
		const r = cellInfo.analysis.inspectInfo.refs;
		r.forEach((ref) => refsArr.add(ref));
	}

	// refs from emitInfo for CodeInfo cells
	if (
		cellInfo.status === "executable" &&
		(cellInfo.emitInfo?.kind === "VariableDefinition" ||
			cellInfo.emitInfo?.kind === "ViewVariableDefinition")
	) {
		const r = cellInfo.emitInfo.refs || [];
		r.forEach((ref: string) => refsArr.add(ref));
	}

	return refsArr;
};

/**
 * Finds all cells in an array that are direct (i.e., one-hop) ancestors of a given cell.
 */
export const getDirectAncestorsOfCell = (
	cell: CellInfo | undefined,
	cells: CellInfo[]
): { cell: CellInfo; index: number }[] => {
	const cellRefs = getReferencesFromCell(cell);
	const directAncestorCells = cells.flatMap((cell, index) => {
		if (!cellRefs.has(cell.cell.cellName || "")) {
			return [];
		}
		return [{ cell, index }] as const;
	});

	return directAncestorCells;
};

/**
 *  * Finds all cells in an array that are direct (i.e., one-hop) descendents of a given cell.
 */
export const getDirectDescendentsOfCell = (
	selectedCell: CellInfo | undefined,
	cells: CellInfo[]
): { cell: CellInfo; index: number }[] => {
	const directDescendentCells = cells.flatMap((cell, index) => {
		const cellRefs = getReferencesFromCell(cell);
		if (!selectedCell?.cell.cellName || !cellRefs.has(selectedCell?.cell.cellName)) {
			return [];
		}
		return [{ cell, index }] as const;
	});

	return directDescendentCells;
};
