import { AllSelection, type Command, TextSelection } from "prosemirror-state";

import { defaultBlockAt } from "./defaultBlockAt";

// If a block node is selected, create an empty paragraph before (if it is its parent's first child)
// or after it.
//
// NOTE: Based heavily on the MIT-licensed default implementation:
// https://github.com/ProseMirror/prosemirror-commands/blob/622eec158e24fe0d575da0b5e767c3c618a90bd5/src/commands.js
export const createParagraphNear: Command = (state, dispatch) => {
	const sel = state.selection;
	const { $from, $to } = sel;
	if (sel instanceof AllSelection || $from.parent.inlineContent || $to.parent.inlineContent) {
		return false;
	}

	const type = defaultBlockAt($to.parent.contentMatchAt($to.indexAfter()));

	if (!type || !type.isTextblock) {
		return false;
	}

	if (dispatch) {
		const side = (!$from.parentOffset && $to.index() < $to.parent.childCount ? $from : $to).pos;
		const content = type.createAndFill();

		if (!content) {
			console.warn("createParagraphNear: content not created, skipping insert");
			return false;
		}

		const tr = state.tr.insert(side, content);
		tr.setSelection(TextSelection.create(tr.doc, side + 1));
		dispatch(tr.scrollIntoView());
	}
	return true;
};
