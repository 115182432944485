import { type ResolvedPos } from "prosemirror-model";

import { type CellPosition, type MomentCellType, isMomentCellType, isTextCell } from "./types";

const makeTypeChecker = (type?: MomentCellType): ((s: string) => boolean) => {
	if (type === undefined) {
		return isMomentCellType;
	}

	return (s: string) => {
		return s === type;
	};
};

export const findAncestorCell = (
	pos: ResolvedPos,
	type?: MomentCellType
): CellPosition | undefined => {
	let depth = pos.depth;

	const typeChecker = makeTypeChecker(type);

	while (depth > 0) {
		const n = pos.node(depth);
		if (isTextCell(n) || typeChecker(n.type.name)) {
			const mType: MomentCellType =
				n.type.name === "compute_cell" ? "compute_cell" : "text_cell";
			return {
				node: n,
				depth,
				id: n.attrs["id"],
				before: pos.before(depth),
				start: pos.start(depth),
				end: pos.end(depth),
				after: pos.after(depth),
				type: mType,
			};
		}

		depth--;
	}
};

export type { CellPosition } from "./types";
