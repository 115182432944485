import { PANE_LABELS } from "~/store/canvas/types";
import { platformUsesCommandKey } from "~/utils/platform";

export const hotkeyCommands = [
	"new-tab",
	"bookmark",
	"bold",
	"convert-to-blockquote",
	"convert-to-code-block",
	"convert-to-h1",
	"convert-to-h2",
	"convert-to-h3",
	"convert-to-ordered_list",
	"convert-to-bullet_list",
	"convert-to-text",
	"copy-cell",
	"italics",
	"new-canvas",
	"open-components-tab",
	"open-cell-dependencies-tab",
	"run-cell-code",
	"save-canvas",
	"strikethrough",
	"toggle-document-history",
	"toggle-code-pane",
	"toggle-navigation-pane",
	"underline",
] as const;
export interface HotkeyInfo {
	mousetrapKeybinding(): string | string[];
	prettyPrintKeybinding(): string;
	description: string;
	category: "System" | "Rich Text" | "Application";
	reserved?: boolean;
}

export const hotkeys: { [command in (typeof hotkeyCommands)[number]]: HotkeyInfo } = {
	/****************************************************************************************
	 * HOTKEY STYLE GUIDE
	 * ==================
	 * Follow these guidelines when adding new prettyPrint hotkeys to ensure they're displayed
	 * consistently in our app, and consistent with the user's OS.
	 *
	 * SYMBOLS:
	 * - ⌘: command
	 * - ⇧: shift
	 * - ⌥: option
	 * - ⌃: control
	 * - ↵: enter
	 *
	 * For MacOS:
	 * - Use the unicode symbols instead of spelling out the modifier key (e.g. ⌘ instead of "cmd")
	 * - Do NOT use a + symbol to join together multiple keys (e.g. ⌘G)
	 * - Show the modifier keys in the following order: control, option, shift, command
	 * - Use as few modifiers as possible, and in this order of preference: command, shift, option, control
	 *
	 * For Windows:
	 * - Spell out the modifier keys instead of using symbols ("Shift" instead of ⇧)
	 * - Capitalize modifier keys
	 * - DO use the + symbold to join together modifier keys with no spaces (e.g. "Ctrl+B")
	 * - Use as few modifiers as possible, and in this order of preference: ctrl, alt, shift
	 ****************************************************************************************/

	/**
	 * RESERVED
	 * ========
	 * These hotkeys are not used in the app, but are reserved by the OS or browser.
	 * Setting `reserved: true` will cause tests to fail if we attempt to use one of
	 * these shortcuts for something in the Moment app. Do not use `reserved: true`
	 * to define shortcuts used in the app.
	 */
	"new-tab": {
		mousetrapKeybinding: () => (platformUsesCommandKey() ? "command+t" : "ctrl+t"),
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⌘T" : "Ctrl+T"),
		description: "New Tab",
		category: "System",
		reserved: true,
	},

	/**
	 * RICH TEXT
	 * =========
	 * Used for editing rich text in the canvas.
	 */
	"bookmark": {
		mousetrapKeybinding: () => (platformUsesCommandKey() ? "command+d" : "ctrl+d"),
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⌘D" : "Ctrl+D"),
		category: "Rich Text",
		description: "Bookmark",
	},
	"italics": {
		mousetrapKeybinding: () => (platformUsesCommandKey() ? "command+i" : "ctrl+i"),
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⌘I" : "Ctrl+I"),
		category: "Rich Text",
		description: "Italics",
	},
	"underline": {
		mousetrapKeybinding: () => (platformUsesCommandKey() ? "command+u" : "ctrl+u"),
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⌘U" : "Ctrl+U"),
		category: "Rich Text",
		description: "Underline",
	},
	"bold": {
		mousetrapKeybinding: () => (platformUsesCommandKey() ? "command+b" : "ctrl+b"),
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⌘B" : "Ctrl+B"),
		category: "Rich Text",
		description: "Bold",
	},
	"strikethrough": {
		mousetrapKeybinding: () => (platformUsesCommandKey() ? "command+shift+x" : "ctrl+shift+x"),
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⌘⇧X" : "Ctrl+Shift+X"),
		category: "Rich Text",
		description: "Strikethrough",
	},
	"convert-to-text": {
		mousetrapKeybinding: () => (platformUsesCommandKey() ? "command+shift+0" : "ctrl+shift+0"),
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⌘⇧0" : "Ctrl+Shift+0"),
		category: "Rich Text",
		description: "Convert block to text",
	},
	"convert-to-h1": {
		mousetrapKeybinding: () => (platformUsesCommandKey() ? "command+shift+1" : "ctrl+shift+1"),
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⌘⇧1" : "Ctrl+Shift+1"),
		category: "Rich Text",
		description: "Convert block to Heading 1",
	},
	"convert-to-h2": {
		mousetrapKeybinding: () => (platformUsesCommandKey() ? "command+shift+2" : "ctrl+shift+2"),
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⌘⇧2" : "Ctrl+Shift+2"),
		category: "Rich Text",
		description: "Convert block to Heading 2",
	},
	"convert-to-h3": {
		mousetrapKeybinding: () => (platformUsesCommandKey() ? "command+shift+3" : "ctrl+shift+3"),
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⌘⇧3" : "Ctrl+Shift+3"),
		category: "Rich Text",
		description: "Convert block to Heading 3",
	},
	"convert-to-ordered_list": {
		mousetrapKeybinding: () => (platformUsesCommandKey() ? "command+shift+7" : "ctrl+shift+7"),
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⌘⇧7" : "Ctrl+Shift+7"),
		category: "Rich Text",
		description: "Convert block to ordered list",
	},
	"convert-to-bullet_list": {
		mousetrapKeybinding: () => (platformUsesCommandKey() ? "command+shift+8" : "ctrl+shift+8"),
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⌘⇧8" : "Ctrl+Shift+8"),
		category: "Rich Text",
		description: "Convert block to bullet list",
	},
	"convert-to-blockquote": {
		mousetrapKeybinding: () => (platformUsesCommandKey() ? "command+shift+>" : "ctrl+shift+>"),
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⌘⇧>" : "Ctrl+Shift+>"),
		category: "Rich Text",
		description: "Convert block to blockquote",
	},
	"convert-to-code-block": {
		mousetrapKeybinding: () =>
			platformUsesCommandKey() ? "command+shift+\\" : "ctrl+shift+\\",
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⌘⇧\\" : "Ctrl+Shift+\\"),
		category: "Rich Text",
		description: "Convert block to blockquote",
	},

	/**
	 * BLUE BAR
	 */
	"copy-cell": {
		mousetrapKeybinding: () => (platformUsesCommandKey() ? "command+c" : "ctrl+c"),
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⌘C" : "Ctrl+C"),
		category: "Application",
		description: "Copy cell",
	},

	/**
	 * MOMENT APP
	 * ==========
	 * Used for app-chrome level actions like toggling panes
	 */
	"toggle-document-history": {
		mousetrapKeybinding: () => (platformUsesCommandKey() ? "command+y" : "ctrl+y"),
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⌘Y" : "Ctrl+Y"),
		category: "Application",
		description: "Toggle open document history",
	},
	"toggle-code-pane": {
		mousetrapKeybinding: () => (platformUsesCommandKey() ? "command+j" : "ctrl+j"),
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⌘J" : "Ctrl+J"),
		category: "Application",
		description: "Toggle code editor",
	},
	"toggle-navigation-pane": {
		mousetrapKeybinding: () => (platformUsesCommandKey() ? "command+\\" : "ctrl+\\"),
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⌘\\" : "Ctrl+\\"),
		category: "Application",
		description: "Toggle Pages Pane",
	},
	"open-components-tab": {
		mousetrapKeybinding: () => (platformUsesCommandKey() ? "command+p" : "ctrl+p"),
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⌘P" : "Ctrl+P"),
		category: "Application",
		description: `Toggle ${PANE_LABELS["components"].toLowerCase()}`,
	},
	"open-cell-dependencies-tab": {
		mousetrapKeybinding: () => (platformUsesCommandKey() ? "command+alt+d" : "ctrl+alt+d"),
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⌥⌘D" : "Ctrl+Alt+D"),
		category: "Application",
		description: `Toggle ${PANE_LABELS["cellDependencies"].toLowerCase()}`,
	},
	"save-canvas": {
		mousetrapKeybinding: () => (platformUsesCommandKey() ? "command+s" : "ctrl+s"),
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⌘S" : "Ctrl+S"),
		category: "Application",
		description: "Save canvas",
	},
	"new-canvas": {
		mousetrapKeybinding: () => (platformUsesCommandKey() ? "command+shift+n" : "ctrl+shift+n"),
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⇧⌘N" : "Ctrl+Shift+N"),
		category: "Application",
		description: "New canvas",
	},
	"run-cell-code": {
		mousetrapKeybinding: () => (platformUsesCommandKey() ? "shift+enter" : "shift+enter"),
		prettyPrintKeybinding: () => (platformUsesCommandKey() ? "⇧↵" : "Shift+Enter"),
		category: "Application",
		description: "Run cell",
	},
} as const;
