import { type Command, Selection } from "prosemirror-state";

import { schema } from "@moment/api-collab/prosemirror-schema";

export const escapeCodeBlock: Command = (state, dispatch) => {
	const { selection } = state.tr;

	const isInCodeBlock = selection.$from.node().type === schema.nodes.code_block;

	if (!isInCodeBlock) {
		return false;
	}

	const isCursorAtEndOfBlock =
		selection.$from.parentOffset === selection.$from.parent.content.size ||
		selection.$to.parentOffset === selection.$to.parent.content.size;
	if (!isCursorAtEndOfBlock) {
		return false;
	}

	// Get the text of the node from the start to the current cursor position
	const text = selection.$from.node().textBetween(0, selection.$from.parentOffset);

	// find the first newline character consecutive newline characters from the end of the block
	let newlineIndex = text.length - 1;
	while (text[newlineIndex] === "\n" && newlineIndex > 0) {
		newlineIndex--;
	}

	// Check if there are at least 3 consecutive newlines at the end
	if (text.length - newlineIndex < 3) {
		return false;
	}

	const newParagraph = schema.nodes.paragraph.createAndFill({});
	if (!newParagraph || !dispatch) {
		return false;
	}

	const { tr } = state;

	// replace the ending newlines with nothing
	tr.insert(selection.$to.after(), newParagraph);
	tr.setSelection(Selection.near(tr.doc.resolve(selection.$to.after())));
	tr.deleteRange(selection.$from.pos - text.length + newlineIndex + 1, selection.$to.pos);
	dispatch(tr.scrollIntoView());
	return true;
};
