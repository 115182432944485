import type { NodeType } from "prosemirror-model";
import { AllSelection, type Command, TextSelection } from "prosemirror-state";

import { DEFAULT_INDENT, schema } from "@moment/api-collab/prosemirror-schema";

// IMPORTANT: If you change this, change `[data-indent]` CSS in `_prose-mirror-overrides.css` too.
const MAX_LIST_ITEM_INDENT = 7;

// un-indent empty list item
export const unindentEmptyListItem: Command = (state, dispatch) => {
	const { $from } = state.selection;
	const listItem = schema.nodes.list_item;
	if ($from.node(-1).type !== listItem && $from.node(-1).type !== schema.nodes.to_do_item) {
		return false;
	}

	const node = $from.node(-1);
	const indent = parseInt(node.attrs["indent"] ?? `${DEFAULT_INDENT}`) || DEFAULT_INDENT;

	if (indent > 0 && dispatch) {
		dispatch(
			state.tr.setNodeMarkup($from.before(-1), null, { ...node.attrs, indent: indent - 1 })
		);
		return true;
	}

	return false;
};

// set list_item node's indent attribute to subtract 1
export const unindentListItem: Command = (state, dispatch) => {
	return updateListItemIndent(-1)(state, dispatch);
};

// set list_item node's indent attribute to add 1
export const indentListItem: Command = (state, dispatch) => {
	return updateListItemIndent(1)(state, dispatch);
};

// set list_item node's indent attribute to add or subtract by an increment
export const updateListItemIndent: (indentStep: number) => Command =
	(indentStep) => (state, dispatch) => {
		const { $from, $to } = state.selection;
		const listItem = schema.nodes.list_item;
		const todoListItem = schema.nodes.to_do_item;

		// Check if the selection is a list item or todo list item
		if ($from.node(-1).type !== listItem && $from.node(-1).type !== todoListItem) {
			return false;
		}

		// Start a transaction
		const tr = state.tr;

		// Find all list items between the selection start and end positions
		state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
			const indent = parseInt(node?.attrs["indent"] ?? `${DEFAULT_INDENT}`) || DEFAULT_INDENT;

			if (
				!node ||
				// Skip nodes that are not list items or todo list items
				(node.type !== listItem && node.type !== todoListItem) ||
				// don't allow indenting more than the max
				(indent >= MAX_LIST_ITEM_INDENT && indentStep === 1) ||
				// don't allow indenting less than 0
				(indent === 0 && indentStep === -1)
			) {
				return;
			}

			// Set the indent attribute to the new value
			tr.setNodeMarkup(pos, null, {
				...node.attrs,
				indent: indent + indentStep,
			});
		});

		// Commit the transaction
		if (dispatch) {
			dispatch(tr);
			return true;
		}

		return false;
	};

export const splitListItem =
	(listType: NodeType): Command =>
	(state, dispatch) => {
		const { $from } = state.selection;
		const listItem = listType;

		// -1 because paragraph is inside list item
		const listItemNode = $from.node(-1);

		if (listItemNode.type !== listItem) {
			return false;
		}

		// if it's an empty list item, return false
		if ($from.node().content.size === 0) {
			return false;
		}

		if (dispatch) {
			// 2 deep for: paragraph, and listType
			const splitDepth = 2;
			const tr = state.tr;

			if (
				state.selection instanceof TextSelection ||
				state.selection instanceof AllSelection
			) {
				// Delete the content they have selected
				tr.deleteSelection();
			}

			tr.split($from.pos, splitDepth, [
				{
					type: listType,
					attrs: {
						...listItemNode.attrs,
						// Splitting a checked TODO list item should produce an unchecked TODO list item.
						status: listType !== schema.nodes.to_do_item ? undefined : "to do",
					},
				},
			]);

			dispatch(tr);

			return true;
		}

		return false;
	};
