import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { useCallback } from "react";

import type { CanvasMetadata } from "@moment/api-collab/api-types";

import { isDesktopAppMode } from "~/auth/interop/common";
import { getAppBaseUrl } from "~/utils/common";

import { documentQueryKeys } from ".";
import { useCloneRepoMutation } from "../git/useCloneRepoMutation";
import { useRouteParams } from "../route";

export const queryDocument = async (
	workspaceId: string,
	documentId: string
): Promise<CanvasMetadata> => {
	const response = await fetch(
		`${getAppBaseUrl()}/api/public/${workspaceId}/${documentId}/index.json`
	);
	if (!response.ok) {
		throw new Error("Failed to fetch public document metadata");
	}

	return await response.json();
};

export const useDocumentQuery = (args: { workspaceId: string; documentId: string }) => {
	const routeParams = useRouteParams();

	const { workspaceId = routeParams.workspaceId, documentId = routeParams.documentId } = args;

	const { mutateAsync: cloneGitRepo } = useCloneRepoMutation();

	const getDocumentMetadata = useCallback(async () => {
		try {
			return await window.desktopIpcApi?.fetchLocalDocumentMetadata(workspaceId, documentId);
		} catch (error) {
			return null;
		}
	}, [workspaceId, documentId]);

	const query = useQuery<CanvasMetadata>({
		queryKey: documentQueryKeys.document(workspaceId, documentId),
		queryFn: async () => {
			const HACK_IS_HELP_DOC = isDesktopAppMode() && workspaceId === "Help";
			if (!isDesktopAppMode() || HACK_IS_HELP_DOC) {
				return await queryDocument(workspaceId, documentId);
			}

			let document = await getDocumentMetadata();
			if (!document) {
				await cloneGitRepo({ workspaceId, documentId });

				// Try again
				document = await getDocumentMetadata();
			}

			if (!document) {
				throw new Error("Failed to fetch document metadata");
			}

			return document;
		},
		enabled: !isEmpty(workspaceId) && !isEmpty(documentId),
	});

	return query;
};
