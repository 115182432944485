import { type Node } from "prosemirror-model";
import { type NodeView } from "prosemirror-view";
import { renderToString } from "react-dom/server";

import { Row } from "~/components/canvas/layout/row/Row";
import { hotkeys } from "~/utils/hotkeys";

export class DocumentPlaceholderView implements NodeView {
	public dom: HTMLElement;

	constructor(node: Node) {
		const div = document.createElement("div");
		const cellID = node.attrs["cellID"];

		const htmlString = renderToString(
			<Row cellID={cellID}>
				<div className="text-tertiary">
					{hotkeys["open-components-tab"].prettyPrintKeybinding()} to insert data and
					components, or start typing
				</div>
			</Row>
		);

		this.dom = div;
		this.dom.innerHTML = htmlString;
	}
}
