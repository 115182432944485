import { InputRule } from "prosemirror-inputrules";
import { type MarkType } from "prosemirror-model";
import { type EditorState } from "prosemirror-state";

// Given a link markType returns an input rule that turns `"[title](href)"`
// into an <a> tag.
export const mdLinkRegex = /\[([^[]+)\]\((.+)\)$/;

export const linkRuleMd = (markType: MarkType): InputRule => {
	const matchHandler = function (
		state: EditorState,
		match: string[],
		start: number,
		end: number
	) {
		const tr = state.tr;

		const m1 = match[1];
		const m2 = match[2];

		if (m1 === undefined || m2 === undefined) {
			return tr;
		}

		return state.tr.insertText(m1, start, end).addMark(
			start,
			start + m1.length,
			markType.create({
				href: m2,
			})
		);
	};

	return new InputRule(mdLinkRegex, matchHandler);
};
