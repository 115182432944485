import { createTRPCClient, createTRPCReact } from "@trpc/react-query";

import type { AppRouter } from "@moment/desktop-app-ws-api";

export const trpc = createTRPCReact<AppRouter>();

export const vanillaTrpc = createTRPCClient<AppRouter>({
	links: [],
});

export type { AppRouter };
