import { Plugin, PluginKey } from "prosemirror-state";

import { UniqueSluggifier } from "~/utils/format/UniqueSluggifier";
import { getPageSlug, isSameCanvasLink } from "~/utils/helpers/url";

function isSamePage(link1: string, link2: string, defaultHostname: string) {
	const url1 = new URL(link1, defaultHostname);
	const url2 = new URL(link2, defaultHostname);

	// ignore trailing slashes
	const pathname1 = url1.pathname.replace(/\/$/, "");
	const pathname2 = url2.pathname.replace(/\/$/, "");

	return url1.protocol === url2.protocol && url1.host === url2.host && pathname1 === pathname2;
}

function scrollToHash(hash: string) {
	const element: Element | null = document.getElementById(hash);
	if (element) {
		element.scrollIntoView({ behavior: "smooth" });
		return;
	}

	// if element is not found, then try to find a heading with the same slug
	// set location hash
	window.location.hash = hash;

	// find all headings
	const headings = document.querySelectorAll("h1, h2, h3, h4, h5, h6");
	if (headings.length === 0) {
		return;
	}

	// Note: if two headings are the same, it will probably not work
	// TODO: create a unique slug for each heading as a plugin?
	const sluggifier = new UniqueSluggifier();

	// search through headings to find slug
	for (const heading of headings) {
		const headingText = heading.textContent;
		if (!headingText) {
			continue;
		}
		const slug = sluggifier.generateSlug(headingText);
		if (`#${slug}` === hash) {
			heading.scrollIntoView({ behavior: "smooth" });
			return;
		}
	}
}

export const clickableLinkPlugin = () => {
	const p = new Plugin({
		key: new PluginKey("clickableLinkPlugin"),

		props: {
			handleClickOn: (view, _pos, _node, _nodePos, event, _direct) => {
				const target = event.target;
				if (!(target instanceof HTMLElement)) {
					return;
				}

				// The `target` may be a descendent of an anchor tag
				// (e.g. <a href="moment.dev"><em><u>target</u></em></a>)
				const closestAnchorElement = target.closest(".ProseMirror a");
				if (!(closestAnchorElement instanceof HTMLAnchorElement)) {
					return;
				}

				// Don't `window.open` links inside a `compute_cell`. This function is mostly for
				// links that appear in the contenteditable ProseMirror document, rather than
				// compute cells. Generally compute cells can have anything inside of them and
				// will want/need broad flexibility over how links inside them are handled.
				const closestComputeCellElement = target.closest("[data-node-type='compute_cell']");
				if (closestComputeCellElement) {
					return;
				}

				const href = closestAnchorElement.getAttribute("href");

				if (!href) {
					return;
				}

				// We want to check if the link is to the current canvas
				// A canvas can be formed by the urn or by the shared name
				// If the link is in the form of the shared name, we need to compare it
				// to the current canvas's shared name
				// If the link is in the form of the urn, we need to compare it to the current urn
				const url = new URL(href, window.location.origin);

				const isLinkedToCurrentCanvas = isSameCanvasLink(url.href, window.location.href);

				// check if the link is within the current page
				const isLinkedToCurrentPage =
					isLinkedToCurrentCanvas &&
					isSamePage(url.href, window.location.href, window.location.origin);
				if (isLinkedToCurrentPage) {
					const hash = url.hash;
					scrollToHash(hash);

					event.preventDefault();
					return;
				}

				if (isLinkedToCurrentCanvas) {
					// get page slug
					const pageSlug = getPageSlug(url);
					if (pageSlug) {
						// TODO(trey): Navigate to internal page from links in docs and scroll to the hash if there is one
					}
					event.preventDefault();
					return;
				}

				/**
				 * -- noopener --
				 * https://developer.mozilla.org/en-US/docs/Web/API/Window/open#window_functionality_features
				 *
				 * If this feature is set, the newly-opened window will open as normal, except that
				 * it will not have access back to the originating window (via Window.opener — it
				 * returns null). In addition, the window.open() call will also return null, so the
				 * originating window will not have access to the new one either. This is useful
				 * for preventing untrusted sites opened via window.open() from tampering with the
				 * originating window, and vice versa.
				 */
				const features = isLinkedToCurrentCanvas
					? ""
					: ["noopener", "noreferrer"].join(",");
				const windowTarget = isLinkedToCurrentCanvas ? "_self" : "_blank";
				window.open(url, windowTarget, features);

				event.preventDefault();

				return true;
			},
		},
	});

	return p;
};
