import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { Page } from "~/api/generated/graphql";
import { type Cell } from "~/models/cell";
import { Logger } from "~/utils/logging";

import { type CodeEditorState } from "./types";

const logger = new Logger("code-editor/slice");

export const initialState: CodeEditorState = {
	open: false,
	tabs: {},
	tabDrafts: {},
	pinnedTabs: {},
	currentTab: {},
	flexTab: {},
};

export const codeEditorSlice = createSlice({
	name: "codeEditor",
	initialState,
	reducers: {
		resetCodeEditor: () => {
			return initialState;
		},

		openCodePane: (state, action: PayloadAction<{ open: boolean }>) => {
			state.open = action.payload.open;
		},

		toggleOpen: (state) => {
			state.open = !state.open;
		},

		openTab: (
			state,
			action: PayloadAction<{
				cellID: Cell["id"];
				activePageID?: Page["id"];
				openCodePane?: boolean;
				overridePin?: boolean;
			}>
		) => {
			const { cellID, activePageID, openCodePane, overridePin } = action.payload;

			if (activePageID === undefined) {
				logger.warn("openTab: activePageID is undefined");
				return;
			}

			const pageTabs = state.tabs[activePageID];
			const paneHasFlexTab = state.flexTab[activePageID] !== undefined;
			const isNewTab = !pageTabs?.includes(cellID);
			const hasPinnedTab = state.pinnedTabs[activePageID] !== undefined;

			/**
			 * if tab is new and code pane already has a flex tab
			 * close flex tab
			 */
			if (pageTabs && paneHasFlexTab && isNewTab) {
				const index = pageTabs.findIndex((id) => id === state.flexTab[activePageID]);
				if (index !== -1) {
					state.tabs[activePageID]?.splice(index, 1);
				}

				// Set the tab as current
				state.currentTab[activePageID] = cellID;
			}

			/**
			 * if tab is new, push to tabs and set as flex tab
			 */
			if (isNewTab) {
				// if page has no tabs, initialize tabs array
				if (!state.tabs[activePageID]) {
					state.tabs[activePageID] = [];
				}

				state.tabs[activePageID]?.push(cellID);
				state.tabDrafts[cellID] = { code: "" };

				state.flexTab[activePageID] = cellID;
			}

			// Set the tab as current if there are no pinned tabs, or if overridePin is true
			if (!hasPinnedTab || overridePin) {
				state.pinnedTabs[activePageID] = undefined;
				state.currentTab[activePageID] = cellID;
			}

			if (openCodePane) {
				state.open = true;
			}
		},

		clearFlexTab: (state, action: PayloadAction<{ activePageID?: Page["id"] }>) => {
			const { activePageID } = action.payload;

			if (activePageID === undefined) {
				logger.warn("clearFlexTab: activePageID is undefined");
				return;
			}

			state.flexTab[activePageID] = undefined;
		},

		closeTab: (
			state,
			action: PayloadAction<{ cellID: Cell["id"]; activePageID?: Page["id"] }>
		) => {
			const { cellID, activePageID } = action.payload;

			if (activePageID === undefined) {
				logger.warn("closeTab: activePageID is undefined");
				return;
			}

			const pageTabs = state.tabs[activePageID];
			const indexOf = pageTabs ? pageTabs.findIndex((s) => s === cellID) : -1;

			const tabDraft = state.tabDrafts[cellID];

			if (tabDraft === undefined) {
				return;
			}

			tabDraft.code = undefined;

			// Remove from tabs list
			if (indexOf >= 0) {
				state.tabs[activePageID]?.splice(indexOf, 1);
			}

			// Remove pinned tab
			state.pinnedTabs[activePageID] = undefined;

			// open closest neighbor when tab is closed
			if (cellID === state.currentTab[activePageID]) {
				const nextNeighbor = Math.max(indexOf - 1, 0);

				if (nextNeighbor >= 0 && pageTabs) {
					state.currentTab[activePageID] = pageTabs[nextNeighbor];
				} else {
					state.currentTab[activePageID] = undefined;
				}
			}

			// if closing last open tab, close editor
			if (state.tabs[activePageID]?.length === 0) {
				state.open = false;
			}
		},

		togglePinTab: (
			state,
			action: PayloadAction<{ cellID: Cell["id"]; activePageID?: Page["id"] }>
		) => {
			const { cellID, activePageID } = action.payload;

			if (activePageID === undefined) {
				logger.warn("togglePinTab: activePageID is undefined");
				return;
			}

			if (state.pinnedTabs[activePageID] === cellID) {
				state.pinnedTabs[activePageID] = undefined;
			} else {
				state.pinnedTabs[activePageID] = cellID;
				state.currentTab[activePageID] = cellID;
				state.flexTab[activePageID] = undefined;
			}
		},

		setTempCode: (state, action: PayloadAction<{ cellID: Cell["id"]; code: string }>) => {
			const { cellID, code } = action.payload;
			if (!cellID) {
				return;
			}

			state.tabDrafts = Object.assign(state.tabDrafts, { [cellID]: { code } });
		},

		clearTempCode: (state, action: PayloadAction<{ cellID: Cell["id"] }>) => {
			const cellID = action.payload.cellID;
			if (!cellID) {
				return;
			}

			const tabDraft = state.tabDrafts[cellID];
			if (tabDraft === undefined) {
				return;
			}
			tabDraft.code = "";
		},

		setTabs: (
			state,
			action: PayloadAction<{ tabs: Array<string>; activePageID?: Page["id"] }>
		) => {
			const { tabs, activePageID } = action.payload;

			if (activePageID === undefined) {
				logger.warn("setTabs: activePageID is undefined");
				return;
			}

			const onlyContainsKnownIDs = tabs.every((id) => state.tabs[activePageID]?.includes(id));

			// only set tabs if all IDs are known
			if (!onlyContainsKnownIDs) {
				return;
			}

			state.tabs[activePageID] = action.payload.tabs;
		},
	},
});

export const {
	openCodePane,
	toggleOpen,
	openTab,
	closeTab,
	setTempCode,
	clearTempCode,
	resetCodeEditor,
	togglePinTab,
	clearFlexTab,
	setTabs,
} = codeEditorSlice.actions;

export const codeEditor = codeEditorSlice.reducer;
