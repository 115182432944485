import { InputRule } from "prosemirror-inputrules";
import { type MarkType } from "prosemirror-model";
import { type EditorState } from "prosemirror-state";

const topLevelDomains = import("./topLevelDomains");

let isTopLevelDomain = (_: string) => {
	// If the user types in the 0.1 seconds before we load the TLD checker we will incorrectly stuff
	// like `something.h` as a valid TLD.
	return true;
};
void topLevelDomains.then(({ topLevelDomains }) => {
	isTopLevelDomain = (tld: string) => topLevelDomains.has(tld.toUpperCase());
});

// Given a link markType returns an input rule that turns `"www.moment.dev"`
// into an <a> tag.

export const urlRegex =
	/(?<protocol>http:\/\/|https:\/\/)?((?<path_component>[-a-zA-Z0-9@]{1,256}\.){1,})(?<top_level_domain>[-a-zA-Z0-9]{1,256})(?<path>[-a-zA-Z0-9@:%_+.~#&/=-]*)(\?(?<query_string>[a-zA-Z0-9,@:%_+.~#?&/=]+))?\s$/;
export const urlRegexWithoutTrailingSpace =
	/(?<protocol>http:\/\/|https:\/\/)?((?<path_component>[-a-zA-Z0-9@]{1,256}\.){1,})(?<top_level_domain>[-a-zA-Z0-9]{1,256})(?<path>[-a-zA-Z0-9@:%_+.~#&/=-]*)(\?(?<query_string>[a-zA-Z0-9,@:%_+.~#?&/=]+))?/;

export const LINK_RULE_URL_BLOCK_LIST = ["e.g.", "e.g", "i.e.", "i.e"];
const TLD_RULE_BLOCK_LIST = new Set([
	"py",
	"java",
	"md",
	"sh",
	"coffee",
	"dot",
	"ml",
	"mm",
	"pl",
	"al",
	"ph",
	"pub",
	"fish",
]);

export const linkRuleUrl = (markType: MarkType): InputRule => {
	const matchHandler = function (
		state: EditorState,
		match: RegExpMatchArray,
		start: number,
		end: number
	) {
		const tr = state.tr;

		const matchedString = match[0];
		const trimmedMatchedString = matchedString.trim();

		if (matchedString === undefined) {
			return tr;
		}

		const topLevelDomain: string = match.groups?.["top_level_domain"] || "";
		if (
			// Disallowed urls
			LINK_RULE_URL_BLOCK_LIST.includes(trimmedMatchedString) ||
			// Top Level Domains cannot be all numbers, must have at least one alpha character
			!isNaN(parseInt(topLevelDomain, 10))
		) {
			// bandaid - don't create a link for these cases
			return null;
		} else if (!isTopLevelDomain(topLevelDomain) || TLD_RULE_BLOCK_LIST.has(topLevelDomain)) {
			// Don't render as a link if not one of the well-known TLDs.
			return null;
		}

		let href = trimmedMatchedString;

		if (!match.groups?.["protocol"]) {
			// if a url starts with "//" it will
			// default to the current protocol of the page
			// i.e. // will be http://
			// or // will be https://
			href = "//" + href;
		}
		return tr.insertText(matchedString, start, end).addMark(
			start,
			start + trimmedMatchedString.length,
			markType.create({
				href,
			})
		);
	};

	return new InputRule(urlRegex, matchHandler);
};
