import { Fragment } from "prosemirror-model";
import { type Command, NodeSelection, TextSelection } from "prosemirror-state";

import { schema } from "@moment/api-collab/prosemirror-schema";

import { singleComputeCellSelected } from "../helpers/singleComputeCellSelected";

export const createEmptyParagraphBeforeIfComputeCellSelected: Command = (state, dispatch) => {
	// Can't do anything if there's no dispatch function.
	if (!dispatch) {
		return false;
	}

	const sel = state.selection;

	// Proceed only if the lenght of seleciton is 0 and the selection is in a
	// `compute_cell`.
	if (!singleComputeCellSelected(sel)) {
		return false;
	}

	// Else, insert a paragraph at the beginning of the cell.
	let beginningOfCell = sel.$anchor.start() - 1;
	if (sel instanceof NodeSelection) {
		// `NodeSelection#start()` will returns the position of the parent node (often doc, which
		// would be 0).
		beginningOfCell = sel.$anchor.pos;
	}

	const emptyParagraph = Fragment.from(schema.nodes.paragraph.create());

	let tr = state.tr.insert(beginningOfCell, emptyParagraph);
	tr = tr.setSelection(TextSelection.near(tr.doc.resolve(beginningOfCell), 1));
	dispatch(tr.scrollIntoView());
	return true;
};
