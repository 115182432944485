export { useCreatePageMutation } from "./useCreatePageMutation";
export { useRenamePageMutation } from "./useRenamePageMutation";
export { useReorderPagesMutation } from "./useReorderPagesMutation";
export { useTogglePageArchivedMutation } from "./useTogglePageArchivedMutation";

export const pageQueryKeys = {
	base: ["pages"],
	updatePage: ["pages", "updatePage"],
	documentPages: (workspaceId: string, documentId: string) => ["pages", workspaceId, documentId],
	pageContent: (workspaceId: string, documentId: string, pageId: string) => [
		"pages",
		"content",
		workspaceId,
		documentId,
		pageId,
	],
	pageAuthor: (workspaceId: string, documentId: string, pageId: string) => [
		"pages",
		"author",
		workspaceId,
		documentId,
		pageId,
	],
	relatedFile: (workspaceId: string, documentId: string, pageId: string, file: string) => [
		"pages",
		workspaceId,
		documentId,
		pageId,
		file,
	],
};
