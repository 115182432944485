import { Plugin, PluginKey } from "prosemirror-state";

import { VisualDebugManager } from "./VisualDebugManager";

export const visualDebugPluginKey = new PluginKey("visualDebugPlugin");

export const visualDebugPlugin = () => {
	const p = new Plugin({
		key: visualDebugPluginKey,
		view(view) {
			return new VisualDebugManager({ view, key: visualDebugPluginKey });
		},
	});

	return p;
};
