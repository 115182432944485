import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useLogger } from "~/components/canvas/hooks/useLogger";
import { fromGQL } from "~/models/CanvasListingItem";
import { trpc } from "~/utils/trpc";

import { documentQueryKeys } from ".";
import { useAddGitRemoteMutation } from "../git";
import { useCreateRepoMutation } from "../gitea";
import { useUpdateWorkspaceMutation, workspaceQueryKeys } from "../workspaces";
import { LOCAL_WORKSPACE_ID } from "../workspaces/constants";

export const useCreateDocumentMutation = () => {
	const logger = useLogger("useCreateDocumentMutation");

	const queryClient = useQueryClient();
	const utils = trpc.useUtils();

	const { mutateAsync: initRepo } = trpc.git.init.useMutation();
	const { updateAsync: updateWorkspace } = useUpdateWorkspaceMutation();
	const { mutateAsync: createRemoteRepo } = useCreateRepoMutation();
	const { mutateAsync: addGitRemote } = useAddGitRemoteMutation();

	const mutation = useMutation({
		mutationKey: documentQueryKeys.createDocument,
		mutationFn: async (args: { workspaceId: string }) => {
			const { workspaceId } = args;

			const document = await window.desktopIpcApi?.createNewDocument(workspaceId);
			if (!document) throw new Error("Failed to create new local document");
			const data = fromGQL(document);

			// Initialize the git repo on disk
			await initRepo({ workspaceId, documentId: document.id });

			await updateWorkspace({ workspaceId }, (workspace) => {
				if (!workspace) {
					logger.error("Workspace not found");
					return;
				}

				workspace.documents[document.id] = data;

				return workspace;
			});

			if (workspaceId !== "local") {
				try {
					const remote = await createRemoteRepo({ name: document.id, workspaceId });

					if (remote) {
						await addGitRemote({
							workspaceId,
							documentId: document.id,
						});
					}
				} catch (error) {
					logger.error("Error creating remote repo", { error });
				}
			}

			return data;
		},
		async onSuccess({ id: _ }, { workspaceId }) {
			const location = workspaceId === LOCAL_WORKSPACE_ID ? "local" : "remote";

			// Invalidate the queries
			void Promise.all([
				queryClient.invalidateQueries({
					queryKey: workspaceQueryKeys.workspaces(location, false),
				}),
				queryClient.invalidateQueries({
					queryKey: workspaceQueryKeys.workspaces(location, true),
				}),
				queryClient.invalidateQueries({
					queryKey: workspaceQueryKeys.workspace(location, workspaceId),
				}),
				utils.git.invalidate(),
			]);
		},
	});

	return mutation;
};
