import {
	type Expression,
	type JSXEmptyExpression,
	type Node,
	type ObjectProperty,
	type SourceLocation,
} from "@babel/types";

import { AstPattern } from "../ast-pattern";
import {
	arrayExpressionPattern,
	arrowFunctionExpressionPattern,
	blockStatementPattern,
	callExpressionPattern,
	functionExpressionPattern,
	jsxElementCaptureJsxIdentifierPattern,
	jsxExpressionContainerPattern,
	objectExpressionPattern,
	returnStatementPattern,
} from "../ast-pattern/patterns";
import { toNodeLocation } from "./NodeLocation";
import { makeLocImmerSerializable } from "./patternUtil";

export interface CapturedValue {
	fieldName: { name: string };
	fieldValue: { value: unknown; type: unknown };
}

export interface AccumulatedValue {
	attributeName: { name: string };
	attributeValue: { value: unknown };
	fields: { [key: string]: unknown };
	type: unknown;
}

const captureJsxAttributeValue = (
	expression: Expression | JSXEmptyExpression | undefined,
	inExpressionContainer: boolean
) => {
	const loc = expression && toNodeLocation(expression);
	return {
		attributeValue: {
			...loc,
			inExpressionContainer,
			type: expression?.type,
		},
	};
};

// A `SimpleObject` is an object where all properties are a JS literal, e.g., a string literal.
const simpleObjectPattern = AstPattern.captureGroup(
	objectExpressionPattern(
		// ALL object fields should have primitive literals in their values.
		AstPattern.allElements<Partial<ObjectProperty>>({
			type: "ObjectProperty",
			key: AstPattern.any((fieldName) => ({ fieldName })),
			value: AstPattern.captureGroup(
				AstPattern.any(),
				(captured: unknown, acc: unknown, node: unknown) => ({ fieldValue: node })
			),
		})
	),
	(captured: { [key: string]: CapturedValue }, acc: unknown, node: Node) => {
		const fieldLocations: { [key: string]: unknown } = {};
		for (const value of Object.values(captured)) {
			const { fieldName, fieldValue } = value;
			fieldLocations[fieldName.name] = {
				name: toNodeLocation(fieldName as unknown as Node),
				value: {
					type: fieldValue.type,
					value: fieldValue.value,
					...toNodeLocation(fieldValue as unknown as Node),
				},
			};
		}

		return { containingObject: toNodeLocation(node), fieldLocations };
	}
);

// A `SimpleObjectArray` is an array where `allElements` are `ObjectExpression`s whose properties
// are `SimpleObject`.
const simpleObjectArrayPattern = AstPattern.captureGroup(
	arrayExpressionPattern(AstPattern.allElements(simpleObjectPattern)),
	(captured: { [key: string]: CapturedValue }, acc: unknown, node: Expression) => {
		return {
			type: "SimpleObjectArrayArgument",
			fields: [...Object.values(captured)],
			...captureJsxAttributeValue(node, true),
		};
	}
);

// Capture all attributes in a JSX tag.
const captureJsxAttributes = AstPattern.captureGroup(
	// Find any elements which satisfy the following conditions...
	AstPattern.anyElements({
		type: "JSXAttribute",
		name: (attributeName: unknown) => ({ attributeName }),
		// ... values are one of: ...
		value: AstPattern.or(
			// an "expression container" like `value={"whatever"}` ...
			jsxExpressionContainerPattern(
				AstPattern.or(
					// ... which is either a "simple object array" ...
					simpleObjectArrayPattern,
					// ... or it's "anything else" ...
					//
					// NOTE: This is a design flaw in the original spec for `JsxArgumentInfo`. This
					// is NOT necessarily a primitive, it's really anything at all, but that's what
					// we called it so we leave it for now.
					AstPattern.captureGroup(
						AstPattern.any(),
						(
							captured: { [key: string]: CapturedValue },
							acc: unknown,
							node: Expression
						) => ({
							type: "PrimitiveArgument",
							...captureJsxAttributeValue(node, true),
						})
					)
				)
			),
			// ... or a simple expression, e.g., `value="whatever"` ...
			AstPattern.any((value: Expression) => ({
				type: "PrimitiveArgument",
				...captureJsxAttributeValue(value, false),
			}))
		),
	}),
	(value, acc) => {
		// FINAL VALUE: Turn accumulated values into `RenderCallExpressionInfo`.
		acc.kind ||= "RenderCallExpressionInfo";
		acc.arguments ||= {};
		Object.values(value as AccumulatedValue[]).forEach((v: AccumulatedValue) => {
			acc.arguments[v.attributeName.name] = {
				...(v.fields && { fields: v.fields }),
				type: v.type,
				name: makeLocImmerSerializable(
					v.attributeName as unknown as { loc: SourceLocation | null }
				),
				value: { ...v.attributeValue },
			};
		});
		return acc;
	}
);

export const renderCallExpressionPattern = AstPattern.atRoot(
	blockStatementPattern(
		// Last statement in the fuction body...
		AstPattern.lastElement(
			// ... is a return statement ...
			returnStatementPattern(
				// ... that calls React's `render` function ...
				callExpressionPattern("render", [
					AstPattern.captureGroup(
						// ... and that argument is either an arrow function or a `function` ...
						AstPattern.or(
							arrowFunctionExpressionPattern(
								AstPattern.any(),
								jsxElementCaptureJsxIdentifierPattern(
									(rootJsxTag: { loc: SourceLocation | null }) => ({
										rootJsxTag: makeLocImmerSerializable(rootJsxTag),
									}),
									captureJsxAttributes
								)
							),
							arrowFunctionExpressionPattern(
								AstPattern.any(),
								// ... with a single `return` statement returning a JSX tag ...
								blockStatementPattern(
									AstPattern.lastElement(
										returnStatementPattern(
											// return render(() => <InputTextAalph />)
											jsxElementCaptureJsxIdentifierPattern(
												(rootJsxTag: { loc: SourceLocation | null }) => ({
													rootJsxTag:
														makeLocImmerSerializable(rootJsxTag),
												}),
												captureJsxAttributes
											)
										)
									)
								)
							),
							functionExpressionPattern(AstPattern.any(), [
								// ... with a single `return` statement returning a JSX tag ...
								returnStatementPattern(
									jsxElementCaptureJsxIdentifierPattern(
										(rootJsxTag: { loc: SourceLocation | null }) => ({
											rootJsxTag: makeLocImmerSerializable(rootJsxTag),
										}),
										captureJsxAttributes
									)
								),
							])
						),
						(captured, _acc, node) => {
							return {
								...captured,
								functionArgumentInRenderCall: node,
							};
						}
					),
				])
			)
		)
	)
);
