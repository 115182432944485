import {
	ApolloClient,
	ApolloLink,
	InMemoryCache,
	createHttpLink,
	defaultDataIdFromObject,
} from "@apollo/client";
import crossfetch from "cross-fetch";
import Cookies from "js-cookie";

import { generateShortUuid } from "@moment/api-collab/id";

import { canvasVersionsPaginatedFieldPolicy } from "~/api/canvas-versions";
import { MOMENT_FEATURES_HEADER, encodedFeatures } from "~/features";
import { type Env } from "~/utils/common";
import { Logger } from "~/utils/logging";

export const localMomentDomain = "localhost:8080";
export const stagingMomentDomain = "api-staging.moment.dev";
export const prodMomentDomain = "api.moment.dev";

export type FetchType = (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>;

const logger = new Logger("api/client");

export const getAccessToken = () => "";

export const apolloClient = (mode: Env, fetch?: FetchType) => {
	return new ApolloClient({
		cache: new InMemoryCache({
			dataIdFromObject: (object) => {
				switch (object.__typename) {
					case "Cell": {
						// Cache cells on their ID. This is either `null`, in which case it is never
						// cached, or it is unique, in which case it is cached appropriately.
						return object["id"]?.toString();
					}
					default: {
						return defaultDataIdFromObject(object);
					}
				}
			},
			typePolicies: {
				Query: {
					fields: {
						canvasVersionsPaginated: canvasVersionsPaginatedFieldPolicy,
					},
				},
			},
		}),
		link: makeLink(mode, fetch),
		// turn of cache for all queries and mutations made using apollo client
		defaultOptions: {
			watchQuery: {
				fetchPolicy: "no-cache",
			},
			query: {
				fetchPolicy: "no-cache",
			},
			mutate: {
				fetchPolicy: "no-cache",
			},
		},
	});
};

export const getHttpsURL = (mode: Env): string => {
	switch (mode) {
		case "staging":
			return `https://${stagingMomentDomain}`;
		case "production":
			return `https://${prodMomentDomain}`;
		case "testing":
		case "local":
		default:
			return `http://${localMomentDomain}`;
	}
};

const makeLink = (mode: Env, f?: FetchType) => {
	const links: ApolloLink[] = [];

	let isomorphicGlobalFetch = crossfetch;
	if (typeof window !== "undefined" && Object.prototype.hasOwnProperty.call(window, "fetch")) {
		isomorphicGlobalFetch = window.fetch;
	}
	const fetch = f || isomorphicGlobalFetch;

	const httpURL = getHttpsURL(mode);

	// ------------------------------------------------------------
	// ⚠️ Important
	// Links should remain in this order since they flow into each other.
	// authMiddleware -> organizationMiddleware -> timingLink -> httpLink
	// ------------------------------------------------------------

	// ------------------------------------------------------------
	// Auth Middleware
	// • binds auth token to all requests
	// ------------------------------------------------------------
	const authMiddleware = new ApolloLink((operation, forward) => {
		let token = "";
		if (typeof window !== "undefined") {
			token = getAccessToken();
		}

		// Add to cookies if and only if we're in the browser (i.e., not the next.js SSR).
		if (typeof window !== "undefined" && token) {
			Cookies.set("mom_auth_v1", token);
		} else {
			logger.error("moment auth token is null", { token });
		}

		// add the authorization to the headers
		operation.setContext(({ headers = {} }) => {
			return {
				headers: {
					...headers,
					"authorization": token ? `Bearer ${token}` : "",
					"X-Request-Id": generateShortUuid(),
				},
			};
		});

		return forward(operation);
	});

	links.push(authMiddleware);

	// ------------------------------------------------------------
	// Moment Middleware
	// • binds the feature flags header to requests
	// ------------------------------------------------------------
	const organizationMiddleware = new ApolloLink((operation, forward) => {
		// add the x-organization-domain custom header to the headers
		operation.setContext(({ headers = {} }) => {
			return {
				headers: {
					...headers,
					[MOMENT_FEATURES_HEADER]: encodedFeatures(),
				},
			};
		});

		return forward(operation);
	});

	links.push(organizationMiddleware);

	// ------------------------------------------------------------
	// HTTP Link
	// • specifies the url for our graphql endpoint
	// • wether requests should include the authorization token or not
	// • the fetch function to use
	// ------------------------------------------------------------
	const httpLink = createHttpLink({
		uri: `${httpURL}/query`,
		credentials: "include",
		// @ts-expect-error I'm not sure why this doesn't work
		fetch,
	});

	links.push(httpLink);

	return ApolloLink.from(links);
};
