import { customAlphabet } from "../nanoid";

// Just nanoid without symbols.
const nanoid = customAlphabet("abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789", 48);

export const createCellID = (len = 48): string => {
	// in case we ever decide to change how cell IDs are generated
	// in the future (e.g. if we want a prefix / suffix)

	return nanoid(len);
};
