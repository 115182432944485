import { Fragment, type Node } from "prosemirror-model";

/* Given a fragment, returns a new fragment with all data:src images removed */
export const removeDataSrcImagesFromFragment = (fragment: Fragment): Fragment => {
	const nodes: Node[] = [];

	for (let n = 0; n < fragment.childCount; n++) {
		const child = fragment.child(n);
		const images = findDataSrcImages(child);
		if (images.length === 0) {
			nodes.push(child);
		}
	}
	return Fragment.from(nodes);
};
/* Returns a list of all the data:src image nodes in a fragment
 * Used to detect the presense for alerting
 */
export const findDataSrcImagesInFragment = (fragment: Fragment): Node[] => {
	let images: Node[] = [];
	for (let n = 0; n < fragment.childCount; n++) {
		images = images.concat(findDataSrcImages(fragment.child(n)));
	}
	return images;
};

const isImageNode = (node: Node): boolean => {
	return node.type.name === "image";
};

const hasDataSrc = (node: Node): boolean => {
	return node.attrs["src"]?.startsWith("data");
};

export const findDataSrcImages = (node: Node): Node[] => {
	return findDataSrcImagesInner([], node);
};

const findDataSrcImagesInner = (images: Node[], node: Node): Node[] => {
	if (node.childCount === 0) {
		if (isImageNode(node) && hasDataSrc(node)) {
			return [node];
		}
	}

	for (let n = 0; n < node.childCount; n++) {
		images = images.concat(findDataSrcImages(node.child(n)));
	}

	return images;
};
