import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { isDesktopAppMode } from "~/auth/interop/common";

export const pageStorageSlice = createApi({
	reducerPath: "pageStorage",
	baseQuery: fetchBaseQuery({}),
	tagTypes: ["PageStorage"],
	endpoints: (build) => ({
		fetchPageStorage: build.query<object, { workspaceId; documentId; pageId }>({
			queryFn: async ({ workspaceId, documentId, pageId }) => {
				if (!workspaceId || !documentId || !pageId) {
					throw new Error("Missing required parameters");
				}

				if (!isDesktopAppMode()) {
					const path = `${workspaceId}/${documentId}/pages/page_${pageId}`;
					try {
						const response = await fetch(`/api/public/${path}/pageStorage.json`);
						if (!response.ok) {
							throw new Error("Failed to fetch public page storage");
						}

						const pageStorage = await response.json();

						return { data: pageStorage };
					} catch (error) {
						return { data: {} };
					}
				}

				const pageStorage = await window.desktopIpcApi?.fetchLocalPageStorage(
					workspaceId,
					documentId,
					pageId
				);
				if (!pageStorage) {
					throw new Error("Failed to fetch local page storage");
				}

				return { data: pageStorage };
			},
		}),

		writePageStorage: build.mutation<
			object,
			{
				workspaceId: string;
				documentId: string;
				pageId: string;
				pageStorageContents: object;
			}
		>({
			queryFn: async ({ workspaceId, documentId, pageId, pageStorageContents }) => {
				if (workspaceId !== "local") {
					throw new Error("Only local workspace is supported");
				}

				await window.desktopIpcApi?.writeLocalPageStorage(
					workspaceId,
					documentId,
					pageId,
					pageStorageContents
				);

				return { data: {} };
			},
		}),
	}),
});

export const { useFetchPageStorageQuery, useWritePageStorageMutation } = pageStorageSlice;
