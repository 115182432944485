import { type InputRule } from "prosemirror-inputrules";
import { type MarkType } from "prosemirror-model";

import { wrappingMarkRule } from "./wrappingMarkRule";

// Given a strong markType returns an input rule that turns `"**text**"` in a text block
// into an <b> tag.
export const strongAsterixRule = (markType: MarkType): InputRule => {
	return wrappingMarkRule(/\*\*(.+)\*\*/, markType, {});
};
