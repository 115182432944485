import { type Attrs, type DOMOutputSpec, type Node, type NodeSpec } from "prosemirror-model";

import { CELL_SPEC_ATTRS } from "../../prosemirror-utils/constants";
import { makeTextCellDefaultToDOMAttrs } from "../../prosemirror-utils/makeTextCellDefaultToDOMAttrs";

// :: NodeSpec A callout (`<callout>`) wrapping one or more blocks.

export const callout: NodeSpec = {
	content: "paragraph+",
	group: "block",
	defining: true,
	attrs: {
		...CELL_SPEC_ATTRS,
	},
	parseDOM: [{ tag: "callout" }],
	toDOM(node: Node) {
		const attrs: Attrs = makeTextCellDefaultToDOMAttrs(node, "block");

		const calloutDOM: DOMOutputSpec = ["callout", attrs, ["div", 0]];
		return calloutDOM;
	},
};
