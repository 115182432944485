import type { HTTPLogItem } from "@datadog/datadog-api-client/dist/packages/datadog-api-client-v2/models/HTTPLogItem";
import type { LogLevel, LogMessage, Transport } from "electron-log";

import { clientToken } from "../../token";
import { LogStorage } from "./logStorage";

const logStorage = new LogStorage();

const LOGS_PAYLOAD_SIZE_LIMIT = 5138022;
const LOG_SIZE_LIMIT = 996147;
const MAX_LOG_ITEMS = 995;
const PERIODIC_SEND_MS = 3000;

function processLog(message: LogMessage): Record<string, unknown> {
	// Split the data into the message and metadata.
	let msg = "";
	let metadata = {};
	let arrayData: unknown[] = [];

	for (const data of message.data) {
		if (typeof data === "string") {
			msg = msg + data;
			continue;
		}

		if (typeof data === "number" || typeof data === "boolean") {
			msg = msg + data.toString();
			continue;
		}

		if (Array.isArray(data)) {
			arrayData = arrayData.concat(data);
			continue;
		}

		if (typeof data === "object" && data !== null) {
			metadata = {
				...metadata,
				...data,
			};
		}

		// Handle other data types safely.
		msg = msg + String(data);
	}

	let item: Record<string, unknown> = {
		date: message.date.getTime(),
		level: message.level,
		message: msg,
	};

	if (Object.keys(metadata).length) {
		item = {
			...item,
			...metadata,
		};
	}

	if (arrayData.length) {
		item["arrayData"] = arrayData;
	}

	return item;
}

async function sendLogs(logsToSend: HTTPLogItem[], bucketName: string) {
	if (!logsToSend.length) {
		return;
	}

	try {
		await fetch("https://http-intake.logs.datadoghq.com/api/v2/logs", {
			body: JSON.stringify(logsToSend),
			method: "POST",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
				// For clients that can't protect a secret (browsers, desktop apps),
				// DD requires the use of a client token instead of an API key
				// but the header name is still DD-API-KEY.
				"DD-API-KEY": clientToken(),
			},
		});
	} catch (err) {
		console.error(`Failed to send logs for batch ${bucketName}`, err);
	}
}

function setupPeriodicLogsFlush() {
	setTimeout(async () => {
		try {
			const currentBucket = logStorage.currentBucket;
			await sendLogs(logStorage.finishLogBatch(), currentBucket);
			setupPeriodicLogsFlush();
		} catch (err) {
			console.error("could not send logs to DD", err);
		}
	}, PERIODIC_SEND_MS);
}

interface TransportSettings {
	appEnv: string;
	version: string;
	platform: typeof process.platform;
	level: LogLevel;
	service: string;
	ddsource: string;
}

export const initTransport = (settings: TransportSettings): Transport => {
	function transport(message: LogMessage) {
		const data = processLog(message);
		const logItem: HTTPLogItem = {
			message: JSON.stringify(data),
			ddsource: settings.ddsource,
			service: settings.service,
			ddtags: `env:${settings.appEnv},version:${settings.version},platform:${settings.platform}`,
		};
		const logEntryLength =
			logItem.message.length +
			(logItem.ddsource?.length || 0) +
			(logItem.ddtags?.length || 0) +
			(logItem.hostname?.length || 0) +
			(logItem.service?.length || 0);

		if (logEntryLength > LOG_SIZE_LIMIT) {
			// TODO: Perhaps we can send a truncated log message?
			console.error("Log item size exceeds DD limits. Will not send:", logItem);
			return;
		}

		logStorage.addLog(logItem, logEntryLength);

		// Check if logs should be sent based on size or count
		const logCount = logStorage.getLogCount();
		const shouldSend =
			logStorage.getLogBucketByteSize() > LOGS_PAYLOAD_SIZE_LIMIT || logCount > MAX_LOG_ITEMS;

		if (shouldSend) {
			const currentBucket = logStorage.currentBucket;

			sendLogs(logStorage.finishLogBatch(), currentBucket).catch((err) =>
				console.error("could not send logs to DD", err)
			);
		}
	}

	setupPeriodicLogsFlush();

	return Object.assign(transport, {
		level: settings.level,
		transforms: [],
	});
};
