import { giteaApi as createApi } from "gitea-js";

import { createDocsApiFetch, docsApiFetch, getDocsApiUrl } from "~/utils/docsapi";

import { type Repo, type Workspace } from "./types";

export const getGiteaApiUrl = getDocsApiUrl;

export const giteaClient = (token?: string) =>
	createApi(getGiteaApiUrl(), {
		customFetch: docsApiFetch(token ?? ""),
	});

export const queryReposByWorkspace = async (
	token: string,
	workspaceId: string
): Promise<Array<Repo>> => {
	const docsapiClient = createDocsApiFetch(token);
	const repos = await docsapiClient(`/repos?workspace=${encodeURIComponent(workspaceId)}`, {
		method: "GET",
	});

	return repos;
};

export const getWorkspaces = async (token: string): Promise<Array<Workspace>> => {
	const docsapiClient = createDocsApiFetch(token);
	const repos = await docsapiClient(`/workspaces`, { method: "GET" });

	return repos;
};

export const getPublicRepos = async (): Promise<Array<Repo>> => {
	const url = `${getGiteaApiUrl()}/public/v1/help/repos`;
	const fetch = docsApiFetch("");
	const response = await fetch(url, { method: "GET" });
	return response.json();
};

export const getPublicWorkspaces = async (): Promise<Array<Workspace>> => {
	const url = `${getGiteaApiUrl()}/public/v1/workspaces`;
	const fetch = docsApiFetch("");
	const response = await fetch(url, { method: "GET" });
	return response.json();
};
