import {
	chainCommands,
	deleteSelection,
	joinBackward,
	joinForward,
	liftEmptyBlock,
	newlineInCode,
	selectAll,
	selectNodeBackward,
	splitBlock,
} from "prosemirror-commands";
import { liftListItem, sinkListItem } from "prosemirror-schema-list";

import { schema } from "@moment/api-collab/prosemirror-schema";

import { createEmptyParagraphBeforeIfComputeCellSelected } from "./createEmptyParagraphBeforeIfComputeCellSelected";
import { createParagraphNear } from "./createParagraphNear";
import {
	indentListItem,
	splitListItem,
	unindentEmptyListItem,
	unindentListItem,
} from "./distinctListItems";
import { escapeCodeBlock } from "./escapeCodeBlock";
import { handleArrowDownInComputeCell } from "./handleArrowDownInComputeCell";
import { handleArrowLeftInComputeCell } from "./handleArrowLeftInComputeCell";
import { handleArrowRightInComputeCell } from "./handleArrowRightInComputeCell";
import { handleArrowUpInComputeCell } from "./handleArrowUpInComputeCell";
import { ignoreIfAllSelection } from "./ignoreIfAllSelection";
import { replaceComputeCellWithEmptyParagraphIfSinglySelected } from "./replaceComputeCellWithEmptyParagraphIfSinglySelected";
import { replaceDocWithEmptyParagraphIfAllSelection } from "./replaceDocWithEmptyParagraph";
import { replaceInvalidDeletes } from "./replaceInvalidDeletes";

export const keyMap = ({
	onModEnter,
}: {
	onModEnter?: () => void;
} = {}) => {
	return {
		// IMPORTANT: Mod-a should produce an `AllSelection`. If it doesn't you can't select a
		// `compute_cell` that appears first in the document. This is NOT the default behavior.
		"Mod-a": selectAll,

		"ArrowDown": handleArrowDownInComputeCell,
		"ArrowRight": handleArrowRightInComputeCell,
		"ArrowUp": handleArrowUpInComputeCell,
		"ArrowLeft": handleArrowLeftInComputeCell,

		"Enter": chainCommands(
			replaceDocWithEmptyParagraphIfAllSelection,
			createEmptyParagraphBeforeIfComputeCellSelected,
			splitListItem(schema.nodes.list_item),
			splitListItem(schema.nodes.to_do_item),
			escapeCodeBlock,
			newlineInCode,
			createParagraphNear,
			unindentEmptyListItem,
			liftEmptyBlock,
			splitBlock
		),

		"Mod-Backspace": chainCommands((state, dispatch) => {
			const { $from, $to } = state.selection;
			if (!dispatch || !$from || !$to) {
				return false;
			}

			const lineStart = state.doc.resolve($from.start());
			dispatch(state.tr.delete(lineStart.pos, $from.pos).deleteSelection());
			return true;
		}),
		"Backspace": chainCommands(
			replaceDocWithEmptyParagraphIfAllSelection,
			replaceInvalidDeletes,
			replaceComputeCellWithEmptyParagraphIfSinglySelected,
			deleteSelection,
			joinBackward,
			selectNodeBackward
		),

		"Delete": chainCommands(
			replaceDocWithEmptyParagraphIfAllSelection,
			replaceInvalidDeletes,
			replaceComputeCellWithEmptyParagraphIfSinglySelected,
			deleteSelection,
			joinForward
		),

		"Tab": chainCommands(
			ignoreIfAllSelection,
			indentListItem,
			sinkListItem(schema.nodes.list_item),
			// Returning true here will prevent the default <tab> behavior of blurring (un-focusing)
			// the editor.
			() => true
		),
		"Shift-Tab": chainCommands(
			ignoreIfAllSelection,
			unindentListItem,
			liftListItem(schema.nodes.list_item),
			// Returning true here will prevent the default <tab> behavior of blurring (un-focusing)
			// the editor.
			() => true
		),

		"Mod-Enter": chainCommands(() => {
			onModEnter?.();
			return false; // Returning false will continue the default Mod+Enter behavior
		}),
	};
};
