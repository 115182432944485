import { type EditorState } from "prosemirror-state";

import { documentPlaceholderName } from "../plugins/document-placeholder-plugin/documentPlaceholderPlugin";
import { type CellPosition } from "./types";

export const getCellPositions = (state: EditorState): CellPosition[] => {
	const nodes: CellPosition[] = [];

	let index = 0;
	let pos = state.doc.resolve(index);
	let node = pos.nodeAfter;

	const end = pos.parent.nodeSize;

	while (index < end) {
		if (!node) {
			break;
		}

		if (node.type.name === documentPlaceholderName) {
			index = index + node.nodeSize;
			continue;
		}
		if (node.attrs["id"]) {
			const nodeType = node.type.name === "compute_cell" ? "compute_cell" : "text_cell";
			nodes.push({
				node,
				depth: 1,
				before: index,
				start: index + 1,
				end: index + node.nodeSize - 1,
				after: index + node.nodeSize,
				cellIndex: nodes.length,
				id: node.attrs["id"],
				type: nodeType,
			});

			index = index + node.nodeSize;
		}

		pos = state.doc.resolve(index);
		node = pos.nodeAfter;
	}

	return nodes;
};
