import { sluggify } from "@moment/app/src/utils/helpers/string";

/**
 * A class that can be used to generate slugs while ensuring that they are unique.
 * Repeated slugs are suffixed with a hyphen and a number.
 * Missing slugs are replaced with a number
 */
export class UniqueSluggifier {
	private _duplicateCounters: Record<string, number> = {};

	resolveDuplicate(slug: string): string {
		const counter = (this._duplicateCounters[slug] ?? 0) + 1;
		this._duplicateCounters[slug] = counter;
		if (counter === 1) {
			return slug || `${counter}`;
		}

		return this.resolveDuplicate(`${slug ? `${slug}-${counter}` : counter}`);
	}

	generateSlug(title: string) {
		return this.resolveDuplicate(sluggify(title));
	}
}
