import { useMutation } from "@tanstack/react-query";
import { useRouter } from "next/router";

import { useAuth } from "~/auth/useAuth";
import { giteaClient } from "~/utils/gitea";

import { giteaQueryKeys } from ".";

export const useCreateRepoMutation = () => {
	const { getAccessTokenSilently, user } = useAuth();
	const router = useRouter();

	return useMutation({
		mutationKey: giteaQueryKeys.createRepo,
		mutationFn: async ({ name, workspaceId }: { name: string; workspaceId: string }) => {
			const token = await getAccessTokenSilently(true, router.asPath);
			const gitea = giteaClient(token);

			if (user?.giteaUserId === workspaceId) {
				const { data } = await gitea.user.createCurrentUserRepo({
					name,
					private: true,
				});

				return data;
			} else {
				const { data } = await gitea.orgs.createOrgRepo(workspaceId, {
					name,
					private: true,
				});

				return data;
			}
		},
	});
};
