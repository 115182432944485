import { type Node } from "prosemirror-model";

import { schema } from "@moment/api-collab/prosemirror-schema";
import { DEFAULT_CELL_ID } from "@moment/api-collab/prosemirror-utils";

export type MomentCellType = "text_cell" | "compute_cell";

export const isMomentCellType = (type: string): boolean => {
	return type === "text_cell" || type === "compute_cell";
};

export const isTextCell = (n: Node) => {
	return (
		n.attrs["id"] !== DEFAULT_CELL_ID &&
		n.attrs["id"] !== undefined &&
		n.type !== schema.nodes.compute_cell
	);
};

export const isComputeCell = (n: Node) => {
	return n.type === schema.nodes.compute_cell;
};

export type CellPosition = {
	node: Node;
	depth: number;
	before: number;
	start: number;
	end: number;
	after: number;
	cellIndex?: number;
	type: MomentCellType;
	id: string;
};
