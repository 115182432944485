import { useQuery } from "@tanstack/react-query";

import { isDesktopAppMode } from "~/auth/interop/common";
import { useAuth } from "~/auth/useAuth";
import { getWorkspaces } from "~/utils/gitea";

import { type Workspace, WorkspaceSchema, workspaceQueryKeys } from ".";

export const isMomentWorkspace = (workspace: Workspace) => {
	return workspace.id === "moment" || workspace.id === "moment-eng";
};

export const useWorkspacesQuery = () => {
	const { getAccessTokenSilently } = useAuth();

	const remoteWorkspacesQuery = useQuery({
		queryKey: workspaceQueryKeys.workspaces("remote"),
		queryFn: async (): Promise<Workspace[]> => {
			const token = await getAccessTokenSilently();

			// We cannot fetch remote workspaces without a token
			if (token === undefined) {
				return [];
			}

			const data = await getWorkspaces(token);

			const workspaces = data.map((workspace) =>
				WorkspaceSchema.parse({
					id: workspace.sluggified_user_id,
					name: workspace.display_name,
					documents: {},
					recentlyViewed: [],
				})
			);

			return Array.from(workspaces.values());
		},
	});

	const localWorkspacesQuery = useQuery({
		queryKey: workspaceQueryKeys.workspaces("local"),
		queryFn: async (): Promise<Workspace[]> => {
			return [
				WorkspaceSchema.parse({
					id: "local",
					name: "Local",
				}),
			];
		},
		enabled: isDesktopAppMode(),
	});

	return {
		data: [...(localWorkspacesQuery.data ?? []), ...(remoteWorkspacesQuery.data ?? [])],
		isFetching: localWorkspacesQuery.isFetching || remoteWorkspacesQuery.isFetching,
		isSuccess: localWorkspacesQuery.isSuccess && remoteWorkspacesQuery.isSuccess,
		isLoading: localWorkspacesQuery.isLoading || remoteWorkspacesQuery.isLoading,
		isError: localWorkspacesQuery.isError || remoteWorkspacesQuery.isError,
		refetch: () => {
			void localWorkspacesQuery.refetch();
			void remoteWorkspacesQuery.refetch();
		},
	};
};
