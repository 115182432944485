import { type EditorState, type PluginKey, type PluginView } from "prosemirror-state";
import { type EditorView } from "prosemirror-view";

import { canvasLayoutVisualDebugEvent } from "~/components/common/visual-debugging/useCanvasLayoutVisualDebug";
import { extractJSON } from "~/utils/helpers/json";
import { LOCAL_STORAGE_KEYS } from "~/utils/storage";

//
// VISUAL DEBUGGING MANAGER
//
// This prosemirror plugin is only attached to the editor if the
// CanvasLayoutVisualDebugging feature is enabled.
//
// It works by creating a new stylesheet along with new dom elements
// to visually see where elements are positioned in the editor.
//

export type VisualDebugManagerProps = {
	readonly view: EditorView;
	readonly key: PluginKey;
};

export class VisualDebugManager implements PluginView {
	view: VisualDebugManagerProps["view"];
	key: VisualDebugManagerProps["key"];

	state: string;
	styleElement: HTMLStyleElement;

	constructor(props: VisualDebugManagerProps) {
		this.view = props.view;
		this.key = props.key;

		this.styleElement = document.createElement("style");
		this.styleElement.setAttribute("data-canvas-layout-visual-debug", "true");

		this.state = "";

		this.storageEventHandler = this.storageEventHandler.bind(this);
		this.updateStyles = this.updateStyles.bind(this);

		this.init();
	}

	// --------------------------------------------------------------------
	// getters
	// --------------------------------------------------------------------
	get parentSelector(): string {
		const selector: string[] = [];

		const el = this.view.dom;

		const id = el.id;

		if (id) {
			selector.push(`#${id}`);
		}

		const classNames = Array.from(el.classList);

		if (classNames.length > 0) {
			classNames.forEach((c) => {
				selector.push(`.${c}`);
			});
		}

		return selector.join("");
	}

	get cssText(): string {
		const rules = [
			//
			//	text_cell and compute_cell
			//
			// add red border around text_cells and compute_cells
			`${this.parentSelector} p[data-text-cell],
			${this.parentSelector} compute_cell {
				position: relative;
				box-shadow: inset 0px 0px 0px 1px rgba(255, 0, 0, 0.5)!important;
			}`,
			// add red background to text_cell and compute_cell
			`${this.parentSelector} p[data-text-cell]:before,
			${this.parentSelector} compute_cell:before {
				content: '';
				display: block;
				position: absolute;
				inset: 0;
				background:  rgba(255, 0, 0, 0.1)!important;
				pointer-events: none;
			}`,
			// add id labels
			`${this.parentSelector} p[data-text-cell]:after,
			${this.parentSelector} compute_cell:after,
			[data-page-list-item]:after,
			[data-comment-thread]:after {
				content: attr(id) '/' attr(data-slug);
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				background: rgba( 255, 0, 0, 0.2)!important;
				color: rgba(255, 255, 255, 0.9);
				font-family: monospace;
				font-size: 8pt;
				padding: 0.2em;
				pointer-events: none;
				border-radius: 0 0 0.2em 0;
				white-space: pre-wrap;
			}`,

			//
			// Margins
			//
			// add cyan border around content
			`div[data-row-content] {
				box-shadow: inset 0px 0px 0px 1px rgba(0, 255, 255, 1.0)!important;
			}`,

			//
			// Bottom Margin
			//
			// add blue border around bottom margin
			`#bottom-margin {
				position: relative;
				box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 255, 0.5)!important;
			}`,
			// add blue background to bottom margin
			`#bottom-margin:before{
				content: '';
				display: block;
				position: absolute;
				inset: 0;
				background: rgba(0, 0, 255, 0.1)!important;
				pointer-events: none;
			}`,
			// add blue id label to bottom margin
			`#bottom-margin:after {
				content: 'Bottom Margin';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				background: rgba(0, 0, 255, 0.2)!important;
				color: rgba(255, 255, 255, 0.9);
				font-family: monospace;
				font-size: 8pt;
				padding: 0.2em;
				pointer-events: none;
				border-radius: 0 0 0.2em 0;
			}`,
		];

		const cssText = rules.reduce((acc, rule) => {
			return `${acc}\n${rule}`;
		}, ``);

		return cssText;
	}

	// --------------------------------------------------------------------
	// private methods
	// --------------------------------------------------------------------
	private init(): void {
		// append stylesheet to document body
		document.body.appendChild(this.styleElement);

		const currentState = localStorage.getItem(LOCAL_STORAGE_KEYS.CANVAS_LAYOUT_VISUAL_DEBUG);

		if (currentState) {
			this.state = extractJSON(currentState, "");
		}

		this.subscribeToLocalStorage();
		this.updateStyles();
	}

	private storageEventHandler(): void {
		const nextState = localStorage.getItem(LOCAL_STORAGE_KEYS.CANVAS_LAYOUT_VISUAL_DEBUG);

		if (!nextState) {
			this.state = "";
			return;
		}

		this.state = extractJSON(nextState, "");
		this.updateStyles();
	}

	private subscribeToLocalStorage(): void {
		window.addEventListener(canvasLayoutVisualDebugEvent.type, this.storageEventHandler);
	}

	private unsubscribeFromLocalStorage(): void {
		window.removeEventListener(canvasLayoutVisualDebugEvent.type, this.storageEventHandler);
	}

	private updateStyles(): void {
		if (this.state === "enabled") {
			this.styleElement.innerHTML = this.cssText;
			return;
		}

		this.styleElement.innerHTML = "";
	}

	// --------------------------------------------------------------------
	// public methods
	// --------------------------------------------------------------------
	update(_view: EditorView, _lastState?: EditorState): void {
		this.updateStyles();
	}

	destroy(): void {
		this.unsubscribeFromLocalStorage();

		// remove style element from document body
		this.styleElement.remove();
	}
}
