export const stringOr = <T>(input: unknown, fallbackValue: T): string | T => {
	if (typeof input === "string") {
		return input;
	}
	return fallbackValue;
};

const slugRE = /[^0-9a-zA-Z]+/g;

export const sluggify = (s: string): string => {
	return s.toLowerCase().replace(slugRE, " ").trim().replace(/\s/g, "-");
};
