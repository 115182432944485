import dynamic from "next/dynamic";
import React, { type FC, useEffect } from "react";
import { type FallbackProps } from "react-error-boundary";

import type { ButtonProps } from "@moment/design-system/Button";
import { Icon, type MomentIconType } from "@moment/design-system/Icon";

import { log } from "~/utils/datadog/datadog";
import { clearStorage } from "~/utils/storage";

// Dynamically load ~90Kb Carbon icons chunk.
const Help = dynamic(
	import("@carbon/icons-react").then((m) => m.Help),
	{ ssr: false }
);

// Dynamically load ~30Kb button chunk.
const Button = dynamic(
	import("@moment/design-system/Button").then((m) => m.Button as FC<ButtonProps>),
	{ ssr: false }
);

/**
 * 1) we want to know immediately if this component is ever rendered in prod
 * 2) we want to communicate to users that we are proactive in resolving these issues
 */

export const FatalAppError: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
	const actions = (
		<>
			<Button
				onClick={() => {
					clearStorage();
					window.location.reload();
				}}
			>
				Clear Browser Storage
			</Button>
			<Button level="primary" onClick={() => resetErrorBoundary()}>
				Refresh Page
			</Button>
		</>
	);

	useEffect(() => {
		log("Fatal App Error", { error: `${error}` }, "error");
	}, [error]);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				position: "fixed",
				top: 0,
				left: 0,
				width: "100vw",
				height: "100vh",
				overflow: "auto",
				backgroundColor: "#fff",
			}}
		>
			<div className="flex size-full flex-col">
				<div className="flex grow flex-col overflow-auto">
					<div className="flex grow flex-col items-center justify-center space-y-9 p-4">
						<div
							style={{ width: "112px", height: "112px" }}
							className="flex items-center justify-center rounded-full border"
						>
							<Icon icon={Help as MomentIconType} size={32} />
						</div>
						<div>
							<h1 className="text-secondary text-lg font-normal">
								Something went wrong
							</h1>
						</div>
						<div className={"flex space-x-2"}>{actions}</div>
					</div>
				</div>
			</div>
		</div>
	);
};
