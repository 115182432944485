import z from "zod";

import { safeParse, safeStringify } from "../json";
import { type LoggerInterface } from "../logger-types";
import { type Attrs, Fragment, Node } from "../prosemirror-model";
import { schema } from "../prosemirror-schema";
import { DEFAULT_CELL_ID, createCellID } from "../prosemirror-utils";

// Returns an ordered list of cells constructed from the document.
export const documentToCellCode = (doc: Node): Cell[] => {
	const cells: Cell[] = [];

	doc.content.forEach((child: Node) => {
		const sanitizedNodeAttrs = sanitizeParsedNodeAttrs(child.attrs);
		// Construct the current partially translated cell using the sanitized node attr properties.
		const partialCell: Omit<Cell, "type"> = {
			code: child.attrs["code"],
			id: child.attrs["id"],
			cellName: child.attrs["cellName"],
			inspectorInitiallyExpanded: child.attrs["inspectorInitiallyExpanded"] ?? false,
		};
		// The objective of the parent function is to serialize the input document into a
		// format that can be sent over the wire. The node attr representation for the wire
		// serialization is a record of string to string. This will translate the sanitized node
		// attrs to string.
		const nodeAttrForPersistence = nodeAttrToRecord(sanitizedNodeAttrs);
		switch (child.type.name) {
			case "text_cell": {
				cells.push({
					id: partialCell.id,
					type: CellType.ProseMirrorJSONCell,
					cellName: partialCell.cellName,
					inspectorInitiallyExpanded: partialCell.inspectorInitiallyExpanded,
					code: safeStringify({
						...child.toJSON(),
						attrs: sanitizedNodeAttrs,
					}),
					nodeAttrs: nodeAttrForPersistence,
				});
				break;
			}
			case "compute_cell": {
				cells.push({
					id: partialCell.id,
					type: CellType.JavaScriptFunctionBodyCell,
					code: child.textContent ?? partialCell.code,
					cellName: partialCell.cellName,
					inspectorInitiallyExpanded: partialCell.inspectorInitiallyExpanded,
					nodeAttrs: nodeAttrForPersistence,
				});
				break;
			}
			case "document_placeholder":
				// IMPORTANT: Avoid crashing (e.g.) Redux by skipping node types that aren't really
				// part of a document, i.e., can't be selected, are subject to 0 ProseMirror
				// operations, and so on.
				break;
			default: {
				const cellId = partialCell.id === DEFAULT_CELL_ID ? createCellID() : partialCell.id;
				const text_cell_wrapper = {
					type: "text_cell",
					attrs: {
						...partialCell,
						id: cellId,
						isCollapsed: false,
					},
					content: [
						{
							...child.toJSON(),
							attrs: sanitizedNodeAttrs,
						},
					],
				};
				cells.push({
					id: cellId,
					type: CellType.ProseMirrorJSONCell,
					cellName: partialCell.cellName,
					inspectorInitiallyExpanded: partialCell.inspectorInitiallyExpanded,
					code: safeStringify(text_cell_wrapper),
					nodeAttrs: nodeAttrForPersistence,
				});
				break;
			}
		}
	});
	return cells;
};

export const cellsToDocument = (logger: LoggerInterface, cells: Cell[]): Node => {
	if (cells.length === 0) {
		return schema.nodes.doc.createChecked(
			{},
			schema.nodes.document_placeholder.createChecked()
		);
	}
	const cellNodes = cells.map((cell) => cellToNodes(logger, cell));
	const cellNodesSpread = cellNodes.flat();
	return schema.nodes.doc.createChecked({}, cellNodesSpread);
};

export enum CellType {
	ProseMirrorJSONCell = "ProseMirrorJSONCell",
	JavaScriptFunctionBodyCell = "JavaScriptFunctionBodyCell",
}

// Schema based on GQL response
export const cell = z.object({
	type: z.nativeEnum(CellType),
	cellName: z.string().optional().nullable(),
	code: z.string(),
	inspectorInitiallyExpanded: z.optional(z.boolean()),
	id: z.string(),
	// Note: this diverges from the actual GQL schema because this object is used within the application layer.
	// The application layer appreciates a record over a string that that needs to be parsed in order to be used.
	nodeAttrs: z.record(z.string(), z.string()).optional().nullable(),
});

export type Cell = z.infer<typeof cell>;

export const page = z.object({
	id: z.string(),
	title: z.string(),
	canvasID: z.string(),
	authorID: z.string(),
	slug: z.optional(z.string()),
	cells: z.array(cell),
	latestVersion: z.number(),
});
export type Page = z.infer<typeof page>;

function parseNodeAttrs(
	nodeAttrs: string | Record<string, unknown> | undefined | null,
	logger: LoggerInterface
): Record<string, unknown> | null {
	if (!nodeAttrs) {
		return null;
	} else if (typeof nodeAttrs === "string") {
		return safeParse(nodeAttrs, logger);
	}

	// nodeAttr is a record.
	const parsedNodeAttrs: Record<string, unknown | undefined> = {};
	for (const [key, value] of Object.entries(nodeAttrs)) {
		if (key === "checksum_debug") {
			continue;
		}
		// undefined values sometimes get serialized into `"undefined"` as string.
		if (value === undefined || value === "undefined") {
			parsedNodeAttrs[key] = undefined;
		}
		parsedNodeAttrs[key] = value;
	}
	return nodeAttrs;
}

export const cellToNodes = (logger: LoggerInterface, cell: Cell): Node[] => {
	try {
		switch (cell.type) {
			// FIXME: This should be using the enums.
			case "ProseMirrorJSONCell": {
				try {
					if (cell["code"] === "") {
						const args: MakeTextCellArgs = {
							nodeAttrs: parseNodeAttrs(cell.nodeAttrs, logger) ?? undefined,
						};
						if (cell.id) {
							args.cellID = cell.id;
						}
						return makeTextCell(args);
					}
					return nodeFromJSONString(
						cell.id === null ? undefined : cell.id,
						cell["code"],
						logger
					);
				} catch (e) {
					logger.error("creating text cell failed", { e, cell });
					const fallbackArgs: MakeTextCellArgs = cell.id ? { cellID: cell.id } : {};
					return makeTextCell(fallbackArgs);
				}
			}
			case "JavaScriptFunctionBodyCell": {
				const cellIDCodeCellNameNodeAttrs: MakeComputeCellArgs = {
					code: cell.code,
					nodeAttrs: parseNodeAttrs(cell.nodeAttrs, logger) ?? undefined,
				};
				if (cell.id) {
					cellIDCodeCellNameNodeAttrs.cellID = cell.id;
				}
				if (cell.cellName) {
					cellIDCodeCellNameNodeAttrs.cellName = cell.cellName;
				}
				return [makeComputeCell(cellIDCodeCellNameNodeAttrs)];
			}

			default:
				throw new Error(`Unrecognized cell type ${cell["type"]}`);
		}
	} catch (error) {
		logger.error("error parsing cell to nodes", {
			error,
			cell: safeStringify(cell),
		});
		throw error;
	}
};

// FIXME: De-duplicate this from the easel prosemirror code.
type MakeTextCellArgs = {
	cellID?: string;
	content?: Fragment;
	nodeAttrs?: Record<string, unknown>;
};

// FIXME: De-duplicate this from the easel prosemirror code.
export const makeTextCell = (args: MakeTextCellArgs) => {
	const cellID = args.cellID || createCellID();

	const content = args.content || Fragment.fromArray([schema.nodes.paragraph.createChecked()]);
	const nodeAttrs = sanitizeParsedNodeAttrs(args);
	const attrs: Attrs = {
		id: cellID,
		...nodeAttrs,
	};

	const nodes: Node[] = [];

	for (let i = 0; i < content.childCount; i++) {
		const child = content.child(i);
		if (child !== null) {
			const sanitizedAttrs = sanitizeParsedNodeAttrs(child.attrs);
			nodes.push(child.type.createChecked({ ...sanitizedAttrs, ...attrs }, child.content));
		}
	}

	return nodes;
};

const nodeFromJSONString = (
	cellID: string | undefined,
	content: string,
	logger: LoggerInterface
): Node[] => {
	const parsed = safeParse(content, logger);
	if (parsed.attrs) {
		parsed.attrs = sanitizeParsedNodeAttrs(parsed.attrs);
	}
	const parsedNode = Node.fromJSON(schema, parsed);

	/* Note: There are two kinds of ProseMirrorJSON cells in the wild
	 * ones where the content string encodes an entire document. These
	 * cells were from a time when ever cell initialized it's own
	 * prosemirror editor.
	 *
	 * ```json
	 *  {
	 *      type: "doc",
	 *      content: [
	 *          {
	 *              type: "paragraph",
	 *              content: [{ type: "text", text: "xyz" }],
	 *          },
	 *      ],
	 *  };
	 * ```
	 *
	 * Alternatively cells created after we had all content in one large
	 * prosemirror editor look like this:
	 *
	 * ```json
	 *  {
	 *      type: "text_cell",
	 *      attrs: {
	 *          id: "test-a",
	 *      },
	 *      content: [
	 *          {
	 *              type: "paragraph",
	 *              content: [{ type: "text", text: "abc" }],
	 *          },
	 *      ],
	 *  };
	 *```
	 * In both cases the easy thing to do is just grab the content field
	 * and replace the id with the cell id we already have.
	 *
	 */

	const newNodeAttrs = parsedNode.attrs && { ...parsedNode.attrs, id: cellID };
	return makeTextCell({
		cellID,
		content: parsedNode.content,
		nodeAttrs: newNodeAttrs,
	});
};

type MakeComputeCellArgs = {
	cellID?: string | undefined;
	code: string;
	cellName?: string | undefined;
	nodeAttrs?: Record<string, unknown>;
};

// This is specifically used for translating GQL shapes to prosemirror nodes.
// There is a need to sanitize node attr key/values in node attrs when consuming from the backend/DB.
// The main key to ignore is `checksum_debug` for old canvases where the `checksum_debug` data got persisted.
// The current set up is to also avoid sending the `checksum_debug` key and value in the node attrs when initiating
// persistence.
export const sanitizeParsedNodeAttrs = (input: Record<string, unknown> | undefined): Attrs => {
	const output: Record<string, unknown> = {};
	if (input === undefined) {
		return output;
	}
	for (const k in input) {
		if (k === "checksum_debug" || input[k] === undefined) {
			continue;
		}

		output[k] = input[k];
	}
	return output;
};

// This specifically is used for translating to a GQL shape from a prosemirror node attr to a record of string to string.
const nodeAttrToRecord = (input: Record<string, unknown> | undefined): Record<string, string> => {
	const result: Record<string, string> = {};
	for (const k in input) {
		if (input[k] === undefined) {
			continue;
		}
		result[k] = String(input[k]);
	}
	return result;
};

export const makeComputeCell = ({ cellID, code, cellName, nodeAttrs }: MakeComputeCellArgs) => {
	const nodeAttrsWithoutID = sanitizeParsedNodeAttrs(nodeAttrs);
	let attrs: Attrs = {
		code,
		...nodeAttrsWithoutID,
		id: cellID, // Overwrite the ID here if available.
	};
	if (cellName && cellName !== "") {
		attrs = {
			...attrs,
			// VERY IMPORTANT FIXME: the `nodeAttrs.cellName` overrides `cellName` sometimes, so
			// pressing <enter> in some canvases overwrites ~all the cell names, completely breaking
			// it. I do not have a permanent fix, this is just to stop the bleeding.
			cellName,
		};
	}
	return schema.nodes.compute_cell.createChecked(attrs, schema.text(code));
};
