import { InputRule } from "prosemirror-inputrules";

//
// Replaces character combinations with their special typographic counterparts
//

type ReplacementMap = Array<{ displayText: string; pattern: RegExp }>;

const replacementMap: ReplacementMap = [
	// right facing arrow
	{
		displayText: "→ ",
		pattern: /->\s/,
	},
	// three right facing arrows
	{
		displayText: "⇶ ",
		pattern: /→→→\s/,
	},
	// left facing arrow
	{
		displayText: "← ",
		pattern: /<-\s/,
	},
	// double right facing arrow
	{
		displayText: "⇒ ",
		pattern: /=>\s/,
	},
	// ellipsis
	{
		displayText: "… ",
		pattern: /\.\.\.\s/,
	},
	// these don't seem to work but i would like them to someday
	// // copyright
	// {
	//     displayText: "© ",
	//     pattern: /\((c|C)\)\s/,
	// },
	// // rights reserved
	// {
	//     displayText: "® ",
	//     pattern: /\((r|R)\)\s/,
	// },
	// not equal to
	{
		displayText: "≠ ",
		pattern: /!=\s/,
	},
	// not identical to
	{
		displayText: "≢ ",
		pattern: /!==\s/,
	},
	// asymptotically equal to
	{
		displayText: "≃ ",
		pattern: /~=\s/,
	},
	// not asymptotically equal to
	{
		displayText: "≄ ",
		pattern: /!~=\s/,
	},
	// proportional to
	{
		displayText: "∷ ",
		pattern: /::\s/,
	},
	// less than or equal to
	{
		displayText: "≤ ",
		pattern: /<=\s/,
	},
	// greater than or equal to
	{
		displayText: "≥ ",
		pattern: />=\s/,
	},
	// less than or identical to
	{
		displayText: "≦ ",
		pattern: new RegExp(["<", "=="].join("")),
	},
	// greater than or identical to
	{
		displayText: "≧ ",
		pattern: />==\s/,
	},
	// identical to
	{
		displayText: "≡",
		pattern: /==\s/,
	},
	// strictly identical to
	{
		displayText: "≣",
		pattern: /===\s/,
	},

	// just for fun

	// rose
	{
		displayText: "🌹 ",
		pattern: /@}*>+[-——]{2,}\s/,
	},
	// fish
	{
		displayText: "🐟 ",
		pattern: /<><\s/,
	},
	// heart
	{
		displayText: "❤️ ",
		pattern: /<3\s/,
	},
	// small smile
	{
		displayText: "🙂 ",
		pattern: /:\)\s/,
	},
	// big smile
	{
		displayText: "😄 ",
		pattern: /:D\s/,
	},
	// neutral face
	{
		displayText: "😐 ",
		pattern: /:\|\s/,
	},
	// shrug
	{
		displayText: "¯\\_(ツ)_/¯ ",
		pattern: /:shrug:\s/,
	},
	// +1
	{
		displayText: "👍 ",
		pattern: /:\+1:\s/,
	},
	// -1
	{
		displayText: "👎 ",
		pattern: /:-1:\s/,
	},
	// 100
	{
		displayText: "💯 ",
		pattern: /:100:\s/,
	},
	// white check mark
	{
		displayText: "✅ ",
		pattern: /:white_check_mark:\s/,
	},
	// clap
	{
		displayText: "👏 ",
		pattern: /:clap:\s/,
	},
	// fire
	{
		displayText: "🔥 ",
		pattern: /:fire:\s/,
	},
	// alert
	{
		displayText: "🚨 ",
		pattern: /:alert:\s/,
	},
];

export const specialCharacters = replacementMap.map(({ pattern, displayText }) => {
	return new InputRule(pattern, displayText);
});
