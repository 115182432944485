import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { type Action, type Reducer } from "redux";

import type { ActiveUser } from "@moment/api-collab/api-types";

import { type CellID } from "~/models/cell";
import { codeEditorSlice, initialState as editorInitialState } from "~/store/code-editor/slice";

import { type CanvasState, NoActiveCanvasError } from "./types";

const initialState: CanvasState = {
	editor: editorInitialState,
	propertiesPaneLoading: false,
	mode: "document",
	activeUsers: [],
	activeDocumentID: undefined,
	activePageID: undefined,
} as CanvasState;

export const canvasSlice = createSlice({
	name: "canvas",
	initialState,
	reducers: {
		unsetCanvas: () => {
			return initialState;
		},

		setInspectingCell: (state, action: PayloadAction<{ id: CellID }>) => {
			const { id } = action.payload;
			if (state.activePageID === undefined) {
				throw new NoActiveCanvasError();
			}
			state.inspectingCell = id;
		},

		unsetInspectingCell: (state) => {
			if (state.activePageID === undefined) {
				throw new NoActiveCanvasError();
			}

			state.inspectingCell = undefined;
		},

		setActiveUsers: (state, action: PayloadAction<{ users: ActiveUser[] }>) => {
			const { users } = action.payload;
			state.activeUsers = users;
		},

		activeDocumentChanged: (state, action: PayloadAction<{ documentId?: string }>) => {
			const { documentId } = action.payload;

			state.activeDocumentID = documentId;
			state.activePageID = undefined;
		},

		activePageChanged: (state, action: PayloadAction<{ pageId?: string }>) => {
			const { pageId } = action.payload;

			state.activePageID = pageId;
		},
	},
});

export const { setInspectingCell, unsetInspectingCell, setActiveUsers, activePageChanged } =
	canvasSlice.actions;

export const canvas: Reducer<CanvasState> = (
	state: CanvasState | undefined,
	action: Action
): CanvasState => {
	const editor = codeEditorSlice.reducer(state?.editor, action);
	return canvasSlice.reducer({ ...initialState, ...state, editor }, action);
};
