import { type SourceLocation } from "@babel/types";

import { AstPattern } from "../ast-pattern";
import { identifierPattern, memberExpressionPattern } from "../ast-pattern/patterns";
import { type NodePatternSpec } from "../ast-pattern/types";

// Unpack and repack `loc` so that it can be serialized in Immer. Sigh. :(
export const makeLocImmerSerializable = <T extends { loc: SourceLocation | null }>(t?: T) =>
	!t
		? undefined
		: {
				...t,
				loc: !t.loc
					? undefined
					: {
							start: { column: t.loc.start.column, line: t.loc.start.line },
							end: { column: t.loc.end.column, line: t.loc.end.line },
						},
			};

// Recursively assemble the nested memberExpressionPatterns
const assembleMemberExpression = (identifiers: string[]) => {
	const [identifier, ...members] = identifiers.reverse();

	if (members.length === 0) {
		return identifierPattern(identifier);
	}
	return memberExpressionPattern(
		assembleMemberExpression(members.reverse()),
		identifierPattern(identifier)
	);
};

/**
 * Returns a pattern that matches a member call expression that looks like A.b.c.d(args)
 *
 *
 * @param callee - The member expression string. Period delimited. E.g. "Moment.secrets.get"
 * @param pattern - The argument pattern to capture. E.g. "stringLiteralPattern(AstPattern.any())"
 * @returns AST Pattern to capture the arguments
 *
 */
export const getMemberCallExpressionPattern = <T>(
	callee: string,
	pattern: NodePatternSpec<Partial<T>>
) => {
	const identifiers = callee.split(".");
	const calleePattern = assembleMemberExpression(identifiers);

	return AstPattern.anywhere({
		// manually create a CallExpression here since callExpressionPattern()
		// wraps "callee" in "indentifierPattern()" which we don't want.
		type: "CallExpression",
		callee: calleePattern,
		arguments: [
			AstPattern.captureGroup(
				pattern,
				(capture, acc, arg): T => makeLocImmerSerializable(arg)
			),
		],
	});
};
