import { initLogger as init, initializeRum as initRum, log as logger } from "@moment/logging";

import { isDesktopAppMode } from "~/auth/interop/common";

export type LogLevel = "debug" | "info" | "warn" | "error";

export const isSynthetics = () => {
	if (typeof window !== "undefined") {
		return window._DATADOG_SYNTHETICS_BROWSER !== undefined;
	}

	return false;
};

export const initLogger = () => {
	let service = "browser";
	if (isDesktopAppMode()) {
		service = "electron-renderer";
	}

	init(service);
};

export const initializeRum = () => {
	let service = "browser";
	if (isDesktopAppMode()) {
		service = "electron-renderer";
	}

	initRum(service);
};

export const log = (
	message: string,
	content?: Record<string, unknown>,
	level: LogLevel = "info"
): void => {
	logger(message, content, level);
};
