/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO shekhar re-enable this rule if it becomes an issue
export type Capture = (captured: any, acc: any, node: any) => any;
export type PatternCapture<N> = (value: N, acc: any, node: any) => any;

export type NodePatternSpec<N> = {
	[Property in keyof N]:
		| any
		| PatternCapture<N[Property]>
		| MatchAny<N[Property]>
		| MatchAllElements<N[Property]>
		| MatchOptional<Partial<N[Property]>>
		| MatchCaptureGroup<Partial<N[Property]>>
		| MatchOr<Partial<N[Property]>>;
};

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class Matchable {}

export class MatchAny<N> extends Matchable {
	constructor(public readonly capture?: PatternCapture<N>) {
		super();
	}
}

export class MatchAtRoot<N> extends Matchable {
	constructor(public readonly pattern: NodePatternSpec<N>) {
		super();
	}
}

export class MatchAnywhere<N> extends Matchable {
	constructor(public readonly pattern: NodePatternSpec<N>) {
		super();
	}
}

export class MatchAllElements<N> extends Matchable {
	constructor(public readonly pattern: NodePatternSpec<N>) {
		super();
	}
}

export class MatchAnyElements<N> extends Matchable {
	constructor(public readonly pattern: NodePatternSpec<N>) {
		super();
	}
}

export class MatchLastElement<N> extends Matchable {
	constructor(public readonly pattern: NodePatternSpec<N>) {
		super();
	}
}

export class MatchOptional<N> extends Matchable {
	constructor(public readonly pattern: NodePatternSpec<N>) {
		super();
	}
}

export class MatchCaptureGroup<N> extends Matchable {
	constructor(
		public readonly pattern: NodePatternSpec<N>,
		public readonly capture: Capture
	) {
		super();
	}
}

export class MatchOr<N extends Partial<any>> extends Matchable {
	constructor(public readonly pattern: NodePatternSpec<N>[]) {
		super();
	}
}
