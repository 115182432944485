import { trpc } from "~/utils/trpc";

export const useCommitToRepoMutation = () => {
	const utils = trpc.useUtils();

	const mutation = trpc.git.commit.useMutation({
		onSettled: () => void utils.git.invalidate(),
	});

	return mutation;
};
