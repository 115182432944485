import { isEmpty } from "lodash";

import { isDesktopAppMode } from "~/auth/interop/common";
import { trpc } from "~/utils/trpc";

export const useGitDiffQuery = ({
	workspaceId,
	documentId,
	commitHash = "HEAD",
}: {
	workspaceId: string;
	documentId: string;
	commitHash?: string;
}) => {
	const query = trpc.git.diff.useQuery(
		{ workspaceId, documentId, commitHash },
		{
			staleTime: 1000 * 60 * 5, // 5 minutes
			enabled: isDesktopAppMode() && !isEmpty(workspaceId) && !isEmpty(documentId),
		}
	);

	return query;
};
