import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import { type CellID, type CellInfo, type CellInfoExecutable } from "~/models/cell";
import { type ValidCellEmitInfo } from "~/runtime/cell-compiler/emit";

import { type CellsState } from "./types";

export const initialState: CellsState = {
	store: {},
	orderedList: [],
	cellInfosToRun: [],
	dirty: false,
	hash: "",
	lastSelectedCellID: undefined,
	selectedComputeCellIDs: undefined,
};

export const cellsSlice = createSlice({
	name: "cells",
	initialState,
	reducers: {
		reset: () => {
			return initialState;
		},

		setStatusExecutable: (
			state,
			action: PayloadAction<{ cellID: CellID; emitInfo: ValidCellEmitInfo }>
		) => {
			const { cellID, emitInfo } = action.payload;

			const cellFromStore = state.store[cellID];

			if (cellFromStore === undefined) {
				throw new Error(`cell ${cellID} does not exist`);
			}

			const newCellInfo: CellInfoExecutable = {
				cell: cellFromStore.cell,
				analysis: cellFromStore.analysis,
				emitInfo,
				status: "executable",
			};

			state.store[cellID] = newCellInfo;
		},

		setStatusError: (state, action: PayloadAction<{ cellID: CellID; message: string }>) => {
			const { cellID, message } = action.payload;

			const cellFromStore = state.store[cellID];
			if (!cellFromStore) {
				throw new Error(`cell ${cellID} does not exist`);
			}

			const newCellInfo: CellInfo = { ...cellFromStore, status: "error", message };
			state.store[cellID] = newCellInfo;
		},

		replaceAll: (state, action: PayloadAction<{ cells: CellInfo[]; setDirty?: boolean }>) => {
			const { cells, setDirty } = action.payload;

			state.store = {};
			state.orderedList = [];
			state.lastSelectedCellID = undefined;

			cells.forEach((cellInfo) => {
				state.store[cellInfo.cell.id] = cellInfo;
				state.orderedList.push(cellInfo.cell.id);
			});

			// needed in order to coerce setDirty from undefined to true
			state.dirty = setDirty === false ? false : true;
		},

		append: (state, action: PayloadAction<{ cellInfo: CellInfo }>) => {
			const { cellInfo } = action.payload;

			// eslint-disable-next-line no-prototype-builtins
			if (state.store.hasOwnProperty(cellInfo.cell.id)) {
				throw Error(`Cell ${cellInfo.cell.id} already exists in store`);
			}
			state.store[cellInfo.cell.id] = cellInfo;
			state.orderedList.push(cellInfo.cell.id);

			state.dirty = true;
		},

		setCell: (
			state,
			action: PayloadAction<{
				cellInfo: CellInfo;
			}>
		) => {
			const { cellInfo } = action.payload;

			state.store[cellInfo.cell.id] = cellInfo;
			state.dirty = true;
		},

		setDirty: (state, action: PayloadAction<boolean>) => {
			state.dirty = action.payload;
		},

		setCellInfosToRun: (state, action: PayloadAction<{ cellInfosToRun: CellInfo[] }>) => {
			const { cellInfosToRun } = action.payload;
			state.cellInfosToRun = cellInfosToRun;
			state.dirty = true;
		},

		setOrderedList: (state, action: PayloadAction<{ orderedList: CellID[] }>) => {
			const { orderedList } = action.payload;
			state.orderedList = orderedList;
			state.dirty = true;
		},

		setSelectedComputeCell: (state, action: PayloadAction<{ cellIDs: CellID[] }>) => {
			const { cellIDs } = action.payload;

			for (const cellID of cellIDs) {
				const cellFromStore = state.store[cellID];
				if (!cellFromStore) {
					throw new Error(`cell ${cellID} does not exist`);
				}
				if (cellFromStore.cell.type === "ProseMirrorJSONCell") {
					throw new Error(`cell ${cellID} is not a compute cell`);
				}
			}

			state.lastSelectedCellID = cellIDs[cellIDs.length - 1];
			state.selectedComputeCellIDs = cellIDs;
		},

		unsetSelectedComputeCell: (state) => {
			state.selectedComputeCellIDs = undefined;
		},
	},
});

export const { reset, setDirty, setSelectedComputeCell, unsetSelectedComputeCell } =
	cellsSlice.actions;

export const cells = cellsSlice.reducer;
