import { UpdateNow } from "@carbon/icons-react";
import React, {
	type FC,
	type PropsWithChildren,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from "react";

import { BasicDialog } from "@moment/design-system/Dialog";
import { LoadingSpinner } from "@moment/design-system/LoadingSpinner";

import { selectIsAppUpdateReady } from "~/store/desktop-app/selectors";
import { useAppSelector } from "~/store/hooks";
import { notImplementedFn } from "~/utils/context";

type ConfirmFn = () => Promise<boolean>;
export type OutdatedAppModalContextState = {
	confirm: ConfirmFn;
};

export const initialState: OutdatedAppModalContextState = {
	confirm: notImplementedFn("confirm", "OutdatedAppModalContext", Promise.resolve(true)),
};

const OutdatedAppModalContext = createContext<OutdatedAppModalContextState>(initialState);

export const useOutdatedAppModal = () => useContext(OutdatedAppModalContext);

export const OutdatedAppModalWrapper: FC<PropsWithChildren> = ({ children }) => {
	// State
	const [visible, setVisible] = useState(false);
	const [_props, setProps] = useState();
	// We can't set the resolve function directly in state because anonymous function do not trigger state updates
	const [resolve, setResolve] = useState<{
		fn: undefined | ((resolve: boolean | PromiseLike<boolean>) => void);
	}>({ fn: undefined });
	const isAppUpdateReady = useAppSelector(selectIsAppUpdateReady);

	// Methods
	const showDialog = useCallback(() => setVisible(true), [setVisible]);

	const closeDialog = useCallback(() => {
		setVisible(false);
	}, [setProps, setVisible]);

	const confirm: ConfirmFn = useCallback(() => {
		showDialog();

		return new Promise<boolean>((resolve) => {
			setResolve({ fn: resolve });
		});
	}, [setProps, showDialog, setResolve]);

	const onConfirm = useCallback(() => {
		resolve.fn?.(true);
		closeDialog();
	}, [resolve, closeDialog]);

	const onCancel = useCallback(() => {
		resolve.fn?.(false);
		closeDialog();
	}, [resolve, closeDialog]);

	const value = useMemo(() => ({ confirm }), [confirm]);

	return (
		<OutdatedAppModalContext.Provider value={value}>
			{children}
			<BasicDialog
				isOpen={visible}
				closeDialog={onCancel}
				title="App Update Required"
				titleIcon={<UpdateNow />}
				description={
					<div>
						<span>
							A new version of the app is available. Please restart the app to
							automatically install the update.
						</span>
						{!isAppUpdateReady && (
							<div className="flex flex-row items-center py-2">
								<div className="mr-2">Downloading update...</div>
								<LoadingSpinner />
							</div>
						)}
					</div>
				}
				variant="normal"
				confirmText="Restart app"
				onConfirm={onConfirm}
				confirmDisabled={!isAppUpdateReady}
				hideCancel
				preventDismissal
			/>
		</OutdatedAppModalContext.Provider>
	);
};
