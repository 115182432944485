//
// TypeScript port of the MIT-licensed keymap.js[1] from prosemirror-example-setup.
//
// [1]: https://github.com/ProseMirror/prosemirror-example-setup/blob/6bd92b0712bf389cfe7ec26904230f959643e60b/src/keymap.js
//
import {
	chainCommands,
	exitCode,
	joinDown,
	joinUp,
	lift,
	selectParentNode,
	toggleMark,
} from "prosemirror-commands";
import { redo, undo } from "prosemirror-history";
import { undoInputRule } from "prosemirror-inputrules";
import { type Schema } from "prosemirror-model";
import { type Command, type EditorState, type Transaction } from "prosemirror-state";

import { convertNodesInSelection } from "./convertNodesInSelection";

const mac = typeof navigator != "undefined" ? /Mac/.test(navigator.platform) : false;

// :: (Schema, ?Object) → Object
// Inspect the given schema looking for marks and nodes from the
// basic schema, and if found, add key bindings related to them.
// This will add:
//
// * **Mod-b** for toggling [strong](#schema-basic.StrongMark)
// * **Mod-i** for toggling [emphasis](#schema-basic.EmMark)
// * **Mod-`** for toggling [code font](#schema-basic.CodeMark)
// * **Ctrl-Shift-0** for making the current textblock a paragraph
// * **Ctrl-Shift-1** to **Ctrl-Shift-Digit6** for making the current
//   textblock a heading of the corresponding level
// * **Ctrl-Shift-Backslash** to make the current textblock a code block
// * **Ctrl-Shift-8** to wrap the selection in an ordered list
// * **Ctrl-Shift-9** to wrap the selection in a bullet list
// * **Ctrl->** to wrap the selection in a block quote
// * **Enter** to split a non-empty textblock in a list item while at
//   the same time splitting the list item
// * **Mod-Enter** to insert a hard break
// * **Mod-_** to insert a horizontal rule
// * **Backspace** to undo an input rule
// * **Alt-ArrowUp** to `joinUp`
// * **Alt-ArrowDown** to `joinDown`
// * **Mod-BracketLeft** to `lift`
// * **Escape** to `selectParentNode`
//
// You can suppress or map these bindings by passing a `mapKeys`
// argument, which maps key names (say `"Mod-B"` to either `false`, to
// remove the binding, or a new key name string.
export function baseKeymap(schema: Schema) {
	// eslint-disable-next-line prefer-const
	let keys: { [key: string]: Command } = {},
		type;
	function bind(key: string, cmd: Command) {
		keys[key] = cmd;
	}

	/**
	 * Undo and redo
	 */
	bind("Mod-z", undo);
	bind("Shift-Mod-z", redo);
	bind("Backspace", undoInputRule);
	if (!mac) bind("Mod-y", redo);

	/**
	 * Move block(s) up and down
	 */
	bind("Alt-ArrowUp", joinUp);
	bind("Alt-ArrowDown", joinDown);
	bind("Mod-BracketLeft", lift);
	bind("Escape", selectParentNode);

	/**
	 * Toggle bold
	 */
	if ((type = schema.marks["strong"])) {
		bind("Mod-b", toggleMark(type));
		bind("Mod-B", toggleMark(type));
	}

	/**
	 * Toggle italic
	 */
	if ((type = schema.marks["em"])) {
		bind("Mod-i", toggleMark(type));
		bind("Mod-I", toggleMark(type));
	}

	/**
	 * Toggle underline
	 */
	if ((type = schema.marks["underline"])) {
		bind("Mod-u", toggleMark(type));
		bind("Mod-U", toggleMark(type));
	}

	/**
	 * Toggle strikethrough
	 */
	if ((type = schema.marks["strike"])) {
		bind("Mod-Shift-x", toggleMark(type));
		bind("Mod-Shift-X", toggleMark(type));
	}

	/**
	 * Toggle inline code
	 */
	if ((type = schema.marks["code"])) {
		bind("Mod-`", toggleMark(type));
	}

	/**
	 * Wrap selection in blockquote
	 */
	if ((type = schema.nodes["blockquote"])) {
		bind("Mod->", convertNodesInSelection({ nodeType: "blockquote" }));
	}

	/**
	 * Insert line break
	 */
	if ((type = schema.nodes["hard_break"])) {
		const br = type,
			cmd = chainCommands(exitCode, (state, dispatch) => {
				dispatch?.(state.tr.replaceSelectionWith(br.create()).scrollIntoView());
				return true;
			});
		bind("Shift-Enter", cmd);
		if (mac) bind("Ctrl-Enter", cmd);
	}

	/**
	 * Make section a basic paragraph
	 */
	// macOS
	if ((type = schema.nodes["paragraph"])) {
		bind("Shift-Cmd-0", convertNodesInSelection({ nodeType: "paragraph" }));
	}

	// Windows
	if ((type = schema.nodes["paragraph"])) {
		bind("Shift-Ctrl-0", convertNodesInSelection({ nodeType: "paragraph" }));
	}

	/**
	 * Wrap selection in code block
	 */
	// macOS
	if ((type = schema.nodes["code_block"])) {
		bind("Shift-Cmd-\\", convertNodesInSelection({ nodeType: "code_block" }));
	}

	// Windows
	if ((type = schema.nodes["code_block"])) {
		bind("Shift-Ctrl-\\", convertNodesInSelection({ nodeType: "code_block" }));
	}

	/**
	 * Make section a heading of `i` level
	 */
	if ((type = schema.nodes["heading"])) {
		for (let i = 1; i <= 3; i++) {
			// macOS
			bind(
				"Shift-Cmd-" + i,
				convertNodesInSelection({ nodeType: "heading", attrs: { level: 1 } })
			);

			// Windows
			bind(
				"Shift-Ctrl-" + i,
				convertNodesInSelection({ nodeType: "heading", attrs: { level: 1 } })
			);
		}
	}

	/**
	 * Convert selection to ordered list items
	 */
	if ((type = schema.nodes["list_item"])) {
		bind(
			"Shift-Mod-7",
			convertNodesInSelection({
				nodeType: "list_item",
				attrs: { bullet: "#", indent: 0 },
			})
		);
	}

	/**
	 * Convert selection to bullet list items
	 */
	if ((type = schema.nodes["list_item"])) {
		bind(
			"Shift-Mod-8",
			convertNodesInSelection({
				nodeType: "list_item",
				attrs: { bullet: "-", indent: 0 },
			})
		);
	}

	/**
	 * Insert horizontal rule
	 */
	if ((type = schema.nodes["horizontal_rule"])) {
		const hr = type;
		bind("Mod-_", (state: EditorState, dispatch: Dispatch) => {
			dispatch?.(state.tr.replaceSelectionWith(hr.create()).scrollIntoView());
			return true;
		});
	}

	return keys;
}

type Dispatch = ((tr: Transaction) => void) | undefined;
