import { useLocalStorage } from "react-use";

export const canvasLayoutVisualDebugKey = "moment:canvas-layout-visual-debug";

export const canvasLayoutVisualDebugEvent = new Event(canvasLayoutVisualDebugKey);

export const useCanvasLayoutVisualDebug = () => {
	const [value, setValue] = useLocalStorage(canvasLayoutVisualDebugKey, "disabled");

	const isActive = value === "enabled";

	const toggle = () => {
		switch (value) {
			case "disabled": {
				setValue("enabled");
				break;
			}
			case "enabled":
			default: {
				setValue("disabled");
				break;
			}
		}

		window.dispatchEvent(canvasLayoutVisualDebugEvent);
	};

	return { isActive, toggle };
};
