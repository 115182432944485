import { type Node } from "prosemirror-model";
import { type EditorState, type Transaction } from "prosemirror-state";

import { documentPlaceholderName } from "../plugins/document-placeholder-plugin/documentPlaceholderPlugin";

export const replaceDocumentPlaceholder = ({
	tr,
	state,
	node,
}: {
	tr: Transaction;
	state: EditorState;
	node: Node[];
}) => {
	if (state.doc.childCount > 1) {
		return tr;
	}

	const cell = state.doc.child(0);

	if (cell.type.name !== documentPlaceholderName) {
		return tr;
	}

	return tr.replaceWith(0, cell.nodeSize, node);
};
