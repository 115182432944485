import {
	type ArrayExpression,
	type ArrowFunctionExpression,
	type BlockStatement,
	type BooleanLiteral,
	type CallExpression,
	type ExpressionStatement,
	type FunctionExpression,
	type Identifier,
	type JSXAttribute,
	type JSXElement,
	type JSXExpressionContainer,
	type JSXIdentifier,
	type JSXOpeningElement,
	type MemberExpression,
	type NumericLiteral,
	type ObjectExpression,
	type ObjectPattern,
	type ObjectProperty,
	type ReturnStatement,
	type StringLiteral,
	type TSLiteralType,
	type TSPropertySignature,
	type TSTypeAnnotation,
	type TSTypeLiteral,
	type TSTypeParameterInstantiation,
	type TSTypeReference,
	type VariableDeclarator,
} from "@babel/types";

import { AstPattern } from ".";
import { type NodePatternSpec } from "./types";

export const expressionStatementPattern = (
	expression: NodePatternSpec<Partial<ExpressionStatement>>["expression"]
): NodePatternSpec<Partial<ExpressionStatement>> => ({
	type: "ExpressionStatement",
	expression,
});

export const identifierPattern = (
	name: NodePatternSpec<Partial<Identifier>>["name"]
): NodePatternSpec<Partial<Identifier>> => ({ type: "Identifier", name });

export const stringLiteralPattern = (
	value: NodePatternSpec<Partial<StringLiteral>>["value"]
): NodePatternSpec<Partial<StringLiteral>> => ({ type: "StringLiteral", value });

export const numericLiteralPattern = (
	value: NodePatternSpec<Partial<NumericLiteral>>["value"]
): NodePatternSpec<Partial<NumericLiteral>> => ({ type: "NumericLiteral", value });

export const booleanLiteralPattern = (
	value: NodePatternSpec<Partial<BooleanLiteral>>["value"]
): NodePatternSpec<Partial<BooleanLiteral>> => ({ type: "BooleanLiteral", value });

export const variableDeclaratorPattern = (
	name: NodePatternSpec<Partial<Identifier>>["name"],
	init?: NodePatternSpec<Partial<VariableDeclarator>>["init"]
): NodePatternSpec<Partial<VariableDeclarator>> => ({
	type: "VariableDeclarator",
	id: identifierPattern(name),
	init,
});

export const arrayExpressionPattern = (
	elements: NodePatternSpec<Partial<ArrayExpression>>["elements"]
): NodePatternSpec<Partial<ArrayExpression>> => ({
	type: "ArrayExpression",
	elements,
});

export const objectExpressionPattern = (
	properties: NodePatternSpec<Partial<ObjectExpression>>["properties"]
): NodePatternSpec<Partial<ObjectExpression>> => ({
	type: "ObjectExpression",
	properties,
});

export const memberExpressionPattern = (
	object: NodePatternSpec<Partial<MemberExpression>>["object"],
	property: NodePatternSpec<Partial<MemberExpression>>["property"]
): NodePatternSpec<Partial<MemberExpression>> => ({
	type: "MemberExpression",
	object,
	property,
});

export const objectPatternPattern = (
	properties: NodePatternSpec<Partial<ObjectPattern>>["properties"]
): NodePatternSpec<Partial<ObjectPattern>> => ({
	type: "ObjectPattern",
	properties,
});

export const objectPropertyPattern = (
	name: NodePatternSpec<Partial<Identifier>>["name"],
	value: NodePatternSpec<Partial<ObjectProperty>>["value"]
): NodePatternSpec<Partial<ObjectProperty>> => ({
	type: "ObjectProperty",
	key: { type: "Identifier", name },
	value,
});

export const tsTypeReferencePattern = (
	typeName: NodePatternSpec<Partial<Identifier>>["name"],
	params: NodePatternSpec<Partial<TSTypeParameterInstantiation>>["params"]
): NodePatternSpec<Partial<TSTypeReference>> => ({
	type: "TSTypeReference",
	typeName: identifierPattern(typeName),
	typeParameters: { type: "TSTypeParameterInstantiation", params },
});

export const tsTypeLiteralPattern = (
	members: NodePatternSpec<Partial<TSTypeLiteral>>["members"]
): NodePatternSpec<Partial<TSTypeLiteral>> => ({ type: "TSTypeLiteral", members });

export const tsPropertySignaturePattern = (
	key: NodePatternSpec<Partial<Identifier>>["name"],
	typeAnnotation: NodePatternSpec<Partial<TSTypeAnnotation>>["typeAnnotation"]
): NodePatternSpec<Partial<TSPropertySignature>> => ({
	type: "TSPropertySignature",
	key: identifierPattern(key),
	typeAnnotation: { type: "TSTypeAnnotation", typeAnnotation },
});

export const tsStringLiteralTypePattern = (
	value?: NodePatternSpec<Partial<StringLiteral>>["value"]
): NodePatternSpec<Partial<TSLiteralType>> => ({
	type: "TSLiteralType",
	literal: stringLiteralPattern(value),
});

export const returnStatementPattern = (
	argument: NodePatternSpec<Partial<ReturnStatement>>["argument"]
): NodePatternSpec<Partial<ReturnStatement>> => ({ type: "ReturnStatement", argument });

export const jsxIdentifierPattern = (
	name: NodePatternSpec<Partial<JSXIdentifier>>["name"]
): NodePatternSpec<Partial<JSXIdentifier>> => ({ type: "JSXIdentifier", name });

export const jsxElementPattern = (
	name: NodePatternSpec<Partial<Identifier>>["name"],
	attributes?: NodePatternSpec<Partial<JSXOpeningElement>>["attributes"]
): NodePatternSpec<Partial<JSXElement>> => ({
	type: "JSXElement",
	openingElement: { type: "JSXOpeningElement", name: jsxIdentifierPattern(name), attributes },
});

export const jsxElementCaptureJsxIdentifierPattern = (
	name: NodePatternSpec<Partial<JSXOpeningElement>>["name"],
	attributes?: NodePatternSpec<Partial<JSXOpeningElement>>["attributes"]
): NodePatternSpec<Partial<JSXElement>> => ({
	type: "JSXElement",
	openingElement: { type: "JSXOpeningElement", name, attributes },
});

export const jsxAttribute = (
	name: NodePatternSpec<Partial<JSXIdentifier>>["name"],
	value: NodePatternSpec<Partial<JSXAttribute>>["value"]
): NodePatternSpec<Partial<JSXAttribute>> => ({
	type: "JSXAttribute",
	name: jsxIdentifierPattern(name),
	value,
});

export const jsxExpressionContainerPattern = (
	expression: NodePatternSpec<Partial<JSXExpressionContainer>>["expression"]
): NodePatternSpec<Partial<JSXExpressionContainer>> => ({
	type: "JSXExpressionContainer",
	expression,
});

export const arrowFunctionExpressionPattern = (
	params: NodePatternSpec<Partial<ArrowFunctionExpression>>["params"],
	body: NodePatternSpec<Partial<ArrowFunctionExpression>>["body"]
): NodePatternSpec<Partial<ArrowFunctionExpression>> => ({
	type: "ArrowFunctionExpression",
	params,
	body,
});

export const blockStatementPattern = (
	body: NodePatternSpec<Partial<BlockStatement>>["body"]
): NodePatternSpec<Partial<BlockStatement>> => ({ type: "BlockStatement", body });

export const functionExpressionPattern = (
	params: NodePatternSpec<Partial<FunctionExpression>>["params"],
	body: NodePatternSpec<Partial<BlockStatement>>["body"]
): NodePatternSpec<Partial<FunctionExpression>> => ({
	type: "FunctionExpression",
	params,
	body: blockStatementPattern(body),
});

export const callExpressionPattern = (
	callee: NodePatternSpec<Partial<Identifier>>["name"],
	args: NodePatternSpec<Partial<CallExpression>>["arguments"]
): NodePatternSpec<Partial<CallExpression>> => ({
	type: "CallExpression",
	callee: identifierPattern(callee),
	arguments: args,
});

/**
 * Matches expressions of the form `"whatever" as MomentInspectHint<string, { label: "Whatever" }>`
 */
export const valueTsAsInspectHintPattern = AstPattern.anywhere({
	type: "TSAsExpression",

	expression: {
		start: (valueStart: number | null | undefined) => ({ valueStart }),
		end: (valueEnd: number | null | undefined) => ({ valueEnd }),
		value: (value: string | undefined) => ({ value }),
		extra: { raw: (rawValue: unknown) => ({ rawValue }) },
	},

	// Matches: `MomentInspectHint<string, { label: "Organization" }>`
	typeAnnotation: tsTypeReferencePattern("MomentInspectHint", [
		{ type: (fieldType: unknown) => ({ fieldType }) },

		// Matches: `{ label: "Organization" }`
		AstPattern.optional(
			tsTypeLiteralPattern([
				AstPattern.optional(
					tsPropertySignaturePattern(
						"label",
						tsStringLiteralTypePattern((label: string) => ({ label }))
					)
				),
				AstPattern.optional(
					tsPropertySignaturePattern(
						"section",
						tsStringLiteralTypePattern((label: string) => ({ label }))
					)
				),
				AstPattern.optional(
					tsPropertySignaturePattern(
						"labelPosition",
						tsStringLiteralTypePattern((label: string) => ({ label }))
					)
				),
				AstPattern.optional(
					tsPropertySignaturePattern(
						"summaryFieldPrimary",
						tsStringLiteralTypePattern((label: string) => ({ label }))
					)
				),
			])
		),
	]),
});
