import { isEmpty } from "lodash";

import { DEFAULT_CANVAS_TITLE } from "~/strings";

export const getTitle = (repo) => {
	if (!isEmpty(repo.moment_title)) {
		return repo.moment_title;
	}
	return DEFAULT_CANVAS_TITLE;
};
