import { type Node as ProsemirrorNode } from "prosemirror-model";
import { Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";

import { hotkeys } from "~/utils/hotkeys";

export const TEXT_PLACEHOLDER_PLUGIN_KEY = new PluginKey("text-placeholder-plugin");

export const textPlaceholderPlugin = () => {
	const p = new Plugin({
		key: TEXT_PLACEHOLDER_PLUGIN_KEY,
		state: {
			init() {
				return {
					isFocused: false,
				};
			},
			apply(tr, value, _prevState, _nextState) {
				const meta = tr.getMeta(TEXT_PLACEHOLDER_PLUGIN_KEY);

				if (meta && meta.isFocused !== undefined) {
					value.isFocused = meta.isFocused;
				}

				return value;
			},
		},
		props: {
			decorations(state) {
				//
				// NODE PLACEHOLDERS. In general these behave identical to Notion's placeholders.
				// Specifically, display a placeholder:
				//
				//   * Any time there is an empty header, e.g., `<h1>`, `<h2>`, etc.
				//   * When the cursor exists inside an empty paragraph.
				//

				const decorations: Decoration[] = [];
				const { $from, $to } = state.selection;
				const { isFocused } = p.getState(state) ?? { isFocused: false };

				const decorate = (node: ProsemirrorNode, pos: number) => {
					const cursorInNode = $from.node() === $to.node() && $from.node() === node;

					// The default text placeholder only shows on empty paragraph nodes when the cursor is inside and focused.
					const cursorInEmptyParagraph =
						cursorInNode &&
						node.type.name === "paragraph" &&
						node.childCount === 0 &&
						isFocused;

					// explicitly set placeholder text always shows when empty
					const emptyParagraphWithPlaceholder =
						node.type.name === "paragraph" &&
						node.childCount === 0 &&
						node.attrs["placeholderText"];

					// But other block elements should have a persistent placeholder regardless
					// of cursor position (e.g. headings and blockquotes).
					const nonParagraphBlockIsEmpty =
						node.type.name !== "paragraph" &&
						node.type.name !== "code_block" &&
						node.type.name !== "horizontal_rule" &&
						node.type.isBlock &&
						node.childCount === 0;

					// Unless there is only one empty node
					const docHasOnlyOneEmptyNode =
						state.doc.childCount === 1 && state.doc.firstChild?.childCount === 0;

					if (
						cursorInEmptyParagraph ||
						emptyParagraphWithPlaceholder ||
						nonParagraphBlockIsEmpty ||
						docHasOnlyOneEmptyNode
					) {
						const placeholderText =
							node.attrs["placeholderText"] ||
							`${hotkeys["open-components-tab"].prettyPrintKeybinding()} to insert data and components, or start typing`;

						decorations.push(
							Decoration.node(pos, pos + node.nodeSize, {
								"class": "empty-node",
								"data-placeholder-text": placeholderText,
							})
						);
					}
				};

				state.doc.descendants(decorate);

				return DecorationSet.create(state.doc, decorations);
			},
			handleDOMEvents: {
				// handled in BigRichTextEditor
			},
		},
	});

	return p;
};
