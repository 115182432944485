import {
	type ArrayExpression,
	type Node,
	type TSArrayType,
	type TSTypeLiteral,
	type TSUnionType,
} from "@babel/types";

import { type FieldLocations, type SimpleObjectFieldsInfo } from "./JsxArgumentInfo";
import {
	type JsxParam,
	type JsxSimpleObjectArrayParam,
	type JsxStringUnionParam,
	type MomentFieldMetadata,
} from "./JsxParameterInfo";
import { toNodeLocation } from "./NodeLocation";

export const transformUnionTypeAnnotation = (
	paramType: TSUnionType,
	paramMeta: MomentFieldMetadata
): JsxStringUnionParam | JsxParam => {
	const paramTypes: { type: "StringLiteral"; value: string; raw: string }[] = [];
	let allLiterals = true;
	for (const type of paramType.types) {
		if (type.type !== "TSLiteralType" || type.literal.type !== "StringLiteral") {
			allLiterals = false;
		} else {
			paramTypes.push({
				type: "StringLiteral",
				value: type.literal.value,
				raw: type.literal.extra?.["raw"] as string,
			});
		}
	}

	if (allLiterals) {
		return { type: "MomentStringUnionParam", types: paramTypes, metadata: paramMeta };
	} else {
		return { type: "TSAnyKeyword", metadata: paramMeta } as const;
	}
};

export const momentFieldMetadataFromTypeLiteral = (t: TSTypeLiteral) => {
	const paramMeta: MomentFieldMetadata = {};
	for (const member of t.members) {
		if (member.type !== "TSPropertySignature" || member.key.type !== "Identifier") {
			continue;
		}

		if (
			member.key.name === "section" &&
			member.typeAnnotation?.typeAnnotation.type === "TSLiteralType" &&
			member.typeAnnotation.typeAnnotation.literal.type === "StringLiteral"
		) {
			paramMeta.section = member.typeAnnotation.typeAnnotation.literal.value;
		} else if (
			member.key.name === "label" &&
			member.typeAnnotation?.typeAnnotation.type === "TSLiteralType" &&
			member.typeAnnotation.typeAnnotation.literal.type === "StringLiteral"
		) {
			paramMeta.label = member.typeAnnotation.typeAnnotation.literal.value;
		} else if (
			member.key.name === "labelPosition" &&
			member.typeAnnotation?.typeAnnotation.type === "TSLiteralType" &&
			member.typeAnnotation.typeAnnotation.literal.type === "StringLiteral"
		) {
			paramMeta.labelPosition = member.typeAnnotation.typeAnnotation.literal.value;
		} else if (
			member.key.name === "summaryFieldPrimary" &&
			member.typeAnnotation?.typeAnnotation.type === "TSLiteralType" &&
			member.typeAnnotation.typeAnnotation.literal.type === "StringLiteral"
		) {
			paramMeta.summaryFieldPrimary = member.typeAnnotation.typeAnnotation.literal.value;
		}
	}

	return paramMeta;
};

export const transformPropSignatureTypeAnnotation = (
	paramType: TSArrayType,
	paramMeta: MomentFieldMetadata
): JsxSimpleObjectArrayParam | JsxParam => {
	if (paramType.elementType.type !== "TSTypeLiteral") {
		return { type: "TSAnyKeyword", metadata: paramMeta };
	}

	const members = paramType.elementType.members;
	let allSimpleObjects = true;
	const type: JsxSimpleObjectArrayParam = {
		type: "MomentSimpleObjectArrayParam",
		members: [],
		metadata: paramMeta,
	};
	for (const member of members) {
		let memberType = member.typeAnnotation?.typeAnnotation;
		if (member.type !== "TSPropertySignature" || member.key.type !== "Identifier") {
			allSimpleObjects = false;
			continue;
		}

		let metadata: MomentFieldMetadata = {};
		if (
			memberType?.type === "TSTypeReference" &&
			memberType.typeName.type === "Identifier" &&
			memberType.typeName.name === "MomentField"
		) {
			const metaType = memberType.typeParameters?.params[1];
			if (metaType?.type === "TSTypeLiteral") {
				metadata = momentFieldMetadataFromTypeLiteral(metaType);
			}

			memberType = memberType.typeParameters?.params[0];
			if (memberType === null) {
				continue;
			}
		}

		let typeAnnotation: JsxSimpleObjectArrayParam | JsxStringUnionParam | JsxParam;
		if (memberType?.type === "TSUnionType") {
			//
			// CASE: Union of strings, e.g., `"s1" | "s2" | "s3"`.
			//

			typeAnnotation = transformUnionTypeAnnotation(memberType, metadata);
		} else if (memberType?.type && isTsPrimitiveTypeTag(memberType.type)) {
			//
			// CASE: Any of the allowed primitive types.
			//

			typeAnnotation = { type: memberType.type, metadata };
		} else {
			typeAnnotation = { type: "TSAnyKeyword", metadata };
		}

		type.members.push({
			key: member.key.name,
			type: typeAnnotation,
		});
	}

	if (allSimpleObjects) {
		return type;
	} else {
		return { type: "TSAnyKeyword", metadata: paramMeta };
	}
};

export const transformArrayExpression = (expr: ArrayExpression) => {
	const allFields: SimpleObjectFieldsInfo[] = [];

	for (const e of expr.elements) {
		if (e?.type !== "ObjectExpression") {
			return undefined;
		}

		const fieldLocations: FieldLocations = {};
		for (const prop of e.properties) {
			if (prop.type !== "ObjectProperty") {
				continue;
			}

			if (prop.key.type !== "Identifier" || !isPrimitiveType(prop.value.type)) {
				return undefined;
			}

			fieldLocations[prop.key.name] = {
				name: toNodeLocation(prop.key),
				value: {
					...prop.value,
					...toNodeLocation(prop.value),
				},
			};
		}
		allFields.push({ containingObject: toNodeLocation(e), fieldLocations });
	}

	return allFields;
};

const allPrimitiveTyps: readonly Node["type"][] = [
	"BooleanLiteral",
	"NullLiteral",
	"NumberLiteral",
	"StringLiteral",
	"TemplateLiteral",
	"TaggedTemplateExpression",
	"TSUndefinedKeyword",
	"ArrowFunctionExpression",
] as const;
const primitiveTypeSet = new Set(allPrimitiveTyps);
type PrimitiveTypes = (typeof allPrimitiveTyps)[number];

/* eslint-disable @typescript-eslint/no-explicit-any */
const isPrimitiveType = (o: any): o is PrimitiveTypes => primitiveTypeSet.has(o);

const allTsPrimitiveTypeTags = [
	"TSBooleanKeyword",
	"TSNullKeyword",
	"TSNumberKeyword",
	"TSStringKeyword",
	"TSUndefinedKeyword",
] as const;
const tsPrimitiveTypeTagSet = new Set(allTsPrimitiveTypeTags);

/* eslint-disable @typescript-eslint/no-explicit-any */
const isTsPrimitiveTypeTag = (o: any): o is TsPrimitiveTypeTags => tsPrimitiveTypeTagSet.has(o);
type TsPrimitiveTypeTags = (typeof allTsPrimitiveTypeTags)[number];
