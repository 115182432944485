import { type ParsedUrlQuery, encode } from "querystring";

import type { CanvasById, CanvasBySharedName } from "~/components/navigation/types";

export const VALID_QUERY_PARAMS = {
	CANVAS: {
		VERSION: "v",
		PARAMS: "params",
		DRAFT_AUTHOR_ID: "d",
		INVITE_TOKEN: "inviteToken",
	},
};
export type CanvasRouteTypes = CanvasById | CanvasBySharedName;

export type UseNavigateToCanvasProps = {
	version?: string;
	pageSlug?: string;
	draftAuthorId?: string;
} & CanvasRouteTypes;

/**
 * Canvas regex by id
 */
export const canvasRegexById =
	/^\/canvases\/(?<canvasId>urn:uuid:[a-zA-Z0-9-]+)(?<pageSlug>\/[a-zA-Z0-9-]+)?/;
export const canvasRegexBySharedName =
	/^\/@(?<namespace>[a-zA-Z0-9-]+)\/(?<sharedName>[a-zA-Z0-9-]+)\/?(?<pageSlug>\/[a-zA-Z0-9-]+)?/;
export const canvasRegexByCatalog = /^\/catalog$/;

const canvasRegexes = [canvasRegexById, canvasRegexBySharedName, canvasRegexByCatalog];

export const assembleQueryString = (query?: Record<string, string> | ParsedUrlQuery) =>
	query && Object.keys(query).length > 0 ? `?${encode(query)}` : "";

export const canvasUrlPath = (props: UseNavigateToCanvasProps): string => {
	let subpath = "";
	if (props.pageSlug && props.pageSlug !== "index") {
		subpath = `/${props.pageSlug}`;
	}

	switch (props.type) {
		case "CanvasById":
			return `/d/${props.workspaceId}/${props.id}${subpath}`;
		case "CanvasBySharedName":
			return `/@${props.namespace}/${props.sharedName}${subpath}`;
	}
};

export const canvasLinkHref = (props: UseNavigateToCanvasProps) => {
	const path = canvasUrlPath(props);
	const queryParams = props.version ? `?v=${props.version}` : "";
	return `${path}${queryParams}`;
};

/**
 * useCreateCanvasUrl
 */
export type CreateCanvasUrl = CanvasById | CanvasBySharedName;

export const createCanvasUrl = (options: CreateCanvasUrl) => {
	if ("id" in options) {
		return `/canvases/${options.id}`;
	}
	return `/@${options.namespace}/${options.sharedName}`;
};

/**
 * getPageSlug - returns the last part of the pathname
 * @param url - the url to parse
 * @returns the last part of the pathname which we assume is the page slug
 */
export const getPageSlug = (url: URL) => {
	const pathname = url.pathname;
	const pageSlug = pathname.split("/").pop();
	return pageSlug;
};

/**
 * There are multiple link formats for a canvas. This gets the appropriate regex for the link.
 *
 * @param link
 * @returns the regex for the link
 */
export function getCanvasLinkRegex(link: URL | string) {
	const url = new URL(link);

	return canvasRegexes.find((regex) => {
		return url.pathname.match(regex);
	});
}

export function isSameCanvasLink(link1: string, link2: string) {
	const url1 = new URL(link1);
	const url2 = new URL(link2);

	if (url1.origin !== url2.origin) {
		return false;
	}

	// Ignore trailing slashes.
	url1.pathname = decodeURIComponent(url1.pathname.replace(/\/$/, ""));
	url2.pathname = decodeURIComponent(url2.pathname.replace(/\/$/, ""));

	// Since the origin and pathname match, that means the links are the same page
	// so now we need to check if it's a canvas link.

	const canvasLinkRegex = getCanvasLinkRegex(url1);
	if (!canvasLinkRegex) {
		return false;
	}

	const match1 = url1.pathname.match(canvasLinkRegex);
	const match2 = url2.pathname.match(canvasLinkRegex);
	if (!match1 || !match2) {
		return false;
	}

	let isSameCanvas = true;

	if (match1.groups) {
		Object.keys(match1.groups).forEach((key) => {
			// Ignore the pageSlug since different pages can be the same canvas.
			if (key === "pageSlug") {
				return;
			}

			if (match1.groups?.[key] !== match2.groups?.[key]) {
				isSameCanvas = false;
			}
		});
	}

	return isSameCanvas;
}
