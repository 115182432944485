import { type EditorState, type Transaction } from "prosemirror-state";

import { getCellPosition } from "./getCellPosition";

export const deleteCellByID = (state: EditorState, cellID: string): Transaction | undefined => {
	const position = getCellPosition(state, cellID);

	if (position === undefined) {
		return;
	}
	const tr = state.tr;
	return tr.delete(position.before, position.after);
};
