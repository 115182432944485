import { z } from "zod";

const now = () => new Date();

export const workspaceSchema = z.object({
	id: z.string(),
	name: z.string(),
	createdAt: z.coerce.date().default(now),
	lastAccessedAt: z.coerce.date().default(now),
	path: z.string().nullish(),
});

export type Workspace = z.infer<typeof workspaceSchema>;
