import { type EditorState } from "prosemirror-state";

import { getCellPositions } from "./getCellPositions";
import { type CellPosition } from "./types";

export const getCellPosition = (state: EditorState, cellID: string): CellPosition | undefined => {
	const cellPositions = getCellPositions(state);
	for (let i = 0; i < cellPositions.length; i++) {
		const cellPosition = cellPositions[i];

		if (cellPosition?.node.attrs["id"] === cellID) {
			return cellPosition;
		}
	}
};
