export { useUpdateWorkspaceMutation } from "./useUpdateWorkspaceMutation";
export { useWorkspaceQuery } from "./useWorkspaceQuery";
export { useWorkspacesQuery } from "./useWorkspacesQuery";

export * from "./types";

export const workspaceQueryKeys = {
	workspaces: (location: "local" | "remote" | "help", fetchDocumentData = false) => [
		"workspaces",
		location,
		...(fetchDocumentData ? ["fetchDocumentData"] : []),
	],
	workspace: (location: "local" | "remote", workspaceId: string) => [
		"workspaces",
		location,
		workspaceId,
	],
	updateWorkspace: ["workspaces", "updateWorkspace"],
};
